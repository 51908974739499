import React, {Component} from 'react';
import {a11yLight} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import SyntaxHighlighter from "react-syntax-highlighter";

class ComputerVision extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Инструкция по работе с компьютерным зрением.</h4>

                <p>
                    <strong>Шаг 1</strong><br/>
                    Описание подпрограмм для работы с функциями компьютерного зрения. Arduino.<br/>
                    Для функционирования системы компьютерного зрения необходимо использовать библиотеку
                    Wire для передачи данных между Arduino и Raspberry pi.
                </p>

                <SyntaxHighlighter language="c" style={a11yLight}>
                    {"#include<Wire.h>"}
                </SyntaxHighlighter>

                <p>
                    <strong>Шаг 2</strong><br/>
                    Используемые глобальные переменные для корректной работы функций следует объявить
                    следующие глобальные переменные:
                </p>

                <SyntaxHighlighter language="c" style={a11yLight}>
                    {"int SLAVE_ADDRESS = 0x04; //Адрес устройства КЗ\n" +
                    "int xpos = 404; // Позиция х в относительных координатах\n" +
                    "int ypos = 404; // Позиция y\n" +
                    "byte rd; // байт данных передающийся по каналу связи\n" +
                    "int msg = 201; // режим работы системы КЗ"}
                </SyntaxHighlighter>

                <p>
                    <strong>Примечание:</strong> переменная SLAVE_ADDRESS  должна иметь то же значение, что и аналогичная
                    переменная в коде Python на стороне Raspberry. По-умолчанию это значение 0х04.
                </p>

                <p>
                    Переменные xpos и ypos имеют рабочий диапазон значений от 1 до 99, прочие значения
                    этих переменных должны игнорироваться программой.
                </p>

                <p>
                    <strong>Шаг 3</strong><br/>
                    Настройка среды передачи выполняется в методе void setup().<br/>
                    Необходимо добавить в метод setup() следующий код:
                </p>

                <SyntaxHighlighter language="c" style={a11yLight}>
                    {"Wire.begin(SLAVE_ADDRESS);\n" +
                    "  Wire.onReceive(processMessage);\n" +
                    "  Wire.onRequest(sendAnalogReading);"}
                </SyntaxHighlighter>

                <p>
                    Также необходимо создать объявить следующие функции в конце файла с
                    программой (после функции loop()):
                </p>

                <SyntaxHighlighter language="c" style={a11yLight}>
                    {"//data transfering\n" +
                    "void processMessage(int n) {\n" +
                    "  rd = Wire.read();\n" +
                    "}\n" +
                    "\n" +
                    "void sendAnalogReading() {\n" +
                    "  int reading  = msg;\n" +
                    "  Wire.write(reading);\n" +
                    "}"}
                </SyntaxHighlighter>

                <p>
                    Также непосредственно перед функцией loop() добавьте следующую строку:
                </p>

                <SyntaxHighlighter language="c" style={a11yLight}>
                    {"void(* resetFunc) (void) = 0;"}
                </SyntaxHighlighter>

                <p>
                    В функцию loop() добавьте следующую команду:
                </p>

                <SyntaxHighlighter language="c" style={a11yLight}>
                    {"if(Wire.available()!=0)\n" +
                    "      resetFunc();"}
                </SyntaxHighlighter>

                <p>
                    Вызов resetFunc() будет производить перезагрузку платы Arduino в случае ошибки
                    на этапе передачи данных.
                </p>
            </div>
        )
    }
}

export default ComputerVision