import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Bluetooth extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль Bluetooth</h4>
                <p>
                    Модуль Bluetooth представляет собой печатную плату-адаптер со встроенным интерфейсом
                    Bluetooth.<br/>
                    Внешний вид печатной платы модуля Bluetooth приведен на рисунке 2.19.<br/>
                    Внешний вид модуля Bluetooth приведен на рисунке 2.20.
                </p>

                <p>
                    В модуле Bluetooth установлен стабилизатор напряжения 3,3 В (для электропитания
                    установленных микросхем) и светодиод индикации наличия обмена данными по интерфейсу
                    Bluetooth (при наличии обмена данными светится непрерывно, при отсутствии – мигает).
                </p>

                <p>
                    Модуль Bluetooth соединяется с платой расширения через встроенный в плату
                    расширения соединитель, либо с контроллером по последовательному интерфейсу UART. Для
                    работы достаточно стандартных библиотек Arduino IDE.
                </p>

                <p>
                    Модуль Bluetooth имеет следующие контакты (см. рисунок 2.19):<br/>
                    - <strong>VCC</strong> , <strong>GND</strong> – плюс и минус электропитания;<br/>
                    - <strong>RXD</strong> и <strong>TXD</strong> – приемник и передатчик;<br/>
                    - <strong>STATE</strong> – подключение светодиода;<br/>
                    - <strong>EN</strong> – отключение модуля Bluetooth.
                </p>

                <p>
                    Модуль Bluetooth не может самостоятельно подключаться к другим устройствам
                    Bluetooth. Все настройки для подключения (пароль, скорость передачи данных) можно
                    изменить при помощи АТ-команд.
                </p>

                <p>
                    Области применения модуля Bluetooth:<br/>
                    - управление роботами и другими электронными устройствами;<br/>
                    - передача данных от устройств, датчиков, модулей, установленных на роботе;<br/>
                    - системы "умного дома".
                </p>

                <Image src={"/Constructor/bluetooth-board.png"} name={"Внешний вид печатной платы модуля Bluetooth"} number={"2.19"}/>
                <Image src={"/Constructor/bluetooth-module.png"} name={"Внешний вид модуля Bluetooth"} number={"2.20"}/>

                <p>
                    Основные технические характеристики модуля Bluetooth приведены в таблице 2.18.
                </p>

                <Table name={"Основные технические характеристики модуля Bluetooth"}
                       number={"2.18"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Основной модуль", "НС-05/НС-06"],
                           ["Напряжение электропитания", "от 3,3 до 6 В"],
                           ["Максимальное входное напряжение логической единицы", "5 В"],
                           ["Номинальное значение выходное напряжение логической единицы", "3,3 В"],
                           ["Максимальный ток потребления", "50 мА"],
                           ["Скорость передачи данных", "от 1200 до 1382400 бод"],
                           ["Дальность связи при прямой видимости", "до 30 м"],
                           ["Тип соединителя подключения", "PBS-6"],
                       ]}/>
            </div>
        )
    }
}

export default Bluetooth