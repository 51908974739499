import { createStore, applyMiddleware, compose } from 'redux';

import thunkMiddleware from 'redux-thunk'
import rootReducer from '../reducers';


function saveToLocalStorage(state) {
    let copiedState = Object.assign({}, state);
    delete copiedState.docsSearch;
    try {
        const serializedState = JSON.stringify(copiedState)
        localStorage.setItem('state', serializedState)
    }catch(e){
        console.log(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state')
        if(serializedState === null) return undefined
        return JSON.parse(serializedState)
    }catch (e) {
        return undefined
    }
}

const persistedState = loadFromLocalStorage()

const store = createStore(rootReducer, persistedState, compose(
    applyMiddleware(thunkMiddleware),

    //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
    window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : function (f) {return f;}
));

const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
});

export default store;