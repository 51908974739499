import React, {Component} from 'react';

class FailPayment extends Component {
    render() {
        return (
                <div className={"menu-padding-section"}>
                    <section className="section-b-space light-layout fail-payment">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="error-text">
                                        <i className="fa fa-ban" aria-hidden="true"/>
                                        <h2>Произошла ошибка</h2>
                                        <p>Заказ отменен</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
        )
    }
}

export default FailPayment;