import React, {Component} from 'react'

class Items extends Component {
    render() {
        let {block} = this.props

        return (
            <div className={`items`}>
                {
                    block.items.map(item => {
                        let path = `/docs/${block.id}/${item.id}`;
                        return (
                            <a href={path} key={item.id}>
                                <div
                                    className={`item ${window.location.pathname === path ? "selected" : ""}`}>
                                        <span className={"mark"} style={{background: block.markColor}}/>
                                    {item.name}
                                </div>
                            </a>
                        )
                    })
                }
            </div>
        )
    }
}

export default Items