import React, {Component} from 'react';

class TopBar extends Component {
    render() {
        return (
            <div className="top-header top-header-dark3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-right contacts">
                            <ul className="header-dropdown">
                                <li className={"main-schedule"}>
                                    <i className="fa fa-clock-o"/>
                                    Пн-Пт: 9:00 - 18:00
                                </li>
                            </ul>

                            <ul className="header-dropdown">
                                <li className={"mobile-hidden"}>
                                    <i className="fa fa-envelope-o" aria-hidden="true"/>
                                    <a href="mailto:info@omegabot.ru">info@omegabot.ru</a>
                                </li>
                                <li className={"phone"}>
                                    <i className="fa fa-phone" aria-hidden="true"/>
                                    <a href="tel:+78124263110">+7 (812) 426-31-10</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TopBar;