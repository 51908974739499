import React, {Component} from "react";
import Description from "./Description";
import Downloads from "./Downloads";
import Requirements from "./Requirements";
import TechVision from "./TechVision";
import Helmet from "react-helmet/es/Helmet";

class Software extends Component {
    render() {
        return (
            <>
                <Helmet><title>Загрузка ПО</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className="section-b-space container software-page">
                        <div className="row">
                            <div className={"col"}>
                                <h2 className={"text-center header"}>Omegabot IDE</h2>
                                <Description/>
                                <Downloads/>
                                <Requirements/>
                                <TechVision/>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default Software;