import React, {Component} from 'react';
import Item from "./Item";

class Tariff extends Component {
    render() {
        return (
            <section>
                <div className={'tariff'}>
                    <h2>Доставка курьерской службой</h2>

                    <div className={'row items'}>
                        <Item place={<>по санкт-петербургу<br/>от 350 руб.</>}
                              restrictions={<>в зависимости<br/>от габаритов посылки<br/>до указанного адреса</>}
                              time={<>в течение<br/>3-5 рабочих дней</>}/>

                        <Item place={<>по москве<br/>от 500 руб.</>}
                              restrictions={<>в зависимости<br/>от габаритов посылки<br/>до указанного адреса</>}
                              time={<>в течение<br/>4-7 рабочих дней</>}/>

                        <Item place={<>по россии<br/>от 500 руб.</>}
                              restrictions={<>в зависимости<br/>от габаритов посылки</>}
                              time={<>срок доставки<br/>от 5 рабочих дней</>}/>
                    </div>
                </div>
            </section>
        )
    }
}

export default Tariff