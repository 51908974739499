import axios from 'axios';
import {PRODUCT_DETAIL, PRODUCTS} from "../../constants/axiosURLs";

export function getProductsList(parameters, thenFunc, catchFunc) {
    axios.get(PRODUCTS, { withCredentials: true, params: parameters })
        .then(thenFunc)
        .catch(catchFunc);
}

export function getProductDetail(productId, thenFunc, catchFunc) {
    axios.get(PRODUCT_DETAIL(productId), {withCredentials: true})
        .then(thenFunc)
        .catch(catchFunc);
}