import React, {Component} from 'react';
import Balloon from "./Balloon";

class Mark extends Component {
    render() {
        return (
            <div className={"city-mark " + this.props.className}>
                <div className={"circle"}> <div/> </div>
                <span>{this.props.name}</span>
                <Balloon reviews={this.props.reviews} city={this.props.name}/>
            </div>
        )
    }
}

export default Mark