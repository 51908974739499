import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class LED extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль светодиода</h4>

                <p>
                    Модуль светодиода представляет собой печатную плату с установленным
                    светодиодом. Внешний вид модуля светодиода приведен на рисунке 2.15.
                </p>

                <p>
                    Области применения модуля светодиода:<br/>
                    - обеспечение освещения поверхности и пространства в тёмном помещении на пути следования;<br/>
                    - обозначение габаритов движущегося средства в тёмное и светлое время суток;<br/>
                    - в Конструкторе используется в качестве тренировочного элемента;<br/>
                    - использование в качестве индикатора (например, соединение прошло успешно).
                </p>

                <p>
                    При поступлении на вход модуля светодиода сигнала с номинальным значением 5 В светодиод
                    начинает излучать свет в видимом диапазоне.
                </p>

                <Image src={"/Constructor/led.png"} name={"Внешний вид модуля светодиода"} number={"2.15"}/>

                <p>
                    Основные технические характеристики модуля светодиода приведены в таблице 2.14.
                </p>

                <Table name={"Основные технические характеристики модуля светодиода"}
                       number={"2.14"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Цвет свечения", "зеленый"],
                           ["Номинальное значение длины волны", "570 нм"],
                           ["Минимальная сила света", "345 мКд"],
                           ["Максимальная сила света", "485 мКд"],
                           ["Видимый телесный угол", "30°"],
                           ["Цвет линзы", "бесцветный"],
                           ["Форма линзы", "круглая"],
                           ["Размер линзы", "10 мм"],
                           ["Максимальное обратное напряжение", "5 В"],
                           ["Максимальный импульсный прямой ток", "150 мА"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Диаметр диода", "10 мм"],
                           ["Номинальное значение напряжения электропитания", "5 В"],
                           ["Средний ток потребления", "20 мА"],
                       ]}/>
            </div>
        )
    }
}

export default LED