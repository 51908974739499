import React, {Component} from 'react';
import Details from "./Details";
import ContactForm from "./ContactForm";
import ContactsFooter from "./ContactsFooter";
import Helmet from "react-helmet/es/Helmet";

class Support extends Component {
    render() {
        return (
            <>
                <Helmet><title>Техническая поддержка</title></Helmet>

                <div className={"menu-padding-section support"}>
                    <section className="section-b-space container">
                        <div className="row">
                            <div className="col">
                                <h2 className={"text-center header"}>Техническая поддержка</h2>
                                <Details/>
                                <ContactForm/>
                            </div>
                        </div>
                    </section>
                    <ContactsFooter/>
                </div>
            </>
        )
    }
}

export default Support