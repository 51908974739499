import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Item from './Item'
import {getCartTotal} from '../../../../../assets/js/functions'
import {SYMBOL} from "../../../../../constants";

const Cart = ({cartList, total}) => (
    <div>
        <div className="icon-nav">
            <ul>
                <li className="onhover-div mobile-cart menu-cart">
                    <div className="cart-qty-cls">{cartList.length}</div>
                    <Link to={`/cart`}>
                        <img src={`/assets/images/common/icon/cart.png`}
                             className="img-fluid cart-icon" alt=""/>
                    </Link>

                    <ul className="show-div shopping-cart">
                        {cartList.length > 0 ?
                            <li className={"toolbar"}>
                                <div className="buttons">
                                    <a href={`/cart`} className="view-cart">Посмотреть</a>
                                    <a href={`/checkout`} className="checkout">Оформить</a>
                                </div>
                            </li> : null}

                        {cartList.map(item => <Item key={item.id} item={item}/>)}

                        {(cartList.length > 0) ?
                            <div><li><div className="total">
                                <h5>Итого : <span>{total} {SYMBOL}</span></h5>
                            </div></li></div>
                            : <li><h5 className={"empty-cart-message"}>Корзина пуста</h5></li>}
                    </ul>
                </li>
            </ul>
        </div>
    </div>
)


function mapStateToProps(state) {
    return {
        cartList: state.cartList.cart,
        total: getCartTotal(state.cartList.cart),
    }
}

export default connect(mapStateToProps) (Cart);
