import React, {Component} from 'react';
import {blocks} from "../../../../constants/Pages/MenuPages/education/theory";
import Block from "./Block";

class Theory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentContent: blocks[0].content,
            selectedBlock: blocks[0].id
        }

        this.changeContent = this.changeContent.bind(this);
    }

    changeContent(content, block) {
        this.setState({
            currentContent: content,
            selectedBlock: block
        });
    }

    render() {
        return (
            <>
                <h2 className={"text-center"}>Теоретический материал<br/>для подготовки</h2>
                <section className={"theory section-b-space"}>
                    <div className={"blocks"}>
                        {
                            blocks.map(block =>
                                <Block key={block.id}
                                       name={block.name}
                                       color={block.color}
                                       isSelected={block.id === this.state.selectedBlock}
                                       onChange={() => this.changeContent(block.content, block.id)} />
                            )
                        }
                    </div>
                    <div className={"content"}>{ this.state.currentContent }</div>
                </section>
            </>
        )
    }
}

export default Theory