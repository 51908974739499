import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P3Subject extends Component {
    render() {
        return (
            <AccordionItem title={<li>Предмет соглашения</li>}>
                <div>

                    <ol>
                        <li>Предметом настоящего Пользовательского соглашения является предоставление Пользователю
                            доступа к информации и онлайн сервисам, представленным на Сайте.</li>

                        <li>Сайт предоставляет Пользователю следующие виды сервисов:
                            <ol>
                                <li>свободный доступ к информации (текстовой и графической) с правом
                                    просмотра данной информации;</li>

                                <li>доступ к средствам поиска и навигации Сайта;</li>

                                <li>предоставление Пользователю возможности запросов стоимости,
                                    услуг, отзывов и иной информации (обратная связь);</li>

                                <li>доступ к информации об оказываемых услугах, специальных акциях,
                                    бонусах и скидках;</li>

                                <li>заказ услуг.</li>
                            </ol>
                        </li>

                        <li>Под действие настоящего Пользовательского соглашения подпадают все существующие на данный
                            момент сервисы Сайта, а также любые их последующие модификации и появляющиеся в дальнейшем
                            сервисы.</li>

                        <li>Доступ к Сайту предоставляется на безвозмездной основе.</li>

                        <li>Настоящее Пользовательское соглашение является публичной офертой. Получая доступ к Сайту,
                            Пользователь соглашается с условиями Пользовательского соглашения и считается
                            присоединившимся к настоящему Соглашению.</li>

                        <li>Использование материалов и сервисов Сайта регулируется нормами действующего
                            законодательства Российской Федерации.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P3Subject;