import React, {Component} from 'react';
import Exercise from "./Exercise";
import Helmet from "react-helmet/es/Helmet";

class ExampleItem extends Component {
    render() {
        let { name, exercises } = this.props;
        return (
            <>
                <Helmet><title>Примеры кода: { name }</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className={"section-b-space container example-item"}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <h2 className={"text-center header"}>{ name }</h2>

                                {
                                    exercises.map(exercise =>
                                        <Exercise content={exercise} key={exercise.id}/>
                                    )
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default ExampleItem