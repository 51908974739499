import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from "react-redux";
import ShortDetails from "./ShortDetails";
import TabsDetails from "./TabsDetails";
import { addToCart } from '../../../../actions';
import {ToastContainer} from "react-toastify";
import {getProductDetail} from "../../../../assets/axios/products";
import Modal from "react-responsive-modal";
import Service from "./Service";
import {makeProductTabs} from "./tab-contents";
import AdvancedFiles from "./AdvancedFiles";
import Images from "./Images";



class ItemDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            modalContent: '',
            detail: {}
        };
    }

    componentDidMount() {
        getProductDetail(
            this.props.productId,
            (response) => this.changeDetail(response.data),
            () => this.onOpenModal("Не удалось загрузить информацию о товаре")
        );
    }

    changeDetail(data) {
        this.setState( { detail: data });
    }

    onOpenModal = (content) => {
        this.setState({ modalOpen: true, modalContent: content });
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false, modalContent: '' });
    };

    render(){
        const { addToCart } = this.props;
        const { detail } = this.state;

        return (
            <>
                <Helmet><title>{detail.name}</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className={"section-b-space container product-details"}>
                        <div className="row">
                            <div className={"col"}>
                                <div className={"row"}>
                                    <Service/>
                                    {detail
                                        ? <div className="col-lg-9 col-sm-12 col-xs-12">
                                            <div className="row">
                                                <Images detail={detail} />
                                                <ShortDetails item={detail}
                                                              addToCartClicked={addToCart}/>
                                            </div>

                                            <TabsDetails tabs={makeProductTabs(detail)}/>

                                            {this.state.detail.files && this.state.detail.files.length
                                                    ? <AdvancedFiles files={this.state.detail.files}/>
                                                    : null}
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Modal open={this.state.modalOpen} onClose={this.onCloseModal} center>
                    <div className={"modal-warning"}>
                        {this.state.modalContent}
                    </div>
                </Modal>
                <ToastContainer/>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        productId: ownProps.match.params.id,
    }
}

export default connect(mapStateToProps, {addToCart}) (ItemDetails);