import {Component} from 'react';
import {connect} from "react-redux";
import {updateDocsSearchContent} from "../../../actions";
import ReactDOM from "react-dom";

class SearchWrapper extends Component {
    constructor(props) {
        super(props);
        let self = this;
        document.addEventListener("DOMContentLoaded", function () {
            self.props.updateDocsSearchContent(self.makeSearchable(props.content))
        });
    }

    render() {
        return (
            this.props.children
        )
    }

    makeSearchable(menuObj) {
        let searchable = [];

        for (let block of menuObj) {
            for (let item of block.items) {
                let searchContainer = document.getElementById('search-container');
                ReactDOM.render(item.content, searchContainer);
                let text = searchContainer.querySelector('.section').textContent;
                let childs = Array.from(
                    searchContainer
                        .querySelector('.section')
                        .childNodes);

                let searchItem = {
                    name: item.name,
                    link: `/docs/${block.id}/${item.id}`,
                    text: text,
                    childs: childs
                }

                searchable.push(searchItem);
            }
        }

        return searchable;
    }
}

export default connect(state => state, { updateDocsSearchContent }) (SearchWrapper);