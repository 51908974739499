import React, {Component} from 'react';
import {connect} from "react-redux";
import {decrementQty, incrementQty} from "../../../../../../actions";

class Quantity extends Component {
    render() {
        let { item } = this.props;

        return (
            <td>
                <div className="qty-box">
                    <div className="input-group">
                        <span className="input-group-prepend">
                            <button type="button"
                                    className="btn quantity-left-minus"
                                    onClick={() => this.props.decrementQty(item.id)}
                                    data-type="minus" data-field="">
                                <i className="fa fa-angle-left"/>
                            </button>
                        </span>

                        <input type="text" name="quantity"
                               value={item.qty}
                               onChange={this.props.onChange}
                               className="form-control input-number"/>

                        <span className="input-group-prepend">
                            <button className="btn quantity-right-plus"
                                    onClick={() => this.props.incrementQty(item, 1)}
                                    data-type="plus"
                                    disabled={item.qty >= item.stock}>
                                <i className="fa fa-angle-right"/>
                            </button>
                        </span>
                    </div>
                </div>
                {(item.qty >= item.stock) ? 'распродано' : ''}
            </td>
        )
    }
}

export default connect(() => ({}), {incrementQty, decrementQty}) (Quantity)