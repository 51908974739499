import React, {Component} from 'react';
import ExampleItem from "./ExampleItem";
import {advancedExercises} from "../../../../constants/Pages/MenuPages/education/exercises/advanced/exercises";

class AdvancedExample extends Component {
    render() {
        return (
            <ExampleItem name={'СТАРТ — РАСШИРЕННЫЙ'} exercises={advancedExercises}/>
        )
    }
}

export default AdvancedExample