import React, {Component} from 'react';

class ContactBlock extends Component {
    render() {
        return (
            <div className={"contacts-block"}>
                <div className={"header"}>Мы ответим<br/>на все ваши вопросы</div>
                <div className={"phone"}><a href={"tel:+78124263110"}>+7 (812) 426 31 10</a> <i className="fa fa-phone" aria-hidden="true"/></div>
                <div className={"email"}><a href={"mailto:info@omegabot.ru"}>info@omegabot.ru</a> <i className="fa fa-envelope-o" aria-hidden="true"/></div>
            </div>
        )
    }
}

export default ContactBlock