import BaseTheory from "../../../../components/MenuPages/Education/theory/BaseTheory";
import React from "react";
import AdvancedTheory from "../../../../components/MenuPages/Education/theory/AdvancedTheory";
import VisionTheory from "../../../../components/MenuPages/Education/theory/VisionTheory";

export const blocks = [
    {
        id: 'base',
        name: "базовый курс",
        color: '#44b7c4',
        content: <BaseTheory/>
    },
    {
        id: 'advanced',
        name: "продвинутый курс",
        color: '#3998a3',
        content: <AdvancedTheory/>
    },
    {
        id: 'vision',
        name: <>курс<br/>«техническое зрение»</>,
        color: '#444241',
        content: <VisionTheory/>
    },
]