import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class ColorSensor extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль датчика цвета</h4>

                <p>
                    Данный модуль представляет собой плату с микросхемой AMS TCS34725, которая
                    содержит 12 светочувствительных элементов. Модуль отдельно измеряет RGB-составляющие
                    и уровень освещённости, что позволяет использовать его для определения цветовой
                    температуры освещения или автоматической сортировки предметов по цветам. Внешний вид
                    модуля датчика цвета приведен на рисунке 2.17.
                </p>

                <p>
                    Области применения модуля:<br/>
                    – определение цвета предмета
                </p>

                <p>
                    Микросхема, содержащая 12 светочувствительных элементов оснащена цветными фильтрами
                    для измерения красной, зелёной и синей составляющей. Часть из светочувствительных
                    элементов определяют общий уровень освещённости. Каждая группа фотоэлементов подключена
                    к своему АЦП, который выдаёт 16-битный сигнал, пропорциональный интенсивности падающего
                    света. Готовые результаты измерений передаются по интерфейсу I²C.
                </p>

                <p>
                    На модуле предусмотрен белый светодиод, который подсвечивает предметы, а для измерения
                    самоизлучающих источников света его можно отключить. Встроенный ИК-фильтр отсекает лишний
                    невидимый спектр и повышает чувствительность датчика. Это позволяет устанавливать его за
                    защитным тёмным стеклом, не теряя динамического диапазона.
                </p>

                <Image src={"/Constructor/color-sensor.png"} name={"Внешний вид модуля датчика цвета"} number={"2.17"}/>

                <p>
                    Основные технические характеристики модуля датчика цвета приведены в таблице 2.16.
                </p>

                <Table name={"Основные технические характеристики модуля датчика цвета"}
                       number={"2.16"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Сенсор", "AMS TCS34725"],
                           ["Подсветка", "белый светодиод (LED)"],
                           ["Детектируемые цвета", "RGB, общая освещённость"],
                           ["Интерфейс", "I²C"],
                           ["Напряжение питания", "3,3–5 В"],
                           ["Потребляемый ток", "<300 мкА"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                       ]}/>
            </div>
        )
    }
}

export default ColorSensor