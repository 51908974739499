import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class MagnetSensor extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль магнитного датчика</h4>

                <p>
                    Модуль магнитного датчика представляет собой печатную плату с установленным
                    чувствительным элементом – датчиком Холла. Внешний вид модуля магнитного датчика
                    приведен на рисунке 2.13. При изменении магнитного поля рядом с датчиком на
                    выходе датчика формируется разность потенциалов.
                </p>

                <p>
                    2.13.2 Области применения модуля магнитного датчика:<br/>
                    - поиск роботом магнита;<br/>
                    - движение роботом по магнитному рельсу.
                </p>

                <p>
                    При изменении магнитного поля в непосредственной близости от магнитного датчика
                    на его выходе изменяется уровень напряжения.
                </p>

                <Image src={"/Constructor/magnet-sensor.png"} name={"Внешний вид модуля магнитного датчика"} number={"2.13"}/>

                <p>
                    Основные технические характеристики модуля магнитного датчика приведены в таблице 2.12.
                </p>

                <Table name={"Основные технические характеристики модуля магнитного датчика"}
                       number={"2.12"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Диапазон напряжения электропитания", "от 2,7 до 6,5 В"],
                           ["Чувствительность", "1,4 мВ/ Гаусс"],
                           ["Время отклика, не более", "3 мкс"],
                           ["Выходное напряжение", "1,4 мВ/Гс"],
                           ["Линейность (% от диапазона)", "0,7 %"],
                           ["Диапазон рабочей температуры", "от -40 до 100°C"],
                           ["Индукция вкл.", "1000 Гаусс"],
                           ["Индукция выкл.", "-1000 Гаусс"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Диапазон выходного напряжения", "от 1 до 4 В"],
                           ["Номинальное напряжение электропитания", "5 В"],
                           ["Средний ток потребления", "6 мА"],
                       ]}/>
            </div>
        )
    }
}

export default MagnetSensor