import React, {Component} from 'react';

class DeliveryData extends Component {
    render() {
        let { values, validator } = this.props;
        
        return (
            <div className={"delivery-data"}>
                <div className="row delivery-type">
                    <div className="form-group col-sm-6 col-xs-12">
                        <div className="field-label">Самовывоз/доставка через СДЭК</div>
                        <input type="radio" name="delivery_type" value={""}
                               onChange={() => {}}
                               onClick={this.props.onChange}
                               checked={values.delivery_type !== 'office'}/>
                    </div>
                    <div className="form-group col-sm-6 col-xs-12">
                        <div className="field-label">Самовывоз из офиса</div>
                        <input type="radio" name="delivery_type" value={"office"}
                               onChange={() => {}}
                               onClick={this.props.onChange}
                               checked={values.delivery_type === 'office'}/>
                    </div>
                </div>

                <div className="form-group col-12 sdek-delivery-types">
                    <div className="field-label"
                         style={{display: values.delivery_type === 'office' ? 'none' : 'block'}}>
                        Выберите способ доставки через СДЭК *
                        <div style={{fontWeight: "400"}}>
                            { validator.message('delivery_city', values.delivery_city, 'required') }
                        </div>
                    </div>
                    <div className="pvzMap"
                         style={{display: values.delivery_type === 'office' ? 'none' : 'block'}}>
                        <div id="forpvz" style={{width: "100%", height: "600px"}}/>
                    </div>
                </div>

                {values.delivery_type === 'courier'
                        ? <div className="form-group col-12">
                            <div className="field-label">Адрес доставки *</div>
                            <input type="text" name="delivery_address" value={values.delivery_address}
                                   onChange={this.props.onChange}/>
                            {validator.message('delivery_address', values.delivery_address, 'required')}
                        </div>
                        : null}
            </div>
        )
    }
}

export default DeliveryData