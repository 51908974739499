import React, {Component} from 'react';
import OrderItems from "./OrderItems";
import OrderDetails from "./OrderDetails";

class Order extends Component {
    render() {
        let { order } = this.props;
        return (
            <section className="section-b-space">
                <div className="container success-payment">
                    <div className="row">
                        <OrderItems order={order}/>
                        <OrderDetails order={order}/>
                    </div>
                </div>
            </section>
        )
    }
}

export default Order