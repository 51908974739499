import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P7Responsibility extends Component {
    render() {
        return (
            <AccordionItem title={<li>Ответственность сторон</li>}>
                <div>
                    <ol>
                        <li>Оператор, не исполнивший свои обязательства, несёт ответственность за убытки, понесённые
                            Пользователем в связи с неправомерным использованием персональных данных, в соответствии с
                            законодательством Российской Федерации, за исключением случаев, предусмотренных
                            п. 5.2., п. 5.3. и п. 7.2. настоящей Политики Конфиденциальности.</li>

                        <li>В случае утраты или разглашения Конфиденциальной информации Оператор не несёт
                            ответственность, если данная конфиденциальная информация:
                            <ol>
                                <li>Стала публичным достоянием до её утраты или разглашения.</li>

                                <li>Была получена от третьей стороны до момента её получения Оператором.</li>

                                <li>Была разглашена с согласия Пользователя.</li>

                                <li>Была разглашена сторонними сайтами и сервисами, ссылки на использование которых
                                    присутствуют на Сайте, в случае использования таких ссылок и сервисов
                                    Пользователем.</li>
                            </ol>
                        </li>

                        <li>Пользователь самостоятельно несет ответственность за возможные последствия в случае
                            представления недостоверных и/или неполных персональных данных.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P7Responsibility;