import React, {Component} from 'react';

class Map extends Component {
    render() {
        return (
            <section>
                <div className={'map'}>
                    <div id="deliverypvz" style={{width: "100%", height: "600px"}}></div>
                </div>
            </section>
        )
    }
}

export default Map