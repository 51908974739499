import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Controller extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Контроллер</h4>

                <p>
                    Внешний вид контроллера на плате расширения приведен на рисунке 2.2. Универсальный
                    программируемый контроллер представляет собой компактный одноплатный модуль.
                    Поддержка архитектуры Arduino обеспечивает возможность использования огромной открытой
                    базы библиотек (готовых программных модулей) для решения самых разнообразных учебных
                    и технических задач, а также совместимость с физическими модулями (сенсорами, исполнительными
                    устройствами) разных производителей, также поддерживающих всемирно известную платформу.
                </p>

                <Image src={"/Constructor/controller.png"} name={"Внешний вид контроллера на плате расширения"} number={"2.2"}/>

                <p>
                    Основные технические характеристики контроллера приведены в таблице 2.2.
                </p>

                <Table name={"Основные технические характеристики контроллера"}
                       number={"2.2"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Наименование микроконтроллера", "ATmega328"],
                           ["Номинальное значение рабочего напряжения", "5 В"],
                           ["Напряжение электропитания (рекомендуемое)", "от 7 до 12 В"],
                           ["Напряжение электропитания (предельное)", "от 6 до 20 В"],
                           ["Количество цифровых входов/выходов", "14 (из них 6 могут использоваться в качестве ШИМ-выходов)"],
                           ["Количество аналоговых входов", "6"],
                           ["Максимальный ток одного вывода", "40 мА"],
                           ["Максимальный выходной ток вывода 3.3 В", "50 мА"],
                           ["Flash-память", "32 КБ (ATmega328) из которых 0,5 КБ используются загрузчиком"],
                           ["SRAM", "2 КБ (ATmega328)"],
                           ["EEPROM", "1 КБ (ATmega328)"],
                           ["Тактовая частота", "16 МГц"]
                       ]}/>

                <p>
                    <strong>Описание контроллера</strong><br/>
                    Выводы контроллера, используемые для подключения внешних устройств, могут работать
                    как в режиме входа "INPUT", так и в режиме выхода "OUTPUT".<br/>
                    Допустимый ток на каждом из выходов – 20 мА, не более 40 мА в пике.<br/>
                    Для удобства работы некоторые выводы совмещают в себе несколько функций.<br/>
                    Выводы с номерами от "0" до "13" являются цифровыми и могут считывать и принимать
                    только два вида сигналов: HIGH и LOW. Также с помощью ШИМ можно использовать определённые
                    цифровые порты для управления мощностью подключенных устройств.<br/>
                    Аналоговые выводы предназначены для подключения аналоговых устройств и являются входами
                    для встроенного десятиразрядного аналого-цифрового преобразователя (АЦП).<br/>
                    Контроллер имеет дополнительные выводы:<br/>
                    - AREF – выдает опорное напряжения для встроенного АЦП. Может управляться функцией
                    analogReference();<br/>
                    - RESET – подача низкого сигнала на этом входе приведет к перезагрузке устройства.
                </p>

                <p>
                    <strong>Подключение устройств</strong><br/>
                    Подключение любых устройств к контроллеру осуществляется путем присоединения к
                    выводам, расположенным на печатной плате контроллера: цифровым или аналоговым выводам,
                    или выводам электропитания.<br/>
                    При любом варианте подключения внешнего устройства необходимо помнить, что использование
                    печатной платы контроллера в качестве источника электропитания возможно только в том
                    случае, если ток потребления подключаемого устройства не превышает максимального выходного
                    тока контроллера по цепи подключения.
                </p>
            </div>
        )
    }
}

export default Controller