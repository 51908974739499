import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Accumulator extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Аккумуляторный блок</h4>

                <p>
                    Аккумуляторный блок представляет собой печатную плату, которая совмещает в себе:<br/>
                    - кассету для двух аккумуляторов "18650";<br/>
                    - схему защиты от переполюсовки аккумуляторов;<br/>
                    - схему защиты аккумуляторов от перезаряда, переразряда, тока короткого замыкания;<br/>
                    - схему зарядного устройства с возможностью балансировки заряда аккумуляторов с температурной защитой.<br/>
                    Внешний вид аккумуляторного блока приведен на рисунке 2.24.
                </p>

                <Image src={"/Constructor/accumulator.png"} name={"Внешний вид аккумуляторного блока"} number={"2.24"}/>

                <p>
                    На печатной плате аккумуляторного блока установлены следующие соединители:<br/>
                    - <strong>LED</strong> – предназначен для подключения сдвоенного светодиода – зелёного цвета, для
                    контроля состояния схемы защиты и красного – для индикации процесса зарядки;<br/>
                    - <strong>SW</strong> – предназначен для подключения кнопки с фиксацией, включающую подачу питания с
                    аккумуляторов на соединитель OUT;<br/>
                    - <strong>CHARGE</strong> – предназначен для зарядки аккумуляторов при подключении адаптера электропитания
                    зарядного устройства;<br/>
                    - <strong>OUT</strong> – предназначен для электропитания внешних потребителей от аккумуляторов.
                </p>

                <p>
                    В аккумуляторном блоке заложена возможность использования двух типов микросхем, сочетающих в себе
                    защиты от перезаряда, переразряда и тока короткого замыкания. Первая реализует порог срабатывания
                    защиты от переразряда на уровне 2,7 В, вторая на уровне 3 В. Пороги защиты от перезаряда практически
                    идентичны у обоих микросхем. Порог тока короткого замыкания у первой порядка 8 А, у второй
                    порядка 10 А.<br/>
                    Применённая схема зарядного устройства способна отдавать зарядный ток 1 А для зарядки обоих
                    аккумуляторов одновременно. Напряжение окончания заряда аккумуляторов – 4,2 В на каждый. Точность
                    балансирования заряда ± 30 мВ.
                </p>

                <p>
                    Алгоритм индикации на сдвоенном светодиоде следующий: при замыкании кнопки, подключённой к
                    соединителю <strong>SW</strong>, а также отсутствии короткого замыкания по цепям, подключенным
                    к соединителю <strong>OUT</strong>, и остаточному напряжению на аккумуляторах, большему, чем
                    пороговое переразряда, светится зелёный светодиод. Как только в процессе работы напряжение на
                    одном из аккумуляторов упадёт ниже порогового от переразряда или ток нагрузки превысит ток
                    срабатывания защиты от короткого замыкания, светодиод прекращает светиться, что индицирует
                    срабатывание одной из указанных выше защит. Восстановление из этого состояния произойдёт при
                    следующем порядке действий:<br/>
                    - если произошло короткое замыкание нагрузки, то для сброса защиты необходимо на 5 с выключить
                    кнопку, подключённую к соединителю <strong>SW</strong>, и затем включить её обратно. Если короткое
                    замыкание устранено, светодиод начинает светиться, если не устранено, то светодиод не светится;
                    - если произошёл разряд одного из аккумуляторов или их обоих ниже порога защиты от переразряда,
                    то необходимо выключить кнопку, подключённую к соединителю <strong>SW</strong>, подключить адаптер
                    для электропитания зарядного устройства к соединителю <strong>CHARGER</strong>. По окончании процесса,
                    необходимо отключить адаптер от соединителя <strong>CHARGER</strong>, аккумуляторный блок будет готов
                    к работе и включение кнопки, подключённой к соединителю <strong>SW</strong>, вновь включит зелёный
                    светодиод.
                </p>

                <p>
                    Защита от перезаряда аккумуляторов нужна лишь в случае нештатной работы схемы зарядного устройства
                    либо при зарядке аккумулятора внешним зарядным устройством выше порога перезаряда. В первом случае
                    произойдёт остановка процесса заряда и прекратится свечение красного светодиода, во втором выходное
                    напряжение аккумулятора, отдаваемое в нагрузку, будет снижено подключением внутри микросхемы диода
                    в разрыв цепи земли, как только напряжение на самом аккумуляторе снизится до уровня 4,18 В, схема
                    переходит в нормальный режим работы, происходит сброс защиты от перезаряда.
                </p>

                <p>
                    Индикация зарядки следующая: при подключении адаптера для питания зарядного устройства к
                    соединителю <strong>CHARGER</strong>, начинает светиться красный светодиод, индицирующий начало
                    процесса заряда, по окончании процесса зарядки светодиод прекращает светиться. Светодиод может
                    прекратить свечение также при интенсивной зарядке и разогреве аккумуляторов, близком к граничному
                    температурному режиму. При восстановлении температурного режима заряд продолжится.
                </p>

                <p>
                    Схема защиты от переполюсовки аккумуляторов защищает как потребителей, подключаемых к
                    соединителю <strong>OUT</strong>, от инверсной полярности, так и сам блок аккумуляторов. Также
                    она защищает аккумуляторы в случае попытки их зарядки в неправильно установленном положении.
                </p>

                <p>
                    В процессе эксплуатации Конструктора рекомендуется использовать аккумуляторы, находящиеся в
                    комплекте поставки либо аккумуляторы с хорошей токоотдачей (без большой разницы по напряжению).
                </p>

                <p>
                    Основные технические характеристики аккумуляторного блока приведены в таблице 2.21.
                </p>

                <Table name={"Основные технические характеристики аккумуляторного блока"}
                       number={"2.21"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Габариты, Д х Ш", "103 х 42 мм"],
                           ["Тип аккумуляторов", "Li-Ion 18650"],
                           ["Пороговое напряжение разряда аккумулятора", "2,7 (1) В\n" +
                           "3 (2) В\n"],
                           ["Пороговое напряжение перезаряда аккумулятора", "4,28 (1) В;\n" +
                           "4,29 (2) В\n"],
                           ["Ток срабатывания защиты от короткого замыкания", "8 (1) А;\n" +
                           "10 (2) А\n"],
                           ["Номинальный ток заряда аккумуляторов", "1 А"],
                           ["Максимальный ток схемы балансирования заряда аккумуляторов", "0,95 А"],
                           ["Напряжение зарядного устройства", "от 12 до 24 В"],
                           ["Номинальный ток зарядного устройства", "1 А"],
                           ["Максимальное напряжение окончания заряда для каждого аккумулятора", "4,2 В"],
                           ["Ток потребления платы в режиме ожидания, не более", "30 мкА"],
                           ["Встроенное зарядное устройство", "имеется"],
                           ["Время зарядки", "3 часа"],
                           ["Время работы", "не менее 3 часов"],
                           ["Кнопка включения питания", "имеется"],
                           ["Цвет индикатора включения", "зелёный"],
                           ["Защита аккумулятора от короткого замыкания", "имеется"],
                           ["Цвет индикатора зарядки", "зелёный"],
                       ]}/>

               <p>
                   Основные технические характеристики аккумуляторов приведены в таблице 2.22.
               </p>

                <Table name={"Основные технические характеристики аккумуляторов"}
                       number={"2.22"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Типоразмер", "18650"],
                           ["Номинальная емкость", "3350 мАч"],
                           ["Номинальное напряжение", "3,6 В"],
                           ["Ток разряда пиковый", "13 А"],
                           ["Ёмкость минимальная", "3250 мАч"],
                           ["Напряжение максимальное", "4,20 ± 0,05 В"],
                           ["Напряжение минимальное", "2,65 В"],
                           ["Ток разряда номинальный", "8 А"],
                           ["Внутреннее сопротивление", "< 35 МОм"],
                           ["Температура заряда", "от 0 до +45̊С"],
                           ["Температура эксплуатации", "от -20 до +60̊С"],
                           ["Размер", "65,25 х 18,55 мм"],
                           ["Вес максимальный", "50 г"],
                       ]}/>
            </div>
        )
    }
}

export default Accumulator