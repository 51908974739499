import React, {Component} from 'react';

import {SlideUpDown} from "../../../assets/js/functions"
import Menu from "./Menu";
import SubFooter from "./SubFooter";

class Footer extends Component {
    componentDidMount() {
        if (window.innerWidth < 750) {
            SlideUpDown('footer-title');
        } else {
            let elements = Array.from( document.querySelectorAll(".footer-title") );

            elements.forEach(function (elem) {
                elem.nextElementSibling.style = "display: block";
            });
        }
    }

    render() {
        return (
            <footer>
                <Menu/>
                <SubFooter/>
            </footer>
        )
    }
}

export default Footer;