import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class TemperatureSensor extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль датчика температуры</h4>

                <p>
                    Модуль датчика температуры представляет собой печатную плату с установленным
                    чувствительным элементом – датчиком температуры DS18B20+. Внешний вид модуля
                    датчика температуры приведен на рисунке 2.14. Датчик температуры DS18B20+
                    позволяет определять температуру окружающего воздуха в диапазоне от минус 55
                    до +125°C и получать данные в виде цифрового сигнала.
                </p>

                <p>
                    Области применения модуля датчика температуры:<br/>
                    - измерение температуры окружающей среды;<br/>
                    - использование в качестве триггера.
                </p>

                <p>
                    Модуль датчика температуры преобразует температуру окружающей его среды в
                    цифровой код, который поступает на выходные контакты соединителя. Это
                    преобразование зависит от разрешения преобразования, установленного пользователем,
                    которое варьируется от 9 до 12 бит.
                </p>

                <Image src={"/Constructor/temperature-sensor.png"} name={"Внешний вид модуля датчика температуры"} number={"2.14"}/>

                <p>
                    Основные технические характеристики модуля датчика температуры приведены в таблице 2.13.
                </p>

                <Table name={"Основные технические характеристики модуля датчика температуры"}
                       number={"2.13"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Минимальная измеряемая температура", "-55°С"],
                           ["Максимальная измеряемая температура", "125°С"],
                           ["Чувствительный элемент", "цифровой температурный датчик"],
                           ["Точность", "0,5 %"],
                           ["Время ответа", "до 0,75 с (при разрешении 12 бит)"],
                           ["Измеряемая среда", "газ/поверхность"],
                           ["Выходной сигнал", "1-Wire"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Диапазон измерения температуры", "от – 55 до +125°C"],
                           ["Номинальное значение напряжения электропитания", "5 В"],
                           ["Средний ток потребления", "4 мА"],
                       ]}/>
            </div>
        )
    }
}

export default TemperatureSensor