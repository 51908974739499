import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P6Responsibility extends Component {
    render() {
        return (
            <AccordionItem title={<li>Ответственность</li>}>
                <div>
                    <ol>
                        <li>Любые убытки, которые Пользователь может понести в случае умышленного
                            или неосторожного нарушения любого положения настоящего Соглашения,
                            а также вследствие несанкционированного доступа к коммуникациям другого
                            Пользователя,  Оператором Сайта не возмещаются.
                        </li>

                        <li>Оператор Сайта не несет ответственности за:
                            <ol>
                                <li>Задержки или сбои в процессе совершения операции, возникшие
                                    вследствие непреодолимой силы, а также любого случая неполадок
                                    в телекоммуникационных, компьютерных, электрических и иных
                                    смежных системах.</li>

                                <li>Действия систем переводов, банков, платежных систем и за
                                    задержки, связанные с их работой.</li>

                                <li>Ненадлежащее функционирование Сайта в случае, если Пользователь
                                    не имеет необходимых технических средств для его использования,
                                    а также не несет никаких обязательств по обеспечению
                                    пользователей такими средствами.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P6Responsibility;