import React, {Component} from 'react';
import Image from "../common/Image";

class Base extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Базовая основа</h4>

                <p>
                    Базовая основа представляет собой деталь из ABS пластика с отверстиями для
                    крепления модулей. Внешний вид базовой основы приведен на рисунке 2.4. Некоторые
                    из отверстий имеют латунные резьбовые втулки (3х4 мм) для фиксации деталей Lego
                    Technic, а некоторые – встроенные магниты для фиксации модулей.
                </p>

                <Image src={"/Constructor/base.png"} name={"Внешний вид базовой основы"} number={"2.4"}/>
            </div>
        )
    }
}

export default Base