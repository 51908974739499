import React, {Component} from 'react';
import {SYMBOL} from "../../../../../../../constants";
import List from "./List";
import Delivery from "./Delivery";

class OrderItems extends Component {
    render() {
        let { order } = this.props;
        let items = order.orderItems;
        const outSum = order.cost;

        return (
            <div className="col-lg-6">
                <div className="product-order">
                    <h3>детали заказа</h3>

                    <List items={items}/>
                    <Delivery order={order}/>

                    <div className="final-total">
                        <h3>итого <span>{parseInt(outSum)} {SYMBOL}</span></h3>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderItems