import React, {Component} from 'react';

class PageContent extends Component {
    render() {
        return (
            <section className={"page-content"}>
                {
                    this.props.blocks
                        ? this.props.blocks.map(block =>
                            block.name && block.body
                                ? <div className={"block"}
                                       key={block.id}
                                       id={`${this.props.linkAnchor}${block.id}`}>

                                    {
                                        this.props.withNumeration
                                            ? <div className={"numeration"}>
                                                <div className={"number"}>0{block.id}</div>
                                                <div className={"decoration"}/>
                                            </div>
                                            : null
                                    }

                                    <div className={"content"}>
                                        <div className={block.image ? "with-image" : ""}>
                                            <div className={"name"}>{block.name}</div>
                                            <div className={"body"}>{block.body}</div>
                                        </div>

                                        {
                                            block.image
                                                ? <div>
                                                    <img alt={""}
                                                         src={block.image}
                                                         className={"img-fluid"}/>
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                                : null
                        )
                        : this.props.children
                }
            </section>
        )
    }
}

export default PageContent