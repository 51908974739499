import React, {Component} from 'react';
import parse from 'html-react-parser';

class SearchResult extends Component {
    constructor(props) {
        super(props);

        this.getContent();
    }

    componentDidMount() {
        this.getContent();
    }

    render() {
        let { item } = this.props;

        let regExp = new RegExp(this.props.term, 'gi');
        let name = item.name.replace(regExp, (match) => `<span class="highlight">${ match }</span>`);

        return (
            <div className={"section result"}>
                <h4>{this.props.number}. { parse(name) }</h4>

                { parse( this.getContent() ) }

                <div className={"link"}>
                    <a href={item.link}>
                        <div className={"button"}>Перейти</div>
                    </a>
                </div>
            </div>
        )
    }

    getContent() {
        let result = '';
        let repl = (match) => `<span class="highlight">${match}</span>`;

        for (let child of this.props.item.childs) {
            let prevHighLights = Array.from(child.querySelectorAll('span.highlight'));
            prevHighLights.map(highLight => highLight.replaceWith(highLight.textContent));

            if (child.textContent.toLowerCase().includes(this.props.term.toLowerCase()) && child.nodeName !== 'H4') {
                let content = child.innerHTML;
                let pattern = new RegExp(`(${this.props.term})(?=(((?!>).)*<)|[^<>]*$)`, 'gi');
                child.innerHTML = content.replace(pattern, repl('$1'));

                result = child.outerHTML;
                break;
            }
        }

        return result || this.props.item.childs[1].outerHTML
    }
}

export default SearchResult