import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Camera extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль видеокамеры</h4>

                <p>
                    Модуль видеокамеры представляет собой видеокамеру Raspberry Pi Camera v2,
                    установленную в корпус с сервомотором. Внешний вид модуля видеокамеры приведен
                    на рисунке 2.22.<br/>
                    В видеокамере используется датчик изображения Sony IMX219, который имеет собственное
                    разрешение 8 МП, а также имеет встроенный объектив с фиксированным фокусом. Видеокамера
                    способна создавать статические изображения с разрешением 3280 x 2464 пикселей, а также
                    поддерживает видео 1080p30, 720p60 и 640x480p90.<br/>
                    Модуль видеокамеры подключается к микрокомпьютеру Raspberry Pi с помощью короткого
                    ленточного кабеля.
                </p>

                <Image src={"/Constructor/camera.png"} name={"Внешний вид модуля видеокамеры, установленной на модуль сервопривода"} number={"2.22"}/>

                <p>
                    Основные технические характеристики модуля видеокамеры приведены в таблице 2.20.
                </p>

                <Table name={"Основные технические характеристики модуля видеокамеры"}
                       number={"2.20"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Модель", "Raspberry Pi Camera v2"],
                           ["Разрешение", "8 МП"],
                           ["Разрешение видео", "1080p30, 720p60 и 640x480p90"],
                           ["Частота кадров", "30fps"],
                       ]}/>
            </div>
        )
    }
}

export default Camera