import React, {Component} from 'react';

class EmptyCart extends Component {
    render() {
        return (
            <section className="cart-section section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 empty-cart-cls text-center">
                            <img src={`/assets/images/SalePages/Cart/icon-empty-cart.png`}
                                 className="img-fluid mb-4"
                                 alt="" />
                            <h3><strong>Корзина пуста</strong></h3>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default EmptyCart