import React from "react";

export let header = [
    {
        id: 1,
        order: 1,
        className: "empty d-none d-lg-block"
    },
    {
        id: 2,
        name: <>Надежная защита<br/>и хранение персональных<br/>данных</>,
        order: 2,
        className: "safety"
    },
    {
        id: 3,
        name: <>Гарантия неразглашения<br/>личной<br/>информации</>,
        order: 3,
        className: "privacy"
    }
];