import React from "react";

export const linkAnchor = "guarantee-condition";

export let conditions = [
    {
        id: 1,
        name: "гарантийный срок",
        body: "«Гарантийный срок», указанный ниже, начинается с момента получения товара с указанием даты " +
            "получения. Пожалуйста, сохраняйте квитанцию о получении товара. При отсутствии " +
            "действительной квитанции гарантия начинается с даты изготовления.",
        order: 1
    },
    {
        id: 2,
        name: "срок возврата",
        body: "При возникновении гарантийного случая, обратитесь за помощью в службу технической " +
            "поддержки ОМЕГАБОТ, предварительно ознакомившись с разделом сайта «Техническая поддержка». " +
            "Потребитель имеет право на возврат товара надлежащего качества в течение 14 дней, не считая " +
            "дня его покупки.",
        order: 3
    },
    {
        id: 3,
        name: "условия возврата",
        body: "Возврат товара надлежащего качества проводится, если указанный товар не был в употреблении, " +
            "сохранены его товарный вид, потребительские свойства, фабричные ярлыки, а также имеется " +
            "товарный чек или кассовый чек либо иной подтверждающий оплату указанного товара документ.",
        order: 5
    },
    {
        id: 4,
        name: "условия бесплатного ремонта",
        body: "В течение 14 дней после получения товара, если обнаружены какие-либо заводские повреждения, " +
            "отсутствующая деталь или неисправность, покупатель может выбрать замену или ремонт товара, " +
            "но только после того, как неисправность будет диагностирована нашими техническими " +
            "специалистами. " +
            "В течение гарантийного периода, если вы обнаружите какие-либо проблемы с товаром, на " +
            "которые распространяется гарантия, мы предоставит бесплатный сервис по ремонту или замене " +
            "неисправных компонентов после того, как проблемы будут диагностированы " +
            "На отремонтированный товар или его часть предоставляется гарантия в течение 3 месяца с даты " +
            "отгрузки.",
        order: 2
    },
    {
        id: 5,
        name: "в гарантию не входит",
        body: <ul className={"indent-list"}>
            <li>Продукт и детали, срок гарантии которых истёк;</li>
            <li>Неисправность или повреждение, вызванные неправильным или ненадлежащим
                использованием, обслуживанием или хранением, такие как: неправильное обращение;
                использовать в целях, не предусмотренных условиями эксплуатации; падение; контакт или
                воздействие неподходящей температуры, растворителя, кислоты, щелочи, погружения в воду и
                других сред; разрушение и растрескивание, коррозия и повреждение продуктов или деталей
                (таких как корпус, компоненты, цепи и т. д.) из-за насекомых, укусов крыс или любых посторонних
                материалов;</li>
            <li>Повреждение в результате установки, ремонта, изменения или модификации кем-либо, не
                относящимся к техническим специалистам нашей компании.</li>
            <li>Продукт или части, чья исходная идентификационная информация была изменена,
                повреждена или удалена;</li>
            <li>Неисправность или повреждение в результате форс-мажорных обстоятельств или
                несчастных случаев.</li>
        </ul>,
        order: 4
    },
    {
        id: 6,
        className: "empty",
        order: 12
    }
];