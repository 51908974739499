import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P10Advanced extends Component {
    render() {
        return (
            <AccordionItem title={<li>Дополнительные условия</li>}>
                <div>

                    <ol>
                        <li>Оператор вправе вносить изменения в настоящую Политику конфиденциальности без согласия
                            Пользователя.</li>

                        <li>Настоящая Политика вступает в силу с момента ее опубликования на
                            Сайте: <a href={"/privacy-policy"}>https://omegabot.ru/privacy-policy</a> и распространяется
                            на персональные данные субъекта персональных данных, полученные как до, так и после вступления
                            в силу настоящей Политики конфиденциальности.</li>

                        <li>Изменения Политики конфиденциальности вступают в силу с момента их размещения на Сайте.</li>

                        <li>Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать ООО
                        «Проф-ИТ» через форму обратной связи на Сайте по адресу <a href={"/"}>https://omegabot.ru</a></li>

                        <li>Действующая Политика конфиденциальности размещена на Сайте по
                            адресу <a href={"/privacy-policy"}>https://omegabot.ru/privacy-policy</a></li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P10Advanced;