import React, {Component} from 'react';

class Table extends Component {
    render() {
        return (
            <div className={`table ${this.props.className || ''}`}>
                { this.props.name ? <p className={"name"}>{ this.props.name }</p> : null}

                { this.props.number ? <p>Таблица { this.props.number }</p> : null}

                {
                    this.props.children
                        ? <div className={"sub"}>{ this.props.children }</div>
                        : <table>
                            <thead>
                            <tr>
                                {this.props.header.map((cell, i) =>
                                    <th key={i}>{ cell }</th>
                                )}
                            </tr>
                            </thead>
                        <tbody>
                        { this.props.rows.map((row, j) =>
                            <tr key={j}>
                                { row.map((cell, j) => <td key={j}>{ cell }</td>) }
                            </tr>
                        ) }
                        </tbody>
                        </table>
                }
            </div>
        )
    }
}

export default Table