import * as types from '../constants/ActionTypes'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

/* Cart */
export const addToCart = (product, qty) => (dispatch) => {
    toast.success(product.name + " добавлен в корзину");
    dispatch(addToCartUnsafe(product, qty))

}
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
    toast.success(product.name + " добавлен в корзину");
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
}
export const addToCartUnsafe = (product, qty) => ({
    type: types.ADD_TO_CART,
    product,
    qty
});
export const removeFromCart = product_id => (dispatch) => {
    toast.error("Товар удален из корзины");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product_id
    })
};
export const incrementQty = (product, qty) => (dispatch) => {
    // toast.success("Количество товара увеличено");
    dispatch(addToCartUnsafe(product, qty))

}
export const decrementQty = productId => (dispatch) => {
    // toast.error("Количество товара уменьшено");

    dispatch({
        type: types.DECREMENT_QTY,
        productId
    })
};
export const changeQty = (productId, count) => (dispatch) => {
    dispatch({
        type: types.CHANGE_QTY,
        productId,
        count
    })
};

/* Wishlist */
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => (dispatch) => {
    toast.error("Item Removed from Wishlist");
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id
    })
};

/* Compare */
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe = (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


/* Documents */
export const updateDocsSearchContent = (value) => ({
    type: types.UPDATE_DOCS_SEARCH_CONTENT,
    value: value
});

export const updateDocsFoundContent = (value) => ({
    type: types.UPDATE_DOCS_FOUND_CONTENT,
    value: value
});

