import React, {Component} from "react";
import Solutions from "./Solutions";

class Offer extends Component {
    render() {
        return (
            <section className={"offer container section-b-space"}>
                <p className={"description"}>
                    ОмегаБот - робототехническая платформа с программируемыми модулями. Рекомендована к
                    использованию в образовательном процессе, а также для участия в профильных соревнованиях.
                    Робототехническая платформа помогает пользователю познакомится с основами мехатроники и
                    робототехники через изучение программирования, электронных схем, программируемых контроллеров
                    и решения изобретательских задач.
                </p>
                <h4 className={"header"}>
                    Мы предоставляем готовое решение для занятий по робототехнике
                </h4>

                <Solutions/>
            </section>
        );
    }
}

export default Offer;