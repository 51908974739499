// Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const DECREMENT_QTY = 'DECREMENT_QTY'
export const CHANGE_QTY = 'CHANGE_QTY'

// WishList
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST'
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST'

// Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE'
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE'

// Docs
export const UPDATE_DOCS_SEARCH_CONTENT = 'UPDATE_DOCS_SEARCH_CONTENT';
export const UPDATE_DOCS_FOUND_CONTENT = 'UPDATE_DOCS_FOUND_CONTENT';
