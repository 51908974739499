import React, {Component} from 'react';
import Slider from "react-slick";
import {SERVER_HOST} from "../../../../constants/axiosURLs";

class Images extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        const { detail } = this.props;
        const pictures = detail.pictures && detail.pictures.length
            ? detail.pictures
            : [1,2,3];

        return (
            <div className="col-md-6">
                <Slider dots={false}
                        arrows={true}
                        fade={true}
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        className="product-slick">
                    {
                        pictures.map((item, i) =>
                            <div className="item" key={item.id || i}>
                                <div className="img-wrapper">
                                    <div className="lable-block">
                                        { detail.new ? <span className="lable3">new</span> : null }
                                        { detail.discount ? <span className="lable4">on sale</span> : null }
                                    </div>
                                    <div className="front">
                                        <img className="img-fluid"
                                             src={SERVER_HOST + (item.url || '/assets/images/common/image-stub.jpg')}
                                             alt=""/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Slider>

                <div className="row">
                    <div className="col-12 p-0">
                        <Slider slidesToShow={3}
                                swipeToSlide={true}
                                arrows={false}
                                dots={false}
                                focusOnSelect={true}
                                asNavFor={this.state.nav1}
                                ref={slider => (this.slider2 = slider)}
                                className="slider-nav">
                            {
                                pictures.map((pic, index) =>
                                    <div key={index}>
                                        <img src={ SERVER_HOST + (pic.url || '/assets/images/common/image-stub.jpg') }
                                             key={index} alt=""
                                             className="img-fluid" />
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        )
    }
}

export default Images