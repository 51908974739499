import React, {Component} from 'react';

class SolutionBlock extends Component {
    render() {
        return (
            <div className="col-md-4 col-sm-8 col-11">
                <div className="collection-banner p-left">
                    <div className="img-part">
                        <img src={`/assets/images/MainPage/offer/${this.props.image}`}
                             className="img-fluid"
                             alt={this.props.name}/>
                    </div>
                    <div className="contain-banner banner-3">
                        <h2>{this.props.name}</h2>
                    </div>
                </div>
            </div>
        )
    }
}

export default SolutionBlock