import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {SYMBOL} from "../../../../../constants";
import {connect} from 'react-redux'

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemStock: ''
        }
    }

    componentDidMount() {
        let quantity = this.props.productFromCart ? this.props.productFromCart.qty : 0
        this.setState({itemStock: this.props.product.stock - quantity})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.productFromCart !== this.props.productFromCart) {
            if(!this.props.productFromCart) {
                this.setState({itemStock: this.props.product.stock})
            }
        }
    }

    addToCart(propsFunc) {
        this.setState({itemStock: this.state.itemStock - 1})
        propsFunc()
    }

    render() {
        const {product} = this.props;
        const {itemStock} = this.state
        return (
            <div className="product-detail">
                <div>
                    <button className={itemStock ? 'btn btn-solid' : 'btn btn-solid btn-disabled'}
                            disabled={!itemStock}
                            onClick={() => this.addToCart(this.props.addToCart)}>
                        в корзину
                    </button>
                    <Link to={`/product/${product.id}`}>
                        <h6 className={"name"}>{product.name}</h6>
                    </Link>
                    <h4>
                        {
                            product.discount
                                ? <div>
                                    { Math.round(product.price * (100 - product.discount) / 100) } {SYMBOL} <del>
                                    {product.price} {SYMBOL}</del>
                                </div>
                                : <div>{product.price} {SYMBOL}</div>
                        }
                    </h4>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let productIndex = state.cartList.cart.findIndex(product => product.id === ownProps.product.id);
    let productFromCart = ~productIndex ? state.cartList.cart[productIndex] : undefined;
    return { productFromCart };
}

export default connect(mapStateToProps)(Details);