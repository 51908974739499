import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import { connect } from 'react-redux'
import FilledCart from "./FilledCart";
import {ToastContainer} from "react-toastify";
import EmptyCart from "./EmptyCart";

class Cart extends Component {
    render (){
        const {cartItems} = this.props;

        return (
            <>
                <Helmet><title>Корзина</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className={"section-b-space container cart cart-section"}>
                        <div className={"row"}>
                            <div className="col">
                                { cartItems.length > 0 ? <FilledCart/> : <EmptyCart/> }
                            </div>
                        </div>
                    </section>
                </div>
                <ToastContainer/>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return ({
        cartItems: state.cartList.cart,
    })
}

export default connect(mapStateToProps) (Cart)