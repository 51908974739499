import React, {Component} from 'react';
import ItemsList from "./ItemsList";
import Total from "./Total";
import CheckoutButton from "./CheckoutButton";

class FilledCart extends Component {
    render() {
        return (
            <>
                <ItemsList/>
                <Total/>
                <CheckoutButton/>
            </>
        )
    }
}

export default FilledCart