import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P3Subject extends Component {
    render() {
        return (
            <AccordionItem title={<li>Предмет политики конфиденциальности</li>}>
                <div>
                    <ol>
                        <li>Настоящая Политика конфиденциальности устанавливает обязательства Оператора по неразглашению
                            и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь
                            предоставляет о себе самостоятельно при регистрации (заполнении регистрационной формы) на
                            Сайте или в процессе использования Сервисов, включая персональные данные Пользователя.</li>

                        <li>В рамках настоящей Политики конфиденциальности под персональной информацией Пользователя
                            понимаются:
                            <ol>
                                <li>Персональная информация, которую Пользователь предоставляет о себе самостоятельно
                                    при регистрации или в процессе использования Сервисов, включая персональные данные
                                    Пользователя, указанные в п.3.3 настоящей Политики.</li>

                                <li>Данные, которые автоматически передаются сервисам Сайта в процессе их использования
                                    с помощью установленного на устройстве Пользователя программного обеспечения, в том
                                    числе IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной
                                    программе, с помощью которой осуществляется доступ к сервисам), технические характеристики
                                    оборудования и программного обеспечения, используемых Пользователем, дата и время доступа
                                    к сервисам, адреса запрашиваемых страниц и иная подобная информация.</li>

                                <li>Иная информация о Пользователе, обработка которой предусмотрена Соглашением об
                                    использовании Сайта.</li>
                            </ol>
                        </li>

                        <li>Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
                            предоставляются Пользователем путём заполнения регистрационной формы на Сайте по
                            адресу <a href="/">https://omegabot.ru</a> и включают в себя следующую информацию:
                            <ol>
                                <li>фамилию, имя, отчество Пользователя;</li>
                                <li>контактный телефон Пользователя;</li>
                                <li>адрес электронной почты (e-mail);</li>
                                <li>адрес доставки.</li>
                            </ol>
                        </li>

                        <li>Отключение Cookies может повлечь невозможность доступа к Сайту.</li>

                        <li>Сайт осуществляет сбор IP-адресов Пользователей. Данная информация используется для
                            обеспечения технической работы Сайта.</li>

                        <li>Любая иная персональная информация, не оговоренная выше (история заказов, используемые
                            Пользователем устройства для просмотра Сайта и т.д.) подлежит надежному хранению и
                            нераспространению, за исключением случаев, предусмотренных в п. 5.2. и п. 5.3. настоящей
                            Политики конфиденциальности.</li>

                        <li>Обработка специальных категорий персональных данных, касающихся расовой, национальной
                            принадлежности, политических взглядов, религиозных или философских убеждений, интимной
                            жизни, Оператором не осуществляется.</li>

                        <li>Обработка персональных данных, предоставленных субъектом персональных данных, осуществляется
                            Оператором следующими способами:
                            <ol>
                                <li>Неавтоматизированная обработка персональных данных;</li>
                                <li>Обработка персональных данных с использованием автоматизации;</li>
                                <li>Смешанная обработка персональных данных.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P3Subject;