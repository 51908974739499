import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Buzzer extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль пьезоизлучателя</h4>

                <p>
                    Модуль представляет собой печатную плату с установленным пьезоизлучателем.
                    Внешний вид модуля пьезоизлучателя приведен на рисунке 2.10.
                </p>

                <p>
                    Области применения модуля – воспроизведение звуковых сигналов.
                </p>

                <p>
                    При наличии управляющего сигнала модуль пьезоизлучателя воспроизводит звуковой
                    сигнал.
                </p>

                <Image src={"/Constructor/buzzer.png"} name={"Внешний вид модуля пьезоизлучателя"} number={"2.10"}/>

                <p>
                    Основные технические характеристики модуля пьезоизлучателя приведены в таблице 2.9.
                </p>

                <Table name={"Основные технические характеристики модуля пьезоизлучателя"}
                       number={"2.9"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Тип", "пьезоэлектрический излучатель"],
                           ["Характерные особенности", "встроенный генератор"],
                           ["Резонансная частота", "3100 ± 500 Гц"],
                           ["Средний рабочий ток", "30 мА"],
                           ["Номинальное напряжение", "5 В"],
                           ["Рабочее напряжение", "от 4 до 8 В"],
                           ["Уровень звука", "85 дБ / 10 см"],
                           ["Рабочая температура", "от -20 до 70°C"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Частота звука", "4 ± 0,5 кГц"],
                           ["Уровень звукового давления", "85 дБ"],
                           ["Напряжение электропитания", "5 В"],
                           ["Средний ток потребления", "12 мА"],
                       ]}/>
            </div>
        )
    }
}

export default Buzzer