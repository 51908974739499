import React, {Component} from "react";
import Slider from 'react-slick';

class Screen extends Component {
    render() {
        return (
            <section className="p-0 small-slider">
                <Slider className="home-slider">
                    <div className={"slider-back"}>
                        <div className="home">
                            <div className="container">
                                <div className="slider-contain">
                                    <div>
                                        <h1>ОМЕГАБОТ</h1>
                                        <h4>Робототехническая платформа<br/>
                                        с программируемыми модулями</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        );
    }
}

export default Screen;