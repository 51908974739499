import code1 from "./code/1.json";
import code2 from "./code/2.json";
import code3 from "./code/3.json";
import code4 from "./code/4.json";
import code5 from "./code/5.json";

export const advancedExercises = [
    {
        id: 1,
        name: 'Движение к источнику света',
        annotation: {
            goal: [
                'Изучить принцип работы модуля датчика света',
                'Закрепить знания по работе моторов'
            ],
            task: 'Омегабот должен при нажатии на кнопку на плате расширения начать движение вперед или назад в зависимости от того, где расположен источник света. Робот должен максимально приблизиться к источнику света. После чего робот должен остановиться.',
            functions: [
                'Чтение с кнопки на плате расширения',
                'Инициализация моторов',
                'Управление моторами',
                'Чтение данных с датчика света',
            ],
            skills: [
                'Навыки работы с роботом и средой программирования',
                'Составление алгоритмов',
                'Работа с модулем датчика света',
                'Управление моторами робота',
            ]
        },
        complectation: {
            image: "/advanced/complectation/1.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль датчика света – 2 шт.',
                'Соединительный провод 3 pin 160 мм – 2 шт',
                'Кабель для программирования контроллера',
                'Аккумуляторы',
            ]
        },
        code: {
            blocks: "/advanced/blocks/1.png",
            text: code1
        }
    },
    {
        id: 2,
        name: 'Управление освещением',
        annotation: {
            goal: [
                'Изучить принцип работы модуля потенциометра',
                'Закрепить изучение принципа работы модуля светодиода',
            ],
            task: 'Омегабот должен при повороте ручки потенциометра увеличивать или уменьшать яркость освещения светодиода на модуле светодиода.',
            functions: [
                'Чтение данных с модуля потенциометра',
                'Чтение с датчика модуля светодиода',
                'Управление модулем светодиода',
            ],
            skills: [
                'Навыки работы с роботом и средой программирования',
                'Составление алгоритмов',
                'Работа с модулем светодиода',
                'Работа с модулем потенциометра',
            ]
        },
        complectation: {
            image: "/advanced/complectation/2.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль светодиода',
                'Модуль потенциометра',
                'Соединительный провод 3 pin 160 мм – 2 шт.',
                'Кабель для программирования контроллера',
                'Аккумуляторы',
            ]
        },
        code: {
            blocks: "/advanced/blocks/2.png",
            text: code2
        }
    },
    {
        id: 3,
        name: 'Работа со схватом',
        annotation: {
            goal: [
                'Изучить принцип работы схвата',
                'Закрепить знания по управлению моторами',
            ],
            task: 'Омегабот должен при нажатии на кнопку на плате расширения ехать вперед 2 секунды, остановится на 1 секунду, схватить банку, используя модуль схвата, и поднять ее вверх. После чего робот должен остановиться.',
            functions: [
                'Чтение с кнопки платы расширения',
                'Инициализация моторов',
                'Инициализация модуля схвата',
            ],
            skills: [
                'Навыки работы с роботом и средой программирования',
                'Составление алгоритмов',
                'Работа с модулем схвата',
            ]
        },
        complectation: {
            image: "/advanced/complectation/3.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль схвата',
                'Кабель для программирования контроллера',
                'Аккумуляторы',
            ]
        },
        code: {
            blocks: "/advanced/blocks/3.png",
            text: code3
        }
    },
    {
        id: 4,
        name: 'Проезд по квадратной траектории со стороной 50 см',
        annotation: {
            goal: [
                'Изучить принцип работы моторов с подключенными энкодерами',
                'Закрепить знания по работе моторов',
            ],
            task: 'Омегабот должен при нажатии на кнопку на плате расширения начать движение вперед на 50 сантиметров, затем повернуться на 90 градусов вправо и продолжить движение вперед на 50 сантиметров. Робот будет продолжать движение, пока не повторит действие 4 раза (пока не проедет по траектории квадрата со стороной 50 сантиметров). Перерыв между всеми действиями полсекунды. После чего робот должен остановиться.',
            functions: [
                'Чтение с кнопки платы расширения',
                'Инициализация работы моторов',
                'Управление моторами',
            ],
            skills: [
                'Навыки работы с роботом и средой программирования',
                'Составление алгоритмов',
                'Работа с энкодерами и моторами',
            ]
        },
        complectation: {
            image: "/advanced/complectation/4.jpg",
            items: [
                'Четырехколесная платформа Омегабот',
                'Расширенная основа с энкодерами',
                'Плата расширения контроллера',
                'Контроллер',
                'Соединительный провод для энкодеров 3 pin 110 мм – 2 шт.',
                'Аккумуляторы',
                'Кабель для программирования контроллера',
            ]
        },
        code: {
            blocks: "/advanced/blocks/4.png",
            text: code4
        }
    },
    {
        id: 5,
        name: 'Имитация работы спец техники',
        annotation: {
            goal: [
                'Закрепить знания по изучению принципа работы схвата',
                'Закрепить знания по работе с модулем светодиода,',
                'Закрепить знания по работе с модулем кнопки',
            ],
            task: 'Робот должен при нажатии на кнопку на модуле кнопки начать мигать двумямодулями светодиодов. При повороте ручки потенциометра робот должен сжимать или разжимать схват. Выполнять программу пока снова не будет нажата кнопка на модуле кнопки.',
            functions: [
                'Инициализация схвата',
                'Чтение с модуля кнопки',
                'Управление модулем светодиода',
                'Управление модулем потенциометра',
                'Управление схватом',
            ],
            skills: [
                'Навыки работы с роботом и средой программирования',
                'Работа со схватом',
                'Составление алгоритмов',
                'Практика работы с несколькими модулями в связке',
            ]
        },
        complectation: {
            image: "/advanced/complectation/5.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль потенциометра',
                'Модуль светодиода 2 шт.',
                'Модуль кнопки',
                'Схват',
                'Соединительный провод 3 pin 160 мм - 4 шт.',
                'Аккумуляторы',
                'Кабель для программирования контроллера',
            ]
        },
        code: {
            blocks: "/advanced/blocks/5.png",
            text: code5
        }
    }
]