import React, {Component} from 'react';
import 'react-tabs/style/react-tabs.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';

class TabsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0
        }
    }

    changeTab(i) {
        this.setState({activeTab: i});
    }

    render () {
        let { tabs } = this.props;

        return (
            <Tabs className="tab-content nav-material"
                  selectedIndex={this.state.activeTab}
                  onSelect={(i) => this.changeTab(i)}>

                <TabList className="nav nav-tabs nav-material">
                    {tabs.map((tab, i) =>
                        <Tab className="nav-item" key={i}>
                            <span className={"nav-link"}>{ tab.name }</span>
                            <div className="material-border"/>
                        </Tab>
                    )}
                </TabList>

                {
                    tabs.map((tab,i) =>
                        <TabPanel key={i}>
                            <div className={"obot-tab-content"}>
                                {tab.content}
                            </div>
                        </TabPanel>
                    )
                }

            </Tabs>
        )
    }
}

export default TabsDetails;