import React, {Component} from "react";

class Item extends Component {
    render() {
        return (
            <div className={'col col-md-4 col-sm-6 col-6'}>
                <div className={'place'}>
                    { this.props.place }
                </div>
                <div className={'restrictions'}>
                    { this.props.restrictions }
                </div>
                <div className={'time'}>
                    { this.props.time }
                </div>
            </div>
        );
    }
}

export default Item;