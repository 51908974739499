import React, {Component} from 'react';
import ExampleItem from "./ExampleItem";
import {baseExercises} from "../../../../constants/Pages/MenuPages/education/exercises/base/exercises";

class BaseExample extends Component {
    render() {
        return (
            <ExampleItem name={'СТАРТ — БАЗОВЫЙ'} exercises={baseExercises}/>
        )
    }
}

export default BaseExample