import React, {Component} from 'react'
import Dropzone from "react-dropzone";
import {getSize, declOfNum} from '../../../../assets/js/functions'
import cx from 'classnames'
import {v4 as uuid} from 'uuid'
import FilesList from "./FilesList";

class Drop extends Component {

    constructor(props) {
        super(props);
        this.onDrop = (dropFile) => {
            let filesArray = [...dropFile]
            filesArray.forEach(f => {
                let file = {f, id: uuid()}
                const type = file.f.name.split('.')
                const numType = type.length - 1

                if (type[numType] !== 'exe') {
                    this.setState({files: [...this.state.files, file], totalSize: this.state.totalSize + file.f.size})
                }
            })
        };
        this.state = {
            files: [],
            totalSize: 0
        };
        this.removeFile = this.removeFile.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filesDrop !== this.state.files) {
            this.props.getFiles(this.state.files, this.state.totalSize / 1024 / 1024)
        }
        if(prevProps.filesDrop === null) {
            this.setState({files: [], totalSize: 0})
            this.props.getFiles(this.state.files, this.state.totalSize)
        }
    }

    removeFile(file) {
        this.setState({
            files: this.state.files.filter(f => f.id !== file.id),
            totalSize: this.state.totalSize - file.f.size
        })
    }

    render() {
        const total = this.state.totalSize / 1024 / 1024
        const length = this.state.files.length
        const namesFile = ['файл', 'файла', 'файлов']
        const attachedFile = ['Прикреплен', 'Прикреплено', 'Прикреплено']

        return (
            <>
                <div className={'field-label'}>
                    Прикрепить файлы <p className={total < 5 ? 'field-text' : 'field-text upload-info_max'}>
                    (не более 5Мб)</p>
                </div>
                <div className={'upload'}>
                    {length !== 0
                        ? <FilesList files={this.state.files} removeFile={this.removeFile}/>
                        : ''
                    }
                    <Dropzone onDrop={this.onDrop}>
                        {({getRootProps, getInputProps, isDragActive}) => (
                            <>
                                <div {...getRootProps()}
                                     className={cx('drop-zone',
                                         this.state.files.length ? 'col-4 col-lg-4 col-xl-3 col-sm-4' : 'col-12',
                                         isDragActive ? 'drop-zone_active' : '')}>
                                    <input {...getInputProps()} />
                                    <img src={'/assets/images/FooterPages/Support/cloud.png'} alt="cloud"
                                         className={'cloud'}/>
                                    {length !== 0
                                        ? ''
                                        : <span className={'upload-text'}>Нажмите для загрузки<br/> или перетащите файл</span>
                                    }
                                </div>
                            </>
                        )}
                    </Dropzone>
                </div>
                {total < 5
                    ?
                    <p className={'upload-info'}>{declOfNum(length, attachedFile)} {length} {declOfNum(length, namesFile)} {getSize(this.state.totalSize)}</p>
                    : <p className={'upload-info upload-info_max'}>Превышен размер файлов</p>
                }
            </>
        );
    }
}

export default Drop