import React, {Component} from 'react';
import Annotation from "./Annotation";
import Complectation from "./Complectation";
import Code from "./Code";

class Exercise extends Component {
    render() {
        let { content } = this.props;
        return (
            <section className={"content"}>
                <Annotation data={content.annotation}/>

                <div className={"exercise"}>
                    <div className={"header"}>
                        <div className={"number"}>Упражнение { content.id }</div>
                        <div className={"name"}>{ content.name }</div>
                    </div>
                    <Complectation data={content.complectation}/>
                    <Code data={content.code}/>
                </div>
            </section>
        )
    }
}

export default Exercise