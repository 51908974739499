import React, {Component} from "react";
import Intro from "./Intro";
import Helmet from "react-helmet/es/Helmet";
import Text from "./Text";

class TermsNConditions extends Component {
    render() {
        return (
            <>
                <Helmet><title>Пользовательское соглашение</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className="container terms-n-conditions section-b-space">
                        <div className="row">
                            <div className="col">
                                <Intro/>
                                <Text/>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default TermsNConditions;