import React, {Component} from 'react';

import {
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment
} from "../../../../constants/Pages/MenuPages/products/serviseIcons"

class Service extends Component {
    render (){

        return (
            <div className="col-sm-3 service">
                <div className="collection-filter-block">
                    <div className="product-service">
                        <div className="media">
                            <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                            <div className="media-body">
                                <h4>Курьерская доставка</h4>
                                <p>В любую точку России</p>
                            </div>
                        </div>
                        <div className="media">
                            <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                            <div className="media-body">
                                <h4>Сервис</h4>
                                <p>Гарантия 1 год</p>
                            </div>
                        </div>
                        <div className="media">
                            <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                            <div className="media-body">
                                <h4>Собственное производство</h4>
                                <p>Вся продукция имеет сертификаты</p>
                            </div>
                        </div>
                        <div className="media border-0 m-0">
                            <div dangerouslySetInnerHTML={{ __html: svgpayment }} />
                            <div className="media-body">
                                <h4>Платежи</h4>
                                <p>Гибкая система оплаты</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Service;