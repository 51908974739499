import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Form from "./Form";

class Checkout extends Component {
    render() {
        return (
            <>
                <Helmet><title>Оформление заказа</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className="section-b-space container order-checkout">
                        <div className="row">
                            <div className="col">
                                <div className="checkout-page">
                                    <div className="checkout-form">

                                        <Form/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Checkout;