import React from "react";

export let details = categoryId => ([
    {
        id: 1,
        name: "оборудование",
        body: <>
            Техническая поддержка оборудования подразумевает поддержку по электронной почте и по
            телефону по основным вопросам гарантийного обслуживания, настройки, установки, сборки и
            подключения. Дополнительную информацию смотрите в руководстве по эксплуатации, входящим
            в комплект поставки продукта, а также на сайте
            в разделе <a href={`/products/${categoryId}`}>Продукты</a> и <a href={"/faq"}>Вопрос–ответ</a>.
        </>,
        image: "/assets/images/FooterPages/Support/recycle.png"
    },
    {
        id: 2,
        name: "программное обеспечение",
        body: <>
            Техническая поддержка программного обеспечения подразумевает поддержку по электронной
            почте и по телефону по вопросам установки, запуска или переустановки (без восстановления
            данных) при условии, что оборудование соответствует минимальным системным требованиям
            (см. раздел сайта  <a href={"/software"}>Загрузка ПО</a>) для рассматриваемых программ.
            Дополнительную информацию смотрите в справочных материалах, входящих в комплект поставки
            продукта, а также на сайте
            в разделе <a href={`/products/${categoryId}`}>Продукты</a>, <a href={"/software"}>Загрузка ПО</a> и <a href={"/faq"}>Вопрос–ответ</a>.
        </>
    },
    {
        id: 3,
        name: "письменный запрос",
        body: "Мы не сможем решить проблему, если мы не знаем, в чем конкретно она выражается, поэтому " +
            "письменное изложение вашей проблемы, поможет нам стать лучше, чтобы оправдывать и " +
            "превосходить ваши ожидания. " +
            "При возникновении проблем с оборудованием для удобства и быстроты вы можете " +
            "воспользоваться формой обратной связи на запрос технической поддержки или написать письмо " +
            "в свободной форме с изложением проблемы, по желанию, номером счета или накладной, по " +
            "которой приобретался товар, телефоном и контактным лицом."
    },
    {
        id: 4,
        name: "заявка считается выполненной",
        body: <ul className={"indent-list"}>
            Обращение в службу поддержки считается решенным, если клиенту предоставлена следующая
            информация:
            <li>сведения, помогающие решить проблему;</li>
            <li>информация, характеризующая проблему как решенную;</li>
            <li>уведомление о том, что проблема отнесена к разряду проблем с аппаратным
                обеспечением и клиент перенаправлен в отдел гарантии;</li>
            <li>информация о том, что проблема выявлена на уровне продукта стороннего
                производителя, не предусматривающего поддержку.</li>
        </ul>
    }
]);