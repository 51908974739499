import React, {Component} from 'react';
import {SYMBOL} from "../../../../../../constants";
import {connect} from "react-redux";
import {removeFromCart} from "../../../../../../actions";

class MobileQuantity extends Component {
    render() {
        let { item } = this.props;

        return (
            <div className="mobile-cart-content row">
                <div className="col-xs-3">
                    <div className="qty-box">
                        <div className="input-group">
                            <input type="text" name="quantity"
                                   className="form-control input-number"
                                   value={item.qty}
                                   onChange={this.props.onChange} />
                        </div>
                    </div>
                </div>

                <div className="col-xs-3">
                    <h2 className="td-color">
                        {item.price - (item.price * item.discount / 100)} {SYMBOL}
                    </h2>
                </div>

                <div className="col-xs-3">
                    <h2 className="td-color">
                        <span className="icon"
                              onClick={() => this.props.removeFromCart(item)}>
                            <i className="icon-close"/>
                        </span>
                    </h2>
                </div>
            </div>
        )
    }
}

export default connect(() => ({}), {removeFromCart}) (MobileQuantity)