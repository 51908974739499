import React, {Component} from 'react';
import { Icon } from '@iconify/react';
import bxsShareAlt from '@iconify/icons-bx/bxs-share-alt';
import {
    FacebookShareButton, FacebookIcon,
    TelegramShareButton, TelegramIcon,
    VKShareButton, VKIcon,
    WhatsappShareButton, WhatsappIcon
} from "react-share";

class SharingBar extends Component {
    onShareButtonClick() {
        document.querySelector(".share-button").style.display = "none";
        let sharingBar = document.getElementById("social-media");
        sharingBar.style.width = "172px";
    }

    render() {
        let siteURL = window.location.host;
        let iconSettings = {
            size: 25,
            round: true,
            bgStyle: { fill: '#dfdfdf' }
        };

        return (
            <div className={"sharing-bar"}>
                <Icon icon={bxsShareAlt} color={"#dfdfdf"}
                      width="42" height="25"
                      className={"share-button"}
                      onClick={this.onShareButtonClick} />

                <div className={"social-media"} id={"social-media"}>
                    <FacebookShareButton url={siteURL + this.props.link}>
                        <FacebookIcon {...iconSettings} iconFillColor={'#3B5998'} />
                    </FacebookShareButton>
                    <TelegramShareButton url={siteURL + this.props.link}>
                        <TelegramIcon {...iconSettings} iconFillColor={'#37AEE2'} />
                    </TelegramShareButton>
                    <VKShareButton url={siteURL + this.props.link}>
                        <VKIcon {...iconSettings} iconFillColor={'#45668E'} />
                    </VKShareButton>
                    <WhatsappShareButton url={siteURL + this.props.link}>
                        <WhatsappIcon {...iconSettings} iconFillColor={'#40D979'} />
                    </WhatsappShareButton>
                </div>
            </div>
        )
    }
}

export default SharingBar