import React, {Component} from "react";
import 'react-light-accordion/demo/css/index.css';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Robot from "./Robot";
import Programming from "./Programming";
import Helmet from "react-helmet/es/Helmet";

class FAQ extends Component {
    render() {
        return (
            <>
                <Helmet><title>Вопрос-ответ</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className="section-b-space container faq">
                        <div className="row">
                            <div className={"col"}>
                                <h2 className={"text-center header"}>Вопрос-ответ</h2>

                                <section>
                                    <Tabs className="theme-tab"
                                          selectedTabClassName={"selected"}>
                                        <TabList className="tabs tab-title">
                                            <Tab><button className={"btn btn-solid"}>Взаимодействие с роботом</button></Tab>
                                            <Tab><button className={"btn btn-solid"}>Программирование</button></Tab>
                                        </TabList>

                                        <TabPanel><Robot/></TabPanel>
                                        <TabPanel><Programming/></TabPanel>
                                    </Tabs>
                                </section>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default FAQ;