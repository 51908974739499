import { combineReducers } from 'redux';
import { IntlReducer as Intl } from 'react-redux-multilingual'

// Import custom components
import cartReducer from './cart';
import wishlistReducer from './wishlist';
import compareReducer from './compare';
import docsReducer from "./docs";


const rootReducer = combineReducers({
    cartList: cartReducer,
    wishlist: wishlistReducer,
    compare: compareReducer,
    docsSearch: docsReducer,
    Intl
});

export default rootReducer;