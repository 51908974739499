import React, {Component} from 'react';

class Time extends Component {
    render() {
        return (
            <section className={"section-b-space time"}>
                <div className={"time-amount time-amount1"}>3<br/>месяца</div>
                <div className={"time-amount time-amount2"}>6<br/>месяцев</div>
                <div className={"time-amount time-amount3"}>12<br/>месяцев</div>

                <div className={"time-line pc"}>
                    <img src={"/assets/images/FooterPages/Guarantee/timeline/pc.png"} alt={""}/>
                </div>

                <div className={"time-line mob"}>
                    <img src={"/assets/images/FooterPages/Guarantee/timeline/mob.png"} alt={""}/>
                </div>

                <div className={"time-description time-description1"}>
                    <ul>
                        <li>Комплект соединительных проводов.</li>
                        <li>Кабель для программирования контроллера.</li>
                        <li>Шлейф.</li>
                    </ul>
                </div>
                <div className={"time-description time-description2"}>
                    <ul>
                        <li>Программируемый контроллер.</li>
                        <li>Плата расширения контроллера.</li>
                        <li>Моторы, сервомоторы.</li>
                        <li>Камера.</li>
                        <li>Плата Raspberry PI 3B+.</li>
                        <li>Набор модулей (датчиков).</li>
                        <li>Аккумуляторы.</li>
                    </ul>
                </div>
                <div className={"time-description time-description3"}>
                    <ul>
                        <li>Четырёхколесная платформа с основной базой для
                            установки модулей.</li>
                        <li>Гусеницы, колеса.</li>
                        <li>Блок питания для зарядки аккумуляторов.</li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default Time