import React from "react";

export const linkAnchor = "term-n-condition";

export let intro = [
    {
        id: 1,
        name: "условия заключения",
        body: <>
            Текст настоящего Пользовательского соглашения, постоянно размещенный в сети Интернет по
            адресу: <a href={"/"}>https://omegabot.ru</a>, содержит все существенные условия и является
            предложением Общества с ограниченной ответственностью «Проф-ИТ» (ИНН 7801553786, ОГРН 1117847331304),
            заключить соглашение с любым Пользователем, использующим сайт в сети Интернет по
            адресу: <a href={"/"}>https://omegabot.ru</a> (далее - Сайт) на указанных в тексте настоящего
            Пользовательского соглашения условиях. Текст настоящего Пользовательского соглашения является
            публичной офертой в соответствии с пунктом 2 статьи 437 Гражданского кодекса Российской Федерации.
        </>,
        order: 1
    },
    {
        id: 2,
        name: "публичная оферта",
        body: "Публичная оферта в соответствии со статьей 438 Гражданского кодекса Российской Федерации признается " +
            "акцептованной Пользователем Сайта с момента регистрации Пользователя на Сайте, оформления Пользователем " +
            "Заказа без регистрации и авторизации, с момента принятия от Пользователя заказа по телефону.",
        order: 2
    },
    {
        id: 3,
        name: "условия расторжения",
        body: "Если Пользователь Сайта не согласен с условиями настоящего Пользовательского соглашения, Пользователь " +
            "обязан воздержаться от использования сервисов Сайта и прекратить использование Сайта.",
        order: 3
    },
    {
        id: 4,
        className: "d-lg-none empty",
        order: 12
    }
];