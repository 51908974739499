import React, {Component} from 'react';
import {menu} from "../../../constants/Pages/FooterPages/docs";
import {connect} from "react-redux";
import SearchWrapper from "./SearchWrapper";
import SearchBar from 'omega-react-js-search';
import {updateDocsFoundContent} from "../../../actions";
import Items from "./Items";
import {Icon} from '@iconify/react';
import chevronDown from '@iconify-icons/mdi/chevron-down';


class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            section: '',
            isOpen: false
        }
    }

    openSection(id) {
        this.state.section === id
            ? this.setState({section: id, isOpen: !this.state.isOpen})
            : this.setState({section: id, isOpen: true})
    }

    render() {

        return (
            <SearchWrapper content={menu}>
                <div className={"menu"}>
                    {
                        this.props.docs.length
                            ? <SearchBar
                                caseInsensitive={true}
                                onSearchTextChange={(term, hits) => this.props.updateDocsFoundContent({term, hits})}
                                onSearchButtonClick={() => {
                                }}
                                placeHolderText={"Поиск..."}
                                data={this.props.docs}
                                keys={["text"]}
                            />
                            : null
                    }
                    {
                        menu.map(block =>
                            <div className={"section"}
                                 key={block.id}
                                 style={{background: block.backColor, color: block.textColor}}>
                                <div className={"name"} onClick={() => this.openSection(block.id)}>
                                    <Icon icon={chevronDown}
                                          color={block.chevronColor}
                                          className={`icon 
                                          ${this.state.section === block.id && this.state.isOpen
                                              ? 'icon_open' : ''}`}/>
                                    {block.name}
                                </div>
                                {this.props.width > 990
                                    ? <Items block={block}/>
                                    : ''
                                }
                                {this.props.width <= 990
                                    ? this.state.section === block.id && this.state.isOpen
                                        ? <Items block={block}/>
                                        : ''
                                    : ''
                                }

                            </div>
                        )
                    }
                </div>
            </SearchWrapper>
        )
    }
}

export default connect(state => state.docsSearch, {updateDocsFoundContent})(Menu);