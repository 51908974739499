import React, {Component} from 'react';

class SocialMediaItem extends Component {
    render() {
        return (
            <li>
                <a href={this.props.link} target={"_blank"}>
                    <i className={`fa fa-${this.props.icon}`} aria-hidden="true"></i>
                </a>
            </li>
        )
    }
}

export default SocialMediaItem