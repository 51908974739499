export const reviewMarks = [
    {
        id: 'spb',
        name: "Санкт-Петербург",
        reviews: [
            {
                name: "Санкт-Петербургский государственный экономический университет",
                link: `spbgeu.pdf`
            },
            {
                name: "Клуб робототехники «Азимов»",
                link: `spb-azimov.pdf`
            }
        ]
    },
    {
        id: 'tomsk',
        name: "Томск",
        reviews: [
            {
                name: "МБОУ «Кисловская средняя общеобразовательная школа»",
                link: `tomsk-kislovka.pdf`
            }
        ]
    },
    {
        id: 'kzn',
        name: "Казань",
        reviews: [
            {
                name: "Центр технологий компонентов робототехники и мехатроники",
                link: `innopolis.pdf`
            }
        ]
    },
]