import React, {Component} from "react";
import Helmet from "react-helmet/es/Helmet";
import ContactBlock from "./ContactBlock";
import AddressMap from "./AddressMap";
import Requisites from "./Requisites";
// import NewYearSchedule from "./NewYearSchedule";

class Contacts extends Component {
    render() {
        return (
            <>
                <Helmet><title>Контакты</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className={"container"}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <h2 className={"text-center header"}>Контакты</h2>

                                <section className={"contacts section-b-space"}>
                                    <ContactBlock/>
                                    <AddressMap/>
                                    <Requisites/>
                                </section>

                                {/*<NewYearSchedule/>*/}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default Contacts;