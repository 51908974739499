import React, {Component} from 'react';
import Item from "./Item";
import {connect} from "react-redux";

class ItemsList extends Component {
    render() {
        return (
            <table className="table cart-table table-responsive-xs items-list">
                <thead>
                <tr className="table-head">
                    <th scope="col">продукт</th>
                    <th scope="col">наименование</th>
                    <th scope="col">цена</th>
                    <th scope="col">количество</th>
                    <th scope="col">удалить</th>
                    <th scope="col">итого</th>
                </tr>
                </thead>

                { this.props.cartItems.map(item => <Item item={item} key={item.id}/>) }
            </table>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        cartItems: state.cartList.cart,
    })
}

export default connect(mapStateToProps) (ItemsList)