import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Grasp extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Схват</h4>

                <p>
                    Схвата представляет собой электромеханическое устройство, предназначенное для схвата
                    и перемещения предметов цилиндрической формы. Внешний вид схвата приведен на рисунке
                    2.16. Схват крепится и подключается к робототехнической платформе. Схват состоит из
                    двух сервомоторов, крепежа и пластиковых деталей.
                </p>

                <p>
                    Схват подключается к соединителям для модулей на плате расширения.
                </p>

                <p>
                    Работа схвата обеспечивается двумя сервоприводами, которые приводят в движение зажимной
                    и подъёмные механизмы схвата.
                </p>

                <Image src={"/Constructor/grasp.png"} name={"Внешний вид схвата"} number={"2.16"}/>

                <p>
                    Основные технические характеристики схвата приведены в таблице 2.15.
                </p>

                <Table name={"Основные технические характеристики схвата"}
                       number={"2.15"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Количество сервоприводов", "2"],
                           ["Материал шестерней", "металл"],
                           ["Усилие", "до 1,8 кг/см"],
                           ["Скорость", "0,1 с/60° (при напряжении 4,8 В)"],
                           ["Рабочее напряжение", "от 4,8 до 7.2 В"],
                           ["Рабочая температура", "от 0 до 55°С"],
                       ]}/>
            </div>
        )
    }
}

export default Grasp