import React, {Component} from "react";

class Requirements extends Component {
    render() {
        return (
            <section className={"section-b-space requirements"}>
                <div>
                <h3>Требования к системе</h3>
                <div className={"list"}>
                    <ul>
                        <li>
                            Операционная система:
                            <ul>
                                <li>Microsoft Windows XP и выше (32-bit или 64-bit).</li>
                            </ul>
                        </li>
                        <li>RAM: 2 ГБ.</li>
                        <li>Пространство на диске: 256 МБ</li>
                        <li>Процессор: минимальное требование - Pentium 2 266 МГц</li>
                    </ul>
                </div>
                </div>
                <div className={"preview col col-md-6 col-12"} >
                                <div className={"file-name"} onClick={() => window.open('/assets/files/reviews/Пользовательская_документация_OmegaBot_IDE.pdf')}>
                                    <img src={'/assets/images/common/fileExtension/pdf.png'}
                                         className={"file-icon img-fluid"}
                                         />
                                    <span title='Самоучитель'>{'Пользовательская документация.pdf'}</span>
                                </div>
                            </div>
            </section>
        );
    }
}

export default Requirements;