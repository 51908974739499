import React, {Component} from 'react';
import Pedestal from "./Pedestal";

class Competitions extends Component {
    render() {
        return (
            <section className={'section-b-space competitions'}>
                <h2 className={'text-center header'}>Участие в соревнованиях</h2>
                <div className={'container'}>
                    <img src={`/assets/images/MainPage/competitions/cup.png`}
                         alt={''}/>

                    <Pedestal/>
                </div>
            </section>
        )
    }
}

export default Competitions