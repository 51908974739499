import React from "react";
import Purpose from "../../../components/FooterPages/Docs/sections/General/Purpose";
import Features from "../../../components/FooterPages/Docs/sections/General/Features";
import Platform from "../../../components/FooterPages/Docs/sections/Constructor/Platform";
import Controller from "../../../components/FooterPages/Docs/sections/Constructor/Controller";
import Shield from "../../../components/FooterPages/Docs/sections/Constructor/Shield";
import Base from "../../../components/FooterPages/Docs/sections/Constructor/Base";
import BaseWithEncoders from "../../../components/FooterPages/Docs/sections/Constructor/BaseWithEncoders";
import Encoder from "../../../components/FooterPages/Docs/sections/Constructor/Encoder";
import BaseWithDistanceSensor from "../../../components/FooterPages/Docs/sections/Constructor/BaseWithDistanceSensor";
import LineSensor from "../../../components/FooterPages/Docs/sections/Constructor/LineSensor";
import TouchSensor from "../../../components/FooterPages/Docs/sections/Constructor/TouchSensor";
import Buzzer from "../../../components/FooterPages/Docs/sections/Constructor/Buzzer";
import LightSensor from "../../../components/FooterPages/Docs/sections/Constructor/LightSensor";
import Potentiometer from "../../../components/FooterPages/Docs/sections/Constructor/Potentiometer";
import MagnetSensor from "../../../components/FooterPages/Docs/sections/Constructor/MagnetSensor";
import TemperatureSensor from "../../../components/FooterPages/Docs/sections/Constructor/TemperatureSensor";
import LED from "../../../components/FooterPages/Docs/sections/Constructor/LED";
import Button from "../../../components/FooterPages/Docs/sections/Constructor/Button";
import ColorSensor from "../../../components/FooterPages/Docs/sections/Constructor/ColorSensor";
import Grasp from "../../../components/FooterPages/Docs/sections/Constructor/Grasp";
import DistanceSensorWithServo from "../../../components/FooterPages/Docs/sections/Constructor/DistanceSensorWithServo";
import Bluetooth from "../../../components/FooterPages/Docs/sections/Constructor/Bluetooth";
import Microcomputer from "../../../components/FooterPages/Docs/sections/Constructor/Microcomputer";
import Camera from "../../../components/FooterPages/Docs/sections/Constructor/Camera";
import Accumulator from "../../../components/FooterPages/Docs/sections/Constructor/Accumulator";
import PowerSupply from "../../../components/FooterPages/Docs/sections/Constructor/PowerSupply";
import Windows from "../../../components/FooterPages/Docs/sections/Software/Windows";
import Linux from "../../../components/FooterPages/Docs/sections/Software/Linux";
import ComputerVision from "../../../components/FooterPages/Docs/sections/Software/ComputerVision";
import Emergency from "../../../components/FooterPages/Docs/sections/Advanced/Emergency";
import Terms from "../../../components/FooterPages/Docs/sections/Advanced/Terms";

export let menu = [
    {
        id: 'general',
        name: 'Общие сведения',
        backColor: '#43b7c4',
        selectedColor: '#48a8b4',
        textColor: '#fff',
        markColor: '#fff',
        chevronColor: '#fff',
        items: [
            { id: 'purpose', name: 'Назначение', content: <Purpose/> },
            { id: 'features', name: 'Отличительные особенности предлагаемого решения', content: <Features/> },
        ],
    },
    {
        id: 'constructor',
        name: 'Устройство и работа составных частей Конструктора',
        backColor: '#dddddf',
        selectedColor: '#C9C9CB',
        textColor: '#333',
        markColor: '#43b7c4',
        chevronColor: '#2e2a2a',
        items: [
            { id: 'platform', name: 'Колесная платформа', content: <Platform/> },
            { id: 'controller', name: 'Контроллер', content: <Controller/> },
            { id: 'shield', name: 'Плата расширения', content: <Shield/> },
            { id: 'base', name: 'Базовая основа', content: <Base/> },
            { id: 'base-with-encoders', name: 'Расширенная основа с энкодерами', content: <BaseWithEncoders/> },
            { id: 'encoder', name: 'Модуль Энкодера', content: <Encoder/> },
            { id: 'base-with-distance-sensor', name: 'Расширенная основа с датчиками расстояния', content: <BaseWithDistanceSensor/> },
            { id: 'line-sensor', name: 'Модуль датчика линии', content: <LineSensor/> },
            { id: 'touch-sensor', name: 'Модуль датчика касания', content: <TouchSensor/> },
            { id: 'buzzer', name: 'Модуль пьезоизлучателя', content: <Buzzer/> },
            { id: 'light-sensor', name: 'Модуль датчика света', content: <LightSensor/> },
            { id: 'potentiometer', name: 'Модуль потенциометра', content: <Potentiometer/> },
            { id: 'magnet-sensor', name: 'Модуль магнитного датчика', content: <MagnetSensor/> },
            { id: 'temperature-sensor', name: 'Модуль датчика температуры', content: <TemperatureSensor/> },
            { id: 'led', name: 'Модуль светодиода', content: <LED/> },
            { id: 'button', name: 'Модуль кнопки', content: <Button/> },
            { id: 'color-sensor', name: 'Модуль датчика цвета', content: <ColorSensor/> },
            { id: 'grasp', name: 'Схват', content: <Grasp/> },
            { id: 'distance-sensor-with-servo', name: 'Модули УЗ дальномера и сервопривода', content: <DistanceSensorWithServo/> },
            { id: 'bluetooth', name: 'Модуль Bluetooth', content: <Bluetooth/> },
            { id: 'microcomputer', name: 'Микрокомпьютер', content: <Microcomputer/> },
            { id: 'camera', name: 'Модуль видеокамеры', content: <Camera/> },
            { id: 'accumulator', name: 'Аккумуляторный блок', content: <Accumulator/> },
            { id: 'power-supply', name: 'Блок питания', content: <PowerSupply/> },
        ]
    },
    {
        id: 'software',
        name: 'Загрузка и установка ПО',
        backColor: '#89898b',
        selectedColor: '#717172',
        textColor: '#fff',
        markColor: '#43b7c4',
        chevronColor: '#2e2a2a',
        items: [
            { id: 'windows', name: 'Инструкция по установке ПО OmegaBot IDE в ОС Windows', content: <Windows/> },
            { id: 'linux', name: 'Инструкция по установке ПО OmegaBot IDE в ОС Linux', content: <Linux/> },
            { id: 'computer-vision', name: 'Инструкция по работе с компьютерным зрением', content: <ComputerVision/> },
        ]
    },
    {
        id: 'advanced',
        name: 'Дополнительно',
        backColor: '#aaaaac',
        selectedColor: '#88A5AA',
        textColor: '#fff',
        markColor: '#fff',
        chevronColor: '#fff',
        items: [
            { id: 'emergency', name: 'Аварийные ситуации при использовании Конструктора', content: <Emergency/> },
            { id: 'terms', name: 'Перечень принятых сокращений и терминов', content: <Terms/> },
        ]
    }
];

