import {
    UPDATE_DOCS_FOUND_CONTENT,
    UPDATE_DOCS_SEARCH_CONTENT
} from "../constants/ActionTypes";


export default function docsReducer(state = {docs: [], found: {}}, action) {
    switch (action.type) {
        case UPDATE_DOCS_SEARCH_CONTENT:
            return {
                docs: action.value,
                found: state.found
            }

        case UPDATE_DOCS_FOUND_CONTENT:
            return {
                docs: state.docs,
                found: action.value
            }

        default:
    }
    return state;
}