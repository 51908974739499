import React, {Component} from 'react';
import Column from "./Column";
import {minProm} from "../../../../../constants/Pages/common/footer";

class Items extends Component {
    render() {
        return (
            <>
                <Column className={"offset-xl-1"}
                        name={"о компании"}
                        items={[
                            <a href={"/about"}>Наша миссия</a>,
                            <a href={"/delivery/"}>Доставка</a>,
                            <a href={"/privacy-policy"}>Политика конфиденциальности</a>,
                            <a href={"/terms-n-conditions"}>Пользовательское соглашение</a>
                        ]}/>

                <Column name={"поддержка"}
                        items={[
                            <a href="/support">Техническая поддержка</a>,
                            <a href="/warranty">Гарантийная политика</a>,
                            <a href="/faq">Вопрос-ответ</a>,
                            <a href="/docs">Руководство пользователя</a>
                        ]}/>

                <Column listClassName={"contact-list"}
                        name={"контакты"}
                        items={[
                            <><i className="fa fa-map-marker"></i>
                                Россия, г.Санкт-Петербург
                                Синопская набережная, 52</>,
                            <><i className="fa fa-phone"></i>
                                <a href="tel:+78124263110">+7 (812) 426-31-10</a></>,
                            <><i className="fa fa-envelope-o"></i>
                                <a href="mailto:info@omegabot.ru">info@omegabot.ru</a></>
                        ]}>
                    <div className={"partnership"}>
                        <div>Проект выполнен при поддержке</div>
                        <div className={"logo"}
                             dangerouslySetInnerHTML={{__html: minProm}}/>
                    </div>
                </Column>
            </>
        )
    }
}

export default Items