import React, {Component} from "react";
import {linkAnchor} from "../../../constants/Pages/FooterPages/termsNConditions";
import PageTitles from "../../common/Page/PageTitles";
import {intro} from "../../../constants/Pages/FooterPages/termsNConditions";
import PageContent from "../../common/Page/PageContent";

class Intro extends Component {
    render() {
        return (
            <>
                <h2 className={"text-center header"}>Пользовательское соглашение</h2>
                <PageTitles titles={intro}
                            linkAnchor={linkAnchor}/>
                <PageContent blocks={intro}
                             linkAnchor={linkAnchor}/>
            </>
        );
    }
}

export default Intro;