import React, {Component} from 'react';
import ItemsList from "./ItemsList";
import DeliveryDetails from "./DeliveryDetails";
import PaymentButton from "./PaymentButton";

class OrderBox extends Component {
    render() {
        const {values, validator} = this.props;
        
        return (
            <div className="col-lg-8 col-12 order-container">
                <div className="checkout-details">
                    <DeliveryDetails values={values}/>
                    <ItemsList values={values}/>
                    <PaymentButton values={values}
                                   validator={validator}/>
                </div>
            </div>
        )
    }
}

export default OrderBox