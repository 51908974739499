import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P9Advanced extends Component {
    render() {
        return (
            <AccordionItem title={<li>Дополнительные условия</li>}>
                <div>

                    <ol>
                        <li>Оператор Сайта не принимает предложения от Пользователя относительно
                            изменений настоящего Пользовательского соглашения.</li>
                        <li>Отзывы и вопросы Пользователя, размещенные на Сайте, не являются
                            конфиденциальной информацией и могут быть использованы Оператором
                            Сайта без ограничений.</li>
                        <li>По вопросам, неурегулированным настоящим Пользовательским соглашением,
                            Стороны руководствуются действующим законодательством РФ.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P9Advanced;