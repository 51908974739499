import React, {Component} from 'react';
import Logo from "../../Logo";
import Navbar from "./Navbar";
import Cart from "./Cart";

class Content extends Component {
    render() {
        return (
            <div className="main-menu">
                <div className="menu-left category-nav-right">
                    <div className="brand-logo">
                        <Logo logo={this.props.logo}/>
                    </div>
                </div>
                <div className="menu-right pull-right">
                    <Navbar/>
                    <Cart/>
                </div>
            </div>
        )
    }
}

export default Content