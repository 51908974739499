import React, {Component} from 'react';

class Examples extends Component {
    render() {
        return (
            <>
                            <div className={"preview col col-md-6 col-12"} >
                                <div className={"file-name"} onClick={() => window.open('/assets/files/reviews/Самоучитель-Базовый (10 уроков + 10 экспериментов + 40 вопросов для проработки).pdf')}>
                                    <img src={'/assets/images/common/fileExtension/pdf.png'}
                                         className={"file-icon img-fluid"}
                                         />
                                    <span title='Самоучитель'>{'Самоучитель - Базовый.pdf'}</span>
                                </div>
                            </div>

                <h2 className={"text-center"}>Примеры кода</h2>

                <section className={"examples section-b-space"}>
                    <div className={"row"}>
                        <div className={"col-lg-4 col-md-6"}>
                            <a href={"/education/examples/base"}>
                                <div className={"example-block"}>
                                    <div className={"header"}>
                                        <div className={"title"}>
                                            <div className={"name"}>Базовый</div>
                                            <p className={"language"}>Omegabot IDE и C++</p>
                                        </div>
                                        <img alt={"Пример кода"}
                                             src={"/assets/images/MenuPages/Education/examples/base.png"}
                                             className={"img-fluid"}/>
                                    </div>
                                    <div className={"description"}>
                                        5 упражнений (сценариев поведения) с Базовым набором Омегабот
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className={"col-lg-4 col-md-6"}>
                            <a href={"/education/examples/advanced"}>
                                <div className={"example-block"}>
                                    <div className={"header"}>
                                        <div className={"title"}>
                                            <div className={"name"}>Расширенный</div>
                                            <p className={"language"}>Omegabot IDE и C++</p>
                                        </div>
                                        <img alt={"Пример кода"}
                                             src={"/assets/images/MenuPages/Education/examples/advanced.png"}
                                             className={"img-fluid"}/>
                                    </div>
                                    <div className={"description"}>
                                        5 упражнений (сценариев поведения) с Расширенным
                                        набором Омегабот
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Examples