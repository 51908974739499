import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    DECREMENT_QTY, CHANGE_QTY
} from "../constants/ActionTypes";


export default function cartReducer(state = {cart: []}, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product.id
            if (state.cart.findIndex(product => product.id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === productId) {
                        cartAcc.push({ ...product, qty: product.qty+action.qty,
                            sum: (product.price - (product.price*product.discount/100))*(product.qty+action.qty) }) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state,
                cart: [...state.cart,
                    { ...action.product,
                        qty: action.qty,
                        sum: (action.product.price - (action.product.price*action.product.discount/100))*action.qty }] }

        case DECREMENT_QTY:
            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        cartAcc.push({ ...product,
                            qty: product.qty-1,
                            sum: (product.price - (product.price*product.discount/100))*(product.qty-1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state,
                cart: [...state.cart,
                    { ...action.product,
                        qty: action.qty,
                        sum: action.product.price*action.qty }] }

        case CHANGE_QTY:
            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId) {
                        cartAcc.push({ ...product,
                            qty: action.count,
                            sum: (product.price - (product.price*product.discount/100))*(action.count) }) // Change qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state,
                cart: [...state.cart,
                    { ...action.product,
                        qty: action.qty,
                        sum: action.product.price*action.qty }] }

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(item => item.id !== action.product_id.id)
            }

        default:
    }
    return state;
}