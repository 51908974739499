import React, {Component} from 'react';

class Block extends Component {
    render() {
        return (
            <div style={{ background: this.props.color }}
                 onClick={ this.props.onChange }>
                { this.props.name }

                <div className={"arrow"}
                     style={{
                         display: this.props.isSelected ? 'block' : 'none',
                         borderLeftColor: this.props.color }}> </div>
            </div>
        )
    }
}

export default Block