import React, {Component} from 'react';

class CheckoutButton extends Component {
    render() {
        return (
            <div className="row cart-buttons">
                <div className="col-12">
                    <a href={`/checkout`} className="btn btn-solid">оформить заказ</a>
                </div>
            </div>
        )
    }
}

export default CheckoutButton