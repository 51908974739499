import React, {Component} from 'react';

class Literature extends Component {
    render() {
        return (
            <>
                <h2 className={"text-center header"}>Список рекомендуемой литературы</h2>
                <section className={"literature"}>
                    <ul>
                        <li>«Грокаем алгоритмы» Адитья Бхаргава</li>
                        <li>«Алгоритмы. Учебное пособие» Горностаева Т. Н.</li>
                        <li>«25 крутых проектов с Arduino» Марк Геддес</li>
                        <li>«Проекты с использованием контроллера Arduino» Петин В. А.</li>
                        <li>«Сенсорные системы в робототехнике: учеб. Пособие» Е. И. Юревич</li>
                        <li>«Основы робототехники» Е. И. Юревич</li>
                        <li>«Основы схемотехники: учебное пособие» А.М. Водовозов</li>
                        <li>«Введение в цифровую схемотехнику» Ю.В. Новиков</li>
                        <li>«Язык программирования С++» Бьерн Страуструп</li>
                        <li>«Python для детей. Самоучитель по программированию» Джейсон Бриггс</li>
                        <li>«Изучаем программирование на Python» Пол Бэрри</li>
                    </ul>
                </section>
            </>
        )
    }
}

export default Literature