import React, {Component} from 'react';
import {Accordion} from "react-light-accordion";
import P1Definitions from "./Accordion/P1Definitions";
import P2GeneralProvisions from "./Accordion/P2GeneralProvisions";
import P3Subject from "./Accordion/P3Subject";
import P4Obligations from "./Accordion/P4Obligations";
import P5Usage from "./Accordion/P5Usage";
import P6Responsibility from "./Accordion/P6Responsibility";
import P7Violation from "./Accordion/P7Violation";
import P8Disputes from "./Accordion/P8Disputes";
import P9Advanced from "./Accordion/P9Advanced";
import PageContent from "../../../common/Page/PageContent";

class Text extends Component {
    render() {
        return (
            <PageContent>
                <h2 className={"text-center header text"}>Текст соглашения</h2>

                <section>
                    <div className={"document-container container-fluid accordion-container"}>
                        <ol>
                            <Accordion atomic={true}>
                                <P1Definitions/>
                                <P2GeneralProvisions/>
                                <P3Subject/>
                                <P4Obligations/>
                                <P5Usage/>
                                <P6Responsibility/>
                                <P7Violation/>
                                <P8Disputes/>
                                <P9Advanced/>
                            </Accordion>
                        </ol>
                    </div>
                </section>
            </PageContent>
        )
    }
}

export default Text