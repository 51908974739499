import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P2GeneralProvisions extends Component {
    render() {
        return (
            <AccordionItem title={<li>Общие положения</li>}>
                <div>

                    <ol>
                        <li>Настоящая Политика конфиденциальности обработки персональных данных (далее – Политика
                            конфиденциальности) действует в отношении всей информации, которую Сайт может получить
                            о Пользователе, во время использования последним Сайта и его сервисов.</li>

                        <li>Использование Пользователем сервисов Сайта означает безоговорочное согласие Пользователя
                            с настоящей Политикой конфиденциальности и указанными в ней условиями обработки персональных
                            данных Пользователя.</li>
                        <li>В случае несогласия с условиями Политики конфиденциальности Пользователь обязан
                            воздержаться от использования сервисов и прекратить использование Сайта.</li>

                        <li>Настоящая Политика конфиденциальности непосредственно применяется только к сервисам
                            Сайта и информации, размещенной на Сайте. Сайт не контролирует и не несет ответственность
                            за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на
                            Сайте.</li>

                        <li>Оператор не проверяет достоверность персональных данных, предоставляемых Пользователем
                            Сайта.</li>

                        <li>Политика конфиденциальности разработана в соответствии с Конституцией Российской Федерации,
                            Гражданским кодексом Российской Федерации, Федеральным законом от 27.07.2006 г. №152-ФЗ
                            «О персональных данных», Федеральным законом от 27 июля 2006 года № 149-ФЗ «Об информации,
                            информационных технологиях и о защите информации», Постановлением Правительства РФ от 01.11.2012
                            № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных
                            системах персональных данных» и иными нормативными правовыми актами Российской Федерации в области
                            охраны (защиты) персональных данных», Постановлением Правительства РФ от 15.09.2008 № 687 «Об
                            утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования
                            средств автоматизации», Приказом ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении и содержания
                            организационных и технических мер по обеспечению безопасности персональных данных при их обработке в
                            информационных системах персональных данных», Приказом Роскомнадзора от 05 сентября 2013 г. № 996
                            «Об утверждении требований и методов по обезличиванию персональных данных», Приказом ФСБ России от
                            9 февраля 2005г. № 66 «Об утверждении Положения о разработке, производстве, реализации и эксплуатации
                            шифровальных (криптографических) средств защиты информации» и иными нормативными правовыми актами
                            Российской Федерации.</li>

                        <li>Настоящая Политика конфиденциальности разработана в целях реализации требований законодательства
                            Российской Федерации в области обработки персональных данных и направлена на обеспечение защиты
                            прав и свобод человека и гражданина при обработке его персональных данных, в том числе на защиту
                            от несанкционированного доступа к персональным данным посетителей и зарегистрированных Пользователей
                            Сайта.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P2GeneralProvisions;