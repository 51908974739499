import React, {Component} from 'react';

class DeliveryDetails extends Component {
    render() {
        let { values } = this.props;

        return (
            <div className={"delivery-details"}>
                <div className={"header"}>Детали доставки</div>
                <div>
                    {values.cdek_type === 'courier' ? 'Адрес доставки' : 'Адрес выдачи заказа'}
                    : {
                    (values.delivery_city || '')
                    + (values.delivery_address ? `, ${values.delivery_address}` : '')}
                </div>
                {
                    values.delivery_type !== 'office'
                        ? <div>Срок доставки: {values.delivery_time} дн.</div>
                        : null
                }
            </div>
        )
    }
}

export default DeliveryDetails