import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import './index.scss';
import store from './store';

/* Menu pages */
import MainPage from './components/MainPage';
import Products from './components/MenuPages/Products/Items';
import LeftSideBar from "./components/MenuPages/Products/ItemDetails";
import Education from './components/MenuPages/Education/index';
import Software from './components/MenuPages/Software';
import Contacts from './components/MenuPages/Contacts';

/* Footer pages */
import Mission from "./components/FooterPages/Mission";
import Delivery from './components/FooterPages/Delivery';
import PrivacyPolicy from './components/FooterPages/PrivacyPolicy';
import TermsNConditions from './components/FooterPages/TermsNConditions';
import Support from "./components/FooterPages/Support";
import Guarantee from "./components/FooterPages/Guarantee";
import FAQ from './components/FooterPages/FAQ';

/* Sale pages */
import Cart from './components/SalePages/Cart';
import Compare from './components/SalePages/compare';
import WishList from "./components/SalePages/wishlist";
import Checkout from './components/SalePages/Checkout';
import OrderSuccess from './components/SalePages/Checkout/PaymentResult/SuccessPayment';
import FailPayment from "./components/SalePages/Checkout/PaymentResult/FailPayment";

/* Profile pages */
import Login from './components/ProfilePages/login';
import Register from './components/ProfilePages/register';
import ForgetPassword from './components/ProfilePages/forget-password';
import Dashboard from './components/ProfilePages/dashboard';

/* News pages */
import Details from './components/FuturePages/blogs/details';
import BlogPage from './components/FuturePages/blogs/blog-page';

/* Other components */
import Layout from './components/App';
import PageNotFound from './components/common/404';
import Docs from "./components/FooterPages/Docs";
import BaseExample from "./components/MenuPages/Education/examples/BaseExample";
import AdvancedExample from "./components/MenuPages/Education/examples/AdvancedExample";

const AppRoute = ({ component, ...props }) =>
    <Route {...props} children={
        <Layout>
            { React.cloneElement(component, { match : props.computedMatch }) }
        </Layout>
    }/>;

class Root extends React.Component {
    render() {
        return(
        	<Provider store={store}>
				<BrowserRouter basename={'/'} >
						<Switch>
                            {/* Menu pages */}
                            <Route exact path={`/`} component={MainPage}/>
                            <AppRoute exact path={`/products`} component={<Products/>}/>
                            <AppRoute exact path={`/products/:category`} component={<Products/>}/>
                            <AppRoute path={`/product/:id`} component={<LeftSideBar/>}/>
                            <AppRoute exact path={`/software`} component={<Software/>}/>
                            <AppRoute exact path={`/contacts`} component={<Contacts/>}/>

                            <AppRoute exact path={`/education`} component={<Education/>}/>
                            <AppRoute exact path={`/education/examples/base`} component={<BaseExample/>}/>
                            <AppRoute exact path={`/education/examples/advanced`} component={<AdvancedExample/>}/>

                            {/* Footer pages */}
                            <AppRoute exact path={`/about`} component={<Mission/>}/>
                            <AppRoute exact path={`/delivery`} component={<Delivery/>}/>
                            <AppRoute exact path={`/privacy-policy`} component={<PrivacyPolicy/>}/>
                            <AppRoute exact path={`/terms-n-conditions`} component={<TermsNConditions/>}/>
                            <AppRoute exact path={`/support`} component={<Support/>}/>
                            <AppRoute exact path={`/warranty`} component={<Guarantee/>}/>
                            <AppRoute exact path={`/faq`} component={<FAQ/>}/>
                            <Route exact path="/docs"><Redirect to="/docs/general/purpose" /></Route>
                            <AppRoute exact path={`/docs/:block/:section`} component={<Docs/>}/>

                            {/* Sale pages */}
                            <AppRoute path={`/cart`} component={<Cart/>}/>
                            <AppRoute path={`/checkout`} component={<Checkout/>}/>
                            <AppRoute path={`/order-success`} component={<OrderSuccess/>}/>
                            <AppRoute path={`/order-fail`} component={<FailPayment/>}/>
                            <AppRoute path={`/compare`} component={<Compare/>}/>
                            <AppRoute path={`/wishlist`} component={<WishList/>}/>

                            {/* Profile pages */}
                            <AppRoute path={`/pages/login`} component={<Login/>}/>
                            <AppRoute path={`/pages/register`} component={<Register/>}/>
                            <AppRoute path={`/pages/forget-password`} component={<ForgetPassword/>}/>
                            <AppRoute path={`/pages/dashboard`} component={<Dashboard/>}/>

                            {/* News pages */}
                            <AppRoute path={`/blog/details`} component={<Details/>}/>
                            <AppRoute path={`/blog/blog-page`} component={<BlogPage/>}/>

                            <AppRoute path="*" component={<PageNotFound/>} />
                         </Switch>
					</BrowserRouter>
			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


