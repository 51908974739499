import React, {Component} from "react";
import Tariff from "./Tariff";
import Description from "./Description";
import Map from "./Map";
import Helmet from "react-helmet/es/Helmet";

class Delivery extends Component {
    render() {
        return (
            <>
                <Helmet><title>Доставка</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className="section-b-space container delivery">
                        <div className="row">
                            <div className="col">
                                <h2 className={"text-center header"}>Доставка</h2>
                                <Tariff/>
                                <Description/>
                                <Map/>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default Delivery;