import React, {Component} from 'react';
import Moment from "react-moment";
import {SYMBOL} from "../../../../../../constants";

class OrderDetails extends Component {
    render() {
        let { order } = this.props;
        const outSum = order.cost;
        const invId = order.id;

        const term = (order.delivery ? order.delivery.term : '0').toString().split("-").map(t => +t);
        const sdekDeliveryTime = Math.max(...term);
        let extendedDeliveryDate = new Date();
        extendedDeliveryDate.setDate(extendedDeliveryDate.getDate() + +sdekDeliveryTime);

        return (
            <div className="col-lg-6">
                <div className="row order-success-sec">
                    <div className="col-sm-6">
                        <h4>Заказ</h4>
                        <ul className="order-detail">
                            <li>№ заказа: {invId}</li>
                            <li>Дата заказа: &nbsp;{<Moment format={"LLL"} date={new Date()} locale={"ru"}/>}</li>
                            <li>Итоговая цена: {outSum} {SYMBOL}</li>
                        </ul>
                    </div>
                    <div className="col-sm-6">
                        <h4>Адрес доставки</h4>
                        <ul className="order-detail">
                            <li>
                                {order.delivery ?
                                    (order.delivery.city + ', ' + order.delivery.address) : ''}
                            </li>
                        </ul>
                    </div>


                    <div className="col-md-12">
                        <div className="delivery-sec">
                            <h3>Ожидаемая дата доставки</h3>
                            <h2>{<Moment format={"L"} date={extendedDeliveryDate} locale={"ru"}/>}</h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderDetails