import React, {Component} from 'react';
import Conditions from "./Conditions";
import Time from "./Time";
import Helmet from "react-helmet/es/Helmet";

class Guarantee extends Component {
    render() {
        return (
            <>
                <Helmet><title>Гарантийная политика</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className={"container guarantee"}>
                        <div className="row">
                            <div className="col">
                                <h2 className={"text-center header"}>Гарантийная политика</h2>
                                <Conditions/>
                                <Time/>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Guarantee