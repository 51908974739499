export let details = [
    {
        id: 1,
        name: "Лидерство",
        body: "Мы стремимся быть признанным лидером в области производства и " +
            "реализации инновационных продуктов и технологий в сфере образовательной " +
            "робототехники, помогая нашим клиентам добиваться успехов в мире быстро " +
            "развивающихся рынков.",
        image: "/assets/images/FooterPages/Mission/squares.png"
    },
    {
        id: 2,
        name: "Сочетание опыта и передовых технологий",
        body: "Мы несем ответственность за каждый произведенный и проданный нами продукт. " +
            "Мы создаем атмосферу, в которой профессионалы нашей компании используют свой " +
            "многолетний опыт, а также знание передовых технологий, отвечая интересам наших " +
            "взыскательных клиентов."
    },
    {
        id: 3,
        name: "Проекты национального масштаба",
        body: "Компания обладает всеми необходимыми ресурсами как для разработок и производства " +
            "нового оборудования, так и для реализации проектов национального масштаба."
    }
]