import React, {Component} from 'react';
import SharingBar from "./SharingBar";
import Modal from "react-responsive-modal";

class Balloon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            link: ''
        }
    }

    onOpenModal = (link) => {
        this.setState({ modalOpen: true, link: link });
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false, link: '' });
    };

    render() {
        return (
            <>
                <div className={"balloon-container"}>
                    <div className={"balloon"}>
                        <h6 className={"city"}>{ this.props.city }</h6>
                        {
                            this.props.reviews.map((review, i) =>
                                <div key={i}
                                     className={"review-name"}
                                     onClick={() => this.onOpenModal(review.link)}>

                                    { review.name }
                                    <div className={"arrow"}> </div>

                                </div>
                            )
                        }
                        <div className="arrow"/>
                    </div>
                </div>

                <Modal open={this.state.modalOpen}
                       onClose={this.onCloseModal}
                       center>
                    <div className={"modal-content pdf-viewer sharing"}>
                        <SharingBar link={this.state.link}/>
                        <iframe src={`${process.env.PUBLIC_URL}/assets/files/reviews/${this.state.link}`}
                                title={"Отзыв"}
                                width={"100%"}
                                height={"600px"} style={{ border: 0 }}>
                            Ваш браузер не поддерживает фреймы
                        </iframe>
                    </div>
                </Modal>
            </>
        )
    }
}

export default Balloon