import React, {Component} from 'react';
import "moment/locale/ru";
import {connect} from "react-redux";
import {getOrderDetail} from "../../../../../assets/axios/order";
import Modal from "react-responsive-modal";
import CustomerDetails from "./CustomerDetails";
import Order from "./Order";
import {readGetParameters} from "../../../../../assets/js/functions";
import PageNotFound from "../../../../common/404";

class SuccessPayment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
            modalContent: '',
            order: {}
        }
    }

    componentDidMount() {
        let params = readGetParameters();
        const invId = params['InvId'];
        getOrderDetail(invId,
                response => this.setState( {order: response.data} ),
            () => this.onOpenModal("Не удалось загрузить список товаров")
        );
    }

    onOpenModal = (content) => {
        this.setState({ modalOpen: true, modalContent: content });
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false, modalContent: '' });
    };

    render() {
        let { order } = this.state;
        const outSum = order.cost;
        const invId = order.id;

        return (
            (outSum && invId) ?
                <>
                    <div className={"menu-padding-section"}>
                        <CustomerDetails order={order}/>
                        <Order order={order}/>
                    </div>

                    <Modal open={this.state.openModal} onClose={this.onCloseModal} center>
                        <div className={"modal-warning"}>
                            {this.state.modalContent}
                        </div>
                    </Modal>
                </>
                : <PageNotFound/>
        )
    }
}

const mapStateToProps = state => {
    return { items: state.data.products };
};

export default connect(mapStateToProps) (SuccessPayment);