import React, {Component} from 'react';
import Menu from "./Menu";
import {menu} from "../../../constants/Pages/FooterPages/docs";
import {connect} from "react-redux";
import {findObject} from "../../../assets/js/functions";
import SearchResult from "./SearchResult";

class Docs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0
        }
        this.getWidthScreen = this.getWidthScreen.bind(this)
    }

    componentDidMount() {
        this.getWidthScreen()
        window.addEventListener('resize', this.getWidthScreen)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getWidthScreen)
    }

    getWidthScreen() {
        this.setState({width: window.innerWidth})
    }

    render() {
        let block = findObject(menu, 'id', this.props.block);
        let section = findObject(block.items, 'id', this.props.section);

        return (
            <div className={"menu-padding-section"}>
                <section className={"container"}>
                    <div className={"row"}>
                        <div className={"col navigation"}>
                            <div className={"docs"}>
                                {this.state.width <= 990
                                    ? <h2 className={"header header_mobile"}>Руководство пользователя</h2>
                                    : ''
                                }
                                <Menu width={this.state.width}/>

                                <div className={"content content_mobile"}>
                                    <h2 className={"header"}>Руководство пользователя</h2>
                                    {
                                        this.props.found.term
                                            ? <>
                                                <span className={"results-header"}>
                                                    Найдено результатов по запросу "{this.props.found.term}": {this.props.found.hits.length}
                                                </span>

                                                {
                                                    this.props.found.hits.map((hit, i) =>
                                                        <SearchResult item={hit}
                                                                      key={i}
                                                                      number={i + 1}
                                                                      term={this.props.found.term}/>
                                                    )
                                                }
                                            </>
                                            : section.content
                                    }
                                    <div id={'search-container'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        docs: state.docsSearch.docs,
        found: state.docsSearch.found,
        section: ownProps.match.params.section,
        block: ownProps.match.params.block,
    }
}

export default connect(mapStateToProps)(Docs);