import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Button extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль кнопки</h4>

                <p>
                    Модуль кнопки представляет собой печатную плату с установленным
                    микропереключателем – кнопкой. Внешний вид модуля кнопки приведен на рисунке 2.16.
                </p>

                <p>
                    Области применения модуля:<br/>
                    - срабатывание при физическом контакте;<br/>
                    - обнаружение препятствия;<br/>
                    - в Конструкторе используется в качестве тренировочного элемента.
                </p>

                <p>
                    При нажатии на кнопку происходит изменение выходного напряжения,
                    сигнализирующего о замыкании контактов кнопки.
                </p>

                <Image src={"/Constructor/button.png"} name={"Внешний вид модуля кнопки"} number={"2.16"}/>

                <p>
                    Основные технические характеристики модуля кнопки приведены в таблице 2.15.
                </p>

                <Table name={"Основные технические характеристики модуля кнопки"}
                       number={"2.15"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Максимальный рабочий ток", "0,05 А"],
                           ["Максимальная высота", "4,3 мм"],
                           ["Типоразмер", "6 x 6 мм"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Тип переключателя", "тактовая кнопка"],
                           ["Номинальное значение напряжения электропитания", "5 В"],
                           ["Средний ток потребления", "5 мА"],
                       ]}/>
            </div>
        )
    }
}

export default Button