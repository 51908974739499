import React, {Component} from 'react';
import Table from "../common/Table";

class Emergency extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Аварийные ситуации при использовании Конструктора</h4>

                <p>
                    Перечень аварийных ситуаций при использовании Конструктора и методы их устранения
                    приведены в таблице 4.1.
                </p>

                <Table name={"Перечень аварийных ситуаций при использовании Конструктора и методы их устранения"}
                       className={"half-td"}
                       number={"4.1"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["При нажатии на кнопку включения робот не работает", "Проверить электропитание (вытащены ли заглушки из аккумуляторного блока).\n" +
                           "Вставить заново аккумуляторы.\n" +
                           "Подключить провода электропитания\n"],
                           ["Не светятся светодиоды на плате расширения при включении робота", "Не подключён провод электропитания, светодиод сгорел. Проверить работоспособность робота, он может работать и без светодиода. Необходимо загрузить программу в робота и получить сообщение о том, что загрузка завершена"],
                           ["Робот не двигается", "Нет электропитания двигателей колес, нет электропитания от аккумулятора, проверить крутятся ли колёса вручную (возможно механическая блокировка посторонним предметом)"],
                           ["Загруженная программа в робота не выполняется, либо выполняется не корректно", "Проверить загрузилась ли программа без ошибок (удостовериться в том, что получено подтверждение о том, что загрузка завершена).\n" +
                           "Не отсоединять провод при загрузке программы. Загружать программу в робота только с выключенным электропитанием"],
                           ["При движении прямо робот поворачивает", "Колёса крутятся с разной скоростью, вариант нормы.\n" +
                           "Проверить нет ли механической блокировки колёс посторонним предметом.\n" +
                           "Разное отклонение по скорости вращения колёс может наблюдаться на разных поверхностях пола в разной степени\n"],
                           ["Программа Arduino не устанавливается на компьютер", "Системные требования компьютера ниже, чем необходимо для корректной работы программы.\n" +
                           "Удалить старую версию программы при её наличии.\n" +
                           "Перезапустить установку программы. Перезагрузить компьютер"],
                           ["Программа Omegabot_IDE не устанавливается на компьютер", "Системные требования компьютера ниже, чем необходимо для корректной работы программы.\n" +
                           "Удалить старую версию программы при её наличии.\n" +
                           "Перезапустить установку программы. Перезагрузить компьютер"],
                           ["Новая версия ПО не устанавливается", "Удалить старую версию ПО.\n" +
                           "Перезапустить установку ПО.\n" +
                           "Проверить системные требования"],
                           ["Программа Omegabot_IDE или Arduino не работает", "Проверить правильность установки.\n" +
                           "Перезапустить компьютер.\n" +
                           "Установка программ должна быть произведена от имени администратора"],
                           ["Неизвестные ошибки при работе с ПО", "Перезапустить программу.\n" +
                           "Перезагрузить компьютер"],
                           ["Не загружается программа в робота", "Переподключить робота к компьютеру.\n" +
                           "Перезапустить загрузку программы.\n" +
                           "Переустановить ПО"],
                           ["Ошибки при работе в программе:\n" +
                           "- ошибка открытия последовательного порта;\n" +
                           "- плата недоступна\n", "Робот не подключен к компьютеру.\n" +
                           "Подключить робота к компьютеру.\n" +
                           "Проверить подключение робота к компьютеру и отображение подключения порта в настройках\n"],
                           ["Значения, получаемые с датчиков, не верные (буквенные или имеют отрицательные численные значения, или значения сильно выходящие за диапазон нормальных, установленных производителем)", "Варианты нормы значений, получаемых от модулей:\n" +
                           "- модуль датчика света: 0….1023;\n" +
                           "- модуль датчика линии: 0….1023;\n" +
                           "- модуль магнитного датчика: 0….1023;\n" +
                           "- модуль потенциометра: 0….1023;\n" +
                           "- модуль кнопки: 0/1;\n" +
                           "- модуль датчика касания: 0/1;\n" +
                           "- модуль светодиода: 0/1;\n" +
                           "- модуль датчика температуры: значение температуры, отображаются до сотых долей градуса;\n" +
                           "- модуль УЗ дальномера: расстояние от 2 до 250 см;\n" +
                           "- модуль пьезоизлучателя: 100….4000;\n" +
                           "- модуль сервопривода: 0….180\n"],
                           ["Светодиод робота мигает красным цветом", "Срабатывает защита (произошло замыкание контактов робота попаданием воды или металлических предметов в контакты платы робота). Исключить попадание воды и предметов из металла на плату контактов робота.\n" +
                           "Робот загружает ПО (ситуация в норме), дождаться загрузки ПО.\n" +
                           "Аккумуляторы робота садятся (зарядить аккумуляторы робота)\n"],
                           ["Аккумуляторы робота не заряжаются", "Подождать.\n" +
                           "Переподключить провод зарядки аккумуляторов.\n" +
                           "Проверить:\n" +
                           "- все ли аккумуляторы находятся в отсеке;\n" +
                           "- правильность установки аккумуляторов (не перепутана ли полярность).\n" +
                           "Робот не будет заряжаться, если вставлен один аккумулятор. Необходимо установить в отсек оба аккумулятора.\n" +
                           "При зарядке аккумуляторов необходимо отключить робота нажатием кнопки\n"],
                           ["Светодиод робота мигает при зарядке (индикатор рядом с кнопкой включения робота)", "Произошло нагревание аккумуляторов при зарядке. Необходимо отключить зарядку, подождать остывания аккумуляторов. Продолжить зарядку."],
                           ["Не светится индикатор при зарядке робота (рядом с кнопкой включения робота)", "Робот заряжен.\n" +
                           "Переподключить зарядку робота, если есть уверенность, что робот не заряжен\n"],
                           ["Схват робота работает некорректно, заедает", "Недостаточное напряжение электропитания. Корректная работа схвата гарантируется только при электропитании от аккумуляторов (электропитание робота не должно осуществляться только от компьютера). Допустимо кратковременное заедание схвата при работе. При возникновении такой ситуации необходимо полностью отключить электропитание робота от аккумулятора и от компьютера, механически закрыть и открыть схвата руками. Подключить электропитание робота заново"],
                           ["Сервомоторы робота работают некорректно, заедают (установлены в модуле сервопривода, модуле УЗ дальномера, модуле видеокамеры)", "Недостаточное напряжение электропитания. Корректная работа сервомоторов гарантируется только при электропитании от аккумуляторов (электропитание робота не должно осуществляться только от компьютера). Допустимо кратковременное заедание сервоприводов при работе. При возникновении такой ситуации необходимо полностью отключить электропитание робота от аккумулятора и от компьютера, механически прокрутить оси приводов руками. Подключить электропитание робота заново"],
                           ["Некорректно работает микрокомпьютер", "Недостаточное напряжение электропитания. Корректная работа микрокомпьютера гарантируется только при электропитании от аккумуляторов (электропитание робота не должно осуществляться только от компьютера). Допускается электропитание робота и от компьютера, и от аккумуляторов одновременно"],
                       ]}/>
            </div>
        )
    }
}

export default Emergency