import React, {Component} from 'react';

class Image extends Component {
    render() {
        return (
            <div className={"image"}>
                <img src={`/assets/images/FooterPages/Docs${this.props.src}`} alt={this.props.name}/>
                <div>
                    Рисунок {this.props.number}<br/>
                    { this.props.name }
                </div>
            </div>
        )
    }
}

export default Image