import React, {Component} from 'react';

class Description extends Component {
    render() {
        return (
            <section>
                <div className={'description'}>
                    <img src={`/assets/images/FooterPages/Delivery/truck.png`}
                         alt={''} className={'img-fluid'}/>
                     <div className={'content'}>
                         <div className={'stats'}>
                             <div>
                                 Более<br/>
                                 <div className={'number'}>1900</div>
                                 пунктов выдачи<br/>
                                 в более чем 16 странах
                             </div>
                             <div>
                                 Пункты выдачи<br/>
                                 более чем в <br/>
                                 <div className={'number'}>200</div>
                                 городах России
                             </div>
                         </div>
                         <div className={'default-tariff'}>
                             Доставка до пункта выдачи осуществляется по тарифу от 150 руб.
                             Точная стоимость доставки до вашего пункта выдачи (или адреса)
                             расчитывается автоматически при оформлении покупки.
                         </div>
                     </div>
                </div>
            </section>
        )
    }
}

export default Description