import React, {Component} from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';

class BaseTheory extends Component {
    render() {
        return (
            <div className="accordion-container">
                <Accordion atomic={true}>
                    <AccordionItem title="Роль робототехники в образовательном процессе">
<p><strong>Образовательная робототехника</strong> &ndash; это новое междисциплинарноенаправление обучения школьников, интегрирующее знания о физике,мехатронике, технологии, математике, кибернетике и ИКТ, позволяющеевовлечь в процесс инновационного научно-технического творчестваучащихся разного возраста.Она направлена на повышение престижа инженерных профессий среди молодежи, развитие навыков практического решения актуальных инженерно-технических задач и работы с техникой.</p>
<p></p>
<p>Сегодня робототехника представляет собой интегративное направление научно-технического прогресса, объединяющее знания в области физики, микроэлектроники, современных информационных технологий и искусственного интеллекта. Робототехника охватывает достаточно широкий класс систем: от полностью автоматизированных производств (производственные конвейерные линии, беспилотные космические корабли, автоматические подводные аппараты и т. д.) до бытовых помощников и детских игрушек.</p>
<p>Робототехника является прикладной наукой, занимающейся разработкой автоматизированных технических систем и являющаяся важнейшей технической основой интенсификации производства. Робототехника опирается на такие дисциплины, как электроника, механика, программирование.</p>
<p>Образовательная робототехника &mdash; это инструмент, закладывающий прочные основы системного мышления.</p>
<p></p>
<p>Технологическое образование является одним из важнейших компонентов подготовки подрастающего поколения к самостоятельной жизни. Деятельностный характер технологического образования, направленность содержания на формирование учебных умений и навыков, обобщенных способов учебной, познавательной, коммуникативной, практической, творческой деятельности позволяет формировать у школьников способность ориентироваться в окружающем мире и подготовить их к продолжению образования в учебных заведениях любого типа.</p>
<p></p>
<p>Робототехника может быть представлена в учебном процессе:</p>
<p>1) как объект изучения,</p>
<p>2) как инструмент познания,</p>
<p>3) как средство обучения, развития и воспитания учащихся</p>
<p></p>
<p>Технологии образовательной робототехники способствуют эффективному овладению обучающимися универсальными учебными действиями, так как объединяют разные способы деятельности при решении каждой конкретной задачи.</p>
<p>Использование робототехники позволяет повысить мотивацию к изучению отдельных образовательных предметов на ступени основного общего образования, а также способствует развитию коллективного мышления.</p>
<p>Аспект интеграции и практической направленности изучения образовательной робототехники связан со сборкой и программированием самих Роботов.</p>
<p></p>
<p>Занятия, проводимые с использованием робототехники стимулируют изучение современных сред и технологий программирования и в некотором смысле моделируют жизненные ситуации, когда необходимо собрать техническую систему, запрограммировать её и только после этого запустить в эксплуатацию. Таким образом, можно подчеркнуть двойственную направленность использования робототехники: образовательную и технологическую.</p>
<p><br />Кроме того, учащиеся, используя робототехнику в образовательном процессе совершенствуют способности:</p>
<p>&ndash; ставить и решать задачи;</p>
<p>&ndash; предвидеть результаты своей деятельности;</p>
<p>&ndash; вариативно строить пути достижения поставленных целей;</p>
<p>&ndash; умение понимать, выполнять и составлять алгоритмы;</p>
<p>&ndash; умение работать в команде;</p>
<p>&ndash; способность согласовывать свои действия с действиями сверстников;</p>
<p>&ndash; умение планировать совместную деятельность<strong>.</strong></p>
<p>&nbsp;</p>
<p>Образовательная робототехника использует робототехнику преимущественно как инструмент, а не как цель обучения, чтобы &laquo;мотивировать и облегчить обучение другим, часто фундаментальным, темам, таким как программирование, искусственный интеллект или инженерное проектирование.</p>
                    </AccordionItem>
                    <AccordionItem title="Робототехническая платформа Омегабот, как средство обучения">
                    <p>Характеризуя работу с платформой Омегабот, можно выделить целевой и содержательный аспекты обучения.</p>
<p>Целевой аспект: проектная деятельность на занятиях с Робототехнической платформой способствует эффективному формированию у обучающихся всего комплекса универсальных учебных действий (познавательных, регулятивных, личностных, коммуникативных);</p>
<p>Содержательный аспект: у учителя появляется возможность эффективной реализации межпредметных связей по основным школьным предметам &laquo;Информатика&raquo;, &laquo;Физика&raquo;, &laquo;Математика&raquo;.</p>
<p></p>
<p>При проведении учебных занятий с Роботом Омегабот, используются знания из основных школьных предметов: информатики, физики, математики.</p>
<p>Информатика как ведущий учебный предмет сохраняет свою специфику, а физика и математика выступают в качестве вспомогательной основы.</p>
<p><br />Можно выделить два вида интегративных связей с учебными предметами:</p>
<p>1) элементы предметных знаний, необходимые для изучения робототехники;</p>
<p>2) элементы межпредметных знаний, необходимые для изучения робототехники.</p>
<p><br />Элементы предметных знаний:</p>
<p>Создание и отладка алгоритмов для Робота &ndash; задача из курса информатики.</p>
<p>В то же время программирование устройств (моторов и датчиков), которыми оснащен Робот, затрагивает область физики. При создании программ необходимо понимать суть Работы датчика (физические закономерности, на которых основана его работа), учитывать погрешности измерения датчика и др.</p>
<p>Математика позволяет решать задачи с углами, градусами, коэффициентами и пропорциями. <br /><br />Элементы межпредметных знаний:</p>
<p>Физика и Математика дают возможность рассчитывать траекторию движения Робота, измерять и рассчитывать значения физических величин.</p>
<p>Информатика и Математика позволяют создавать достаточно сложные алгоритмы для Робота с использованием переменных величин и математических вычислений.</p>
<p>Информатика и Физика &ndash; программировать датчики моторы.</p>

<p>Система занятий с Робоплатформой должна быть построена по принципу углубления в предметную область от общего к частному.</p>
<p>Первоначальный аспект знакомства с платформой подразумевает изучение Робота, как исполнителя программы, включающей в себя 3 основные системы: систему управления, систему исполнения и систему обратной связи. Далее, каждая из систем рассматривается более подробно.</p>
<p></p>
<p>Занятия с использованием Робоплатформы Омегабот позволят достичь целый комплекс образовательных целей, проводить занятия с разными возрастными категориями обучающихся, путём более глубокого погружения в предметную область, развить логическое мышление, научится оценивать влияние отдельных факторов и анализировать результаты.</p>
                    </AccordionItem>
                    <AccordionItem title="Система управления роботом - аппаратная часть">
 <p>Основная составляющая Робоплатформы &ndash; это аппаратная платформа, которая имеет архитектуру Arduino.</p>
<p>Аппаратную платформу можно запрограммировать для связи с различными электронными компонентами и управления ними. Она оборудована несколькими контактами, способными работать как в режиме ввода, что означает возможность получать через них данные от различных компонентов, таких как коммутаторы, кнопки и датчики, так и вывода, допуская передачу данных для управления устройствами, такими как моторы и пьезоизлучатели.</p>
<p>То, каким образом будет происходить ввод и вывод сигналов на аппаратную платформу должно быть указано в программной части системы управления.</p>
<p>Аппаратная платформа крепится на верхнюю часть колёсной платформы, и имеет основу с магнитными креплениями.</p>
<p>Колесная платформа является несущей конструктивной частью Робота и обеспечивает механическую устойчивость. Колесная платформа состоит из жесткой металлической рамы и съёмной основы. Также на раме крепится аккумуляторный блок и мотор-колёса. Внешними габаритами колесной платформы являются края пластиковой пластины.</p>
<p>На колесной платформе с базовой основой предусмотрены четыре гнезда для магнитного крепления внешних модулей, что позволяет устанавливать на платформу до четырех датчиков или исполнительных устройств дополнительно к интегрированным на плату контроллера и в плату расширения.</p>
<p>К базовой основе предусмотрен модуль &ndash; расширение для дополнительной установки модулей (датчиков).</p>
<p>На аппаратную платформу сверху предусмотрено крепление платы расширения.</p>
<p>Плата расширения служит для удобства подключения периферии и обеспечения его питания. Имеет возможность подключения моторов, модуля Bluetooth, подключения периферии по шине I2C.</p>
<p>Аккумуляторный блок предназначен для зарядки и размещения в нём 2-х аккумуляторов типа 18650. Аккумуляторный блок содержит схему защиты от тока короткого замыкания. От аккумуляторного блока питаются мотор-колёса и аппаратная платформа.</p>
                    </AccordionItem>
                    <AccordionItem title="Система управления роботом - программная часть «Знакомство с ArduinoIDE и Omegabot_IDE»">
                    <p><strong>Система управления роботом - программная часть</strong></p>
<p>Программная среда Omegabot_IDE, Arduino IDE.</p>
<p>Программа управления роботом должна иметь чёткую структуру. Алгоритм программы составляется последовательно, путём постановки задачи и разделения её на конкретные элементарные шаги.</p>
<p>Составление правильного алгоритма программы поможет сделать первый шаг к пониманию процесса работы всех трёх систем робота. Научиться задавать роботу понятные структурированные команды и получать обратную связь с датчиков, установленных на Робоплатформе.</p>
<p>Программы и части программ можно объединять в более сложные сценарии работы Робоплатформы.</p>
<p><strong>Omegabot</strong><strong>_</strong><strong>IDE</strong></p>
<p>Программная среда Omegabot_IDE специально разработанная для управления Роботом, позволяет писать алгоритм управления, путём перетаскивания в рабочую область цветных блоков с командами.</p>
<p>Программная среда Omegabot_IDE содержит 16 типов (блоков) команд. Типы команд, помимо разного содержания, имеют разный цвет.</p>
<p>Типы команд:</p>
<p>Управление / Порты / Проверки / Переменные константы / Математические операторы / Сенсоры / Серво / Светодиоды / Звук / Дисплей / Ориентация в пространстве / Моторы / Энкодер / Коммуникации / Хранилище / Блоки кода.</p>
<img alt="" src={"/assets/images/MenuPages/Education/examples/types.png"} className={"img-fluid"}/>
<p>&nbsp;</p>
<p>Слева в области программной среды&ndash; панель для выбора блоков (команд).</p>
<p>Справа в области программной среды &ndash; холст, где будет писаться программа.</p>
<p>Внимание! Робот будет выполнять только ту часть программы, которая находится внутри команды &laquo;Цикл&raquo; или команды &laquo;Программа&raquo;. Эти команды находятся в блоке команд &ndash; Управление.</p>
<p>В правой части программной среды можно будет увидеть подсказки, при составлении программы. В окне подсказок также отображаются подключенные модули (датчики).</p>
<p>Значения переменных, которые выдают модули датчиков, можно увидеть в Мониторе последовательного порта.</p>
<p>Для этого нужно при составлении программы в Цикл добавить команду &ndash; &laquo;в последовательный порт&raquo; из блока программ &laquo;Коммуникации&raquo;, присоединить к ней команду &laquo;+&raquo; из того же блока &laquo;Коммуникации&raquo; и присоединить к ним выбранный модуль (например, модуль датчика линии из блока &laquo;Сенсоры&raquo;), указать порт, к которому подключён выбранный модуль.</p>
<p>В программной среде это выглядит так:</p>
<img  alt="" src={"/assets/images/MenuPages/Education/examples/view.png"} className={"img-fluid"}/>                                
<p>&nbsp;</p>
<p>После загрузки программы с блоком последовательного порта мы сможем включить робота и увидеть в мониторе последовательного порта значения, получаемые датчиком. Для этого нужно нажать на кнопку &laquo;монитор последовательного порта&raquo; рядом с кнопкой &laquo;загрузка программы&raquo; (в верхней части программной среды).</p>
<p>Робоплатформу также можно программировать в программной среде <strong>Arduino</strong> <strong>IDE</strong></p>
<p><strong>Программная среда</strong> <strong>Arduino</strong> <strong>IDE</strong></p>
<p><strong>Arduino</strong> <strong>IDE</strong> &ndash; специально создана для написания программ, их компиляции и программирования аппаратных продуктов.</p>
<img alt="" src={"/assets/images/MenuPages/Education/examples/program.png"} className={"img-fluid"}/>
<p>&nbsp;</p>
<p>Язык программирования в Arduino IDE является стандартным C++ (используются компиляторы семейства GNU Compiler Collection) с некоторыми особенностями, облегчающими новичкам написание первой работающей программы.</p>
<p>Программы, написанные в Arduino IDE, называются наброски (или иногда скетчи &mdash; транслитерация от англ. sketch) и сохраняются в файлах с расширением *.ino. Эти файлы обрабатываются препроцессором контроллера.</p>
<p>Обязательную в C++ функцию main() препроцессор контроллера создает сам, вставляя туда необходимые &laquo;черновые&raquo; действия.</p>
<p>Пользователь должен написать две обязательные для Arduino функции setup() и loop(). Первая вызывается однократно при старте, вторая выполняется в бесконечном цикле.</p>
<p>В текст своей программы (скетча) пользователь не обязан вставлять заголовочные файлы используемых стандартных библиотек. Эти заголовочные файлы добавит препроцессор контроллера в соответствии с конфигурацией проекта (программы). Однако пользовательские библиотеки нужно указывать.<br /><br />Библиотеки являются очень мощным инструментом при работе с Arduino, особенно для новичка. Библиотека является файлом (набором файлов), содержащим точно такой же С++ код, на котором пишется код программы. Мы можем подключить библиотеку в свой код и использовать возможности, которые она даёт, а вариантов там весьма много: готовые &ldquo;инструменты&rdquo; для работы с внешними датчиками и модулями, для работы с внутренней периферией микроконтроллера, библиотеки различных математических инструментов и многое другое.</p>
<p>В Arduino IDE имеется нестандартный механизм добавления библиотек. Библиотеки в виде исходных текстов на стандартном C++ добавляются в специальную папку в рабочем каталоге IDE. При этом название библиотеки добавляется в список библиотек в меню IDE.</p>

                    </AccordionItem>
                    <AccordionItem title="Система исполнения робота (приводы, промежуточные передачи, рабочие органы)">
                    <p>К исполнительной системе Робота относятся приводы (моторы), рабочие органы (схват), промежуточные передачи (редукторы), модуль светодиода, модуль пьезодинамика, модуль Bluetooth (выступает как в качестве системы обратной связи, так и в качестве системы исполнения).</p>
<p>В качестве приводов могут использоваться разные двигатели: коллекторные и бесколлекторные (постоянного и переменного тока), шаговые, серводвигатели.</p>
<p>В четырехколесной платформе Омегабот используются двигатели не переменного тока, а более удобные в управлении коллекторные двигатели постоянного тока. Они легко управляются &mdash; путём подачи на них постоянного тока от аккумуляторов. Причем скорость вращения напрямую зависит от величины напряжения. Поменять направление вращения тоже просто, надо лишь поменять полярность подаваемого напряжения.</p>
<p></p>
<p>Двигатель &mdash; это устройство, которое преобразует какой-либо вид энергии (в данном случае электрическую энергию) в механическую и наоборот. Эффективность данного процесса зависит от внутренней конструкции двигателя, которая в свою очередь зависит от источника тока (постоянного или переменного).</p>
<p>Коллекторный двигатель состоит из: якоря, ротора, подшипников, щёток, коллектора (коммутатора), обмотки, сердечника статора.</p>
<p>Еще одна разновидность двигателей, которая часто используется в робототехнике, &mdash; шаговые. Они умеют по команде &laquo;сделать шаг&raquo; &mdash; то есть повернуться на заранее заданный угол, величина шага (или угла поворота) зависит от конкретной конструкции данного двигателя.</p>
<p>В конструкции схвата у Омегабот используются серводвигатели. Серводвигателями называют двигатели, в состав которых входят датчики-сенсоры (или энкодеры).</p>
<p>Чтобы расширить базу знаний по системе исполнения робота, необходимо понимать в первую очередь, почему в конструкции Робота использованы те или иные двигатели и исполнительные механизмы, а также знать как они работают.</p>
<p>Кроме двигателей, которые используются в четырёхколесной платформе и в схвате, к системе исполнения робота можно отнести некоторые модули.</p>
<p><strong>Модуль светодиода</strong> представляет собой печатную плату с установленным светодиодом. При поступлении на вход модуля светодиода сигнала, светодиод начинает излучать свет в видимом диапазоне.</p>
<p><strong>Модуль пьезодинамика</strong> представляет собой печатную плату с установленным пьезодинамиком. При наличии управляющего сигнала модуль воспроизводит звуковой сигнал. Модуль пезодинамика состоит из пассивного зуммера. Он требует источника сигнала, который задаст параметры звукового сигнала. В качестве такого источника выступает аппаратная платформа.</p>
<p></p>
<p><strong>Модуль </strong><strong>Bluetooth</strong> представляет собой печатную плату-адаптер со встроенным интерфейсом Bluetooth, который позволяет передавать данные на небольшое расстояние с помощью радиоволн. При этом на обоих устройствах должен быть Bluetooth модуль, который и выступает как передатчик, так и отправитель. &nbsp;Bluetooth модуль позволяет осуществлять управление роботами и другими электронными устройствами; передавать данные от устройств, датчиков, модулей, установленных на роботе.</p>
<p>Вся исполнительная система Робота напрямую получает сигналы от системы управления, и действует благодаря заложенной программе.</p>
                    </AccordionItem>
                    <AccordionItem title="Система обратной связи робота (генераторные датчики, параметрические датчики)">
                    <p>Системой обратной связи Робота можно назвать модули датчиков, которые есть в комплекте Робоплатформы.</p>
<p></p>
<p>Датчики играют в робототехнике одну из важнейших ролей. При помощи различных датчиков Робот &laquo;чувствует&raquo; себя самого и окружающий мир. <br /><br />При помощи датчиков мы можем оценивать окружающее пространство и внутренние параметры Робота. <br />В примере образовательной робототехники, ориентируясь на показания датчиков можно составлять программы движения Робота по определенной траектории (или лабиринту), движения от света (или к свету) и реализовывать другие сценарии поведения, комбинируя использование различных датчиков между собой.</p>
<p></p>
<p>Модули датчиков преобразуют измеряемую величину в сигнал (электрический, оптический, пневматический), удобный для измерения, передачи, преобразования, хранения и регистрации информации о состоянии объекта измерений.</p>
<p></p>
<p>Модули датчиков можно классифицировать:</p>
<p></p>
<p>По методу измерения: Активные (генераторные) / Пассивные (параметрические)</p>
<p>По виду измерительных сигналов: Аналоговые / Цифровые</p>
<p>По среде передачи сигналов: Проводные / Беспроводные</p>
<p>По взаимодействию с источниками информации: Контактные / Бесконтактные</p>
<p>По измеряемому параметру: положения, уровня, температуры и т.д.</p>
<p></p>
<p>Также существуют многофункциональные датчики, которые могут воспринимать и преобразовывать нескольких входных величин, и, помимо основной функции (восприятие величины и формирование измерительного сигнала) выполнять ряд дополнительных функций, таких как функции фильтрации, обработки сигналов и т.п</p>
<p></p>
<p>Назначение и принцип действия модулей датчиков, входящих в комплект Робоплатформы:</p>
<p><strong>Датчик расстояния</strong> предназначен для определения расстояния до объекта и состоит из комбинации устройств: датчика положения, инфракрасного светодиода и схемы обработки сигнала. Инфракрасный светодиод посылает сигнал в виде лазерного луча, который отражается от стоящего перед ним препятствия и возвращается обратно в фотоэлемент. На основе того с какой скоростью вернулся сигнал, аппаратная платформа вычисляет расстояние до препятствия.</p>
<p><strong>Модуль датчика линии. </strong>Чувствительный элемент - &nbsp;оптический датчик. Модуль предназначен для обнаружения линий (белых или чёрных) на основании определения отражающей способности поверхности. При подаче питания на модуль, инфракрасный светодиод начинает излучать свет, который отражаясь от поверхности попадает на фототранзистор. По интенсивности отраженного света можно судить о цвете поверхности.</p>
<p><strong>Модуль датчика касания. </strong>Чувствительный элемент - концевой выключатель. При физическом контакте модуля датчика касания с препятствием во время движения, происходит замыкание контактов концевого выключателя, в результате чего вырабатывается соответствующий сигнал.</p>
<p><strong>Модуль датчика света. </strong>Чувствительный элемент &ndash; фоторезистор. Модуль датчика света является аналоговым, выходной сигнал с фоторезистора прямо пропорционален интенсивности света, падающего на чувствительную поверхность датчика. Фоторезистор модуля датчика света изменяет сопротивление в зависимости от силы падающего на него света.</p>
<p><strong>Модуль потенциометра</strong>. Чувствительный элемент &ndash; переменный резистор. При повороте ручки потенциометра аналоговый входной сигнал будет плавно меняться. При помощи потенциометра можно плавно менять яркость светодиода, управлять движением схвата, управлять уровнем звука и регулировать работу других исполнительных механизмов Робота.</p>
<p><strong>Модуль магнитного датчика. </strong>Чувствительный элемент &ndash; датчик Холла. При изменении магнитного поля рядом с датчиком на выходе датчика формируется разность потенциалов.</p>
<p><strong>Модуль датчика температуры. </strong>Чувствительный элемент &ndash; датчик температуры, который преобразует температуру окружающей его среды в цифровой код, поступающий на выходные контакты соединителя.</p>
<p><strong>Модуль кнопки. </strong>Чувствительный элемент - &nbsp;микропереключатель-кнопка. При нажатии на кнопку происходит изменение выходного напряжения, сигнализирующего о замыкании контактов кнопки.</p>
<p><strong>Модуль энкодера</strong>. Чувствительный элемент &ndash; светодиод и фототранзистор. Модуль предназначен для преобразования углового положения или линейного перемещения в цифровой сигнал. Светодиод постоянно излучает. При наличии препятствия между светодиодом и фототранзистором световой поток не доходит до фототранзистора, фототранзистор закрывается, микросхема сигнализирует о наличии в ней препятствия. Тем самым, при подключении модулей энкодера к моторам, можно программировать платформу на перемещение на какое-то определенное расстояние, или поворот на какой-либо определенный угол.</p>
<p><strong>Модуль </strong><strong>Bluetooth</strong> представляет собой печатную плату-адаптер со встроенным интерфейсом Bluetooth, который позволяет передавать данные на небольшое расстояние с помощью радиоволн. При этом на обоих устройствах должен быть Bluetooth модуль, который и выступает как передатчик, так и отправитель. &nbsp;Bluetooth модуль позволяет осуществлять управление роботами и другими электронными устройствами; передавать данные от устройств, датчиков, модулей, установленных на роботе.</p>
<p></p>
<p><strong>Модули датчиков обеспечивают сбор информации о состоянии Робота</strong>. <br />На основании данных с модулей для Робота в устройстве управления формируются значения&nbsp;<strong>переменных состояния объекта</strong>.</p>
<p><strong>Датчики могут измерять состояние объекта напрямую, или косвенно</strong>. Например, датчик температуры изменяет свое сопротивление при изменении температуры, что позволяет эту температуру измерить. С одной стороны, это косвенный способ измерения, так как мы будем измерять сопротивление, а не температуру напрямую. Однако считается, что это датчик непосредственного измерения температуры</p>
<p>То есть, прямым измерением некоторой величины называется измерение ее датчиком для этого и предназначенным. Косвенным измерением называется вычисление измеряемой величины через прямые измерения других величин, которые связаны с измеряемой величиной.</p>
<p>Фактически, все наши измерения косвенные, так как, в большинстве случаев измеряются электрические параметры на которые измеряемая величина влияет. Или механические, например, изгиб пластины. Однако, факт такого преобразования игнорируется, для упрощения и выделения именно сути косвенного измерения.</p>
<p>Измерение может быть<strong>&nbsp;контактным и бесконтактным</strong>. Например, мы можем измерить температуру инфракрасным термометром не касаясь, и даже не приближаясь, к объекту измерения. Не всегда бесконтактность очевидна. Возьмем ёмкостный датчик уровня жидкости в виде трубки опущенной в сосуд. На первый взгляд, тут явно контактный способ измерения. Но надо учесть, что сам датчик, обкладки конденсатора, изолирован от объекта измерения, жидкости. То есть, измерение ведется бесконтактным способом. А вот если мы определяем уровень с помощью металлических электродов используя проводимость воды, то способ измерения у нас контактный. Так как датчик, контактирует с водой, как с объектом измерения.</p>
<p>Выходной сигнал датчиков может быть&nbsp;<strong>логическим (цифровым)</strong>, то есть, иметь только два значения - включено или выключено, ноль или 1. Выходной сигнал может быть&nbsp;<strong>аналоговым</strong>, то есть изменяться плавно, в какой-то функциональной зависимости от измеряемой величины.</p>
                    </AccordionItem>
                    <AccordionItem title="Обзор соревнований и подготовка робота к ним">
                    <p>Сравнение робототехнических соревнований со спортом породило</p>
<p>термины соревновательная робототехника и спортивная робототехника.</p>
<p>Соревновательная робототехника в первую очередь интересна как любой вид соревнований. Учащимся хочется понять, насколько они лучше других в данной дисциплине. Это могут быть соревнования по конструированию, программированию.</p>
<p></p>
<p>Целью проведения робототехнических соревнований является:</p>
<ul>
<li>Выявление талантливой творческой молодежи,</li>
<li>Развитие компетенций и популяризация научно-технического творчества,</li>
<li>Пропаганда технического образования.</li>
</ul>
<p></p>
<p>Задачи Соревнований:</p>
<ul>
<li>Создание условий для личностной самореализации,</li>
<li>Активизация творческой деятельности учащихся в сфере информационных технологий;</li>
<li>Выявление и поощрение познавательной одаренности учащихся;</li>
<li>Выявление и поддержка наиболее активных и способных участников соревнований;</li>
<li>Популяризация и пропаганда знаний в сфере информационных технологий, знаний по робототехнике;</li>
<li>Организация неформального общения детей друг с другом и со взрослыми, создание атмосферы заинтересованности, взаимовыгодного и плодотворного сотрудничества.</li>
</ul>
<p></p>
<p>Соревнования по робототехнике можно классифицировать по различным основаниям.</p>
<p>Первый подход &mdash; &laquo;олимпиадный&raquo;, когда ответ или решения конкурсных заданий состязаний заранее известны (WorldSkills, JuniorSkills, ряд направлений WRO и &laquo;РобоФеста&raquo;).</p>
<p>Второй подход &mdash; &laquo;конкурсный&raquo;, когда эталонного ответа на поставленную соревнованием задачу не существует (EUROBOT, часть направлений RoboCup). Этот подход в большей степени способствует развитию креативности участников, стимулирует научные исследования и технологические разработки. </p><p>Третий подход &mdash; стимулирующий (мотивирующий), когда соревнования являются в большей степени праздником, игрой, общением. Задачей таких соревнований могут быть выявление талантливых учеников, обмен опытом, мотивация участников на дальнейшее обучение, навыки управления и работы в команде.</p>
<p>Робототехнические соревнования ориентированы на разный возраст участников, в том числе и на студентов.</p>
<p></p>
<p><strong>Фестиваль &laquo;РобоФест&raquo;</strong> &mdash; крупнейший в Европе и один из крупнейших в мире фестивалей, ежегодно собирающий лучших участников научно-технического творчества в возрасте от 6 до 30 лет, которые представляют там свои разработки.</p>
<p><strong>Всероссийская робототехническая олимпиада и World Robot Olympiad (</strong>WRO) - это командная проектная олимпиада по программированию интеллектуальных робототехнических систем для школьников и студентов.</p>
<p><strong>Олимпиада НТИ</strong> - инженерные состязания школьников. Олимпиада проводится по нескольким профилям (предметам). Школьники могут выбрать один из 19 профилей олимпиады, среди которых &laquo;Интеллектуальные робототехнические системы&raquo;, &laquo;Водные робототехнические системы&raquo;, &laquo;Автономные транспортные системы&raquo;, &laquo;Беспилотные авиационные системы&raquo;. Олимпиада проводится в три этапа. Первый отборочный этап проходит в дистанционной форме. На втором этапе и в финале участники объединяются в команды и решают инженерно-конструкторские задачи.</p>
<p><strong>WorldSkills и JuniorSkills</strong>. WorldSkills &mdash; международное некоммерческое движение, целью которого является повышение престижа рабочих профессий и развитие навыков мастерства. JuniorSkills &mdash; программа ранней профориентации и основ профессиональной подготовки школьников.</p>
<p><strong>Соревнования RoboCup</strong> &mdash; первые в мире авторитетные робототехнические соревнования, история которых насчитывает более 20 лет &mdash; первый чемпионат состоялся в 1997 г. в Японии. По сути с RoboCup началась история робототехнических соревнований в мире. Важно отметить, что чемпионат RoboCup не привязан к конкретному производителю, оборудованию, бизнесу &mdash; это сообщество ученых, студентов, школьников, технологических предпринимателей.</p>
<p><strong>Соревнования EUROBOT</strong> - международные соревнования роботов, созданных студентами и школьниками. Соревнования, стартовавшие в 90-е годы во Франции и открыты для молодых людей со всего мира. Участники должны создать команду из двух или более человек, которая может быть сформирована студентами, независимыми клубами или некоммерческими организациями.</p>
<p><strong>Фестиваль &laquo;Робофинист&raquo;</strong> - проводится в Санкт-Петербурге ежегодно с2014 г. &nbsp;Соревнования проводятся по различным категориям: воздушные гонки, футбол роботов, следование по линии, кубок РТК, сумо роботов, свободная творческая категория и многие другие. Соревнования насчитывают примерно 50 разных регламентов, среди которых присутствуют и регламенты других известных соревнований, например WRO.</p>
<p><strong>&laquo;Роботраффик-Технион&raquo;</strong> - Всероссийский этап международных соревнований моделей робоавтомобилей. Проводится в Томске. Цель мероприятия -популяризация научно-технического творчества учащихся, создание условий для организации деятельности по созданию и программированию роботизированных систем, привлечение внимания молодежи к проблемам безопасности на дорогах.</p>
<p><strong>Турнир двух столиц</strong> - формат образовательных робототехнических соревнований: &laquo;Командно-личный, очно-заочный, олимпиадный и проектный одновременно. Соревнования проводятся по четырем заранее известным регламентам, а пятый предлагается только на турнире. На полигоне встречаются сразу два аппарата &mdash; роботы соревнуются не только со сложностями поля, но и друг с другом.</p>
<p><strong>Кубок РТК</strong> - организует ЦНИИ робототехники и технической кибернетики. Это соревнования на специальном испытательном полигоне, в ходе которых робот должен за наименьшее время преодолеть полосу препятствий, собрать цветные маячки и доставить их в соответствующие цветовые зоны, выполнить дополнительные задания. Управление роботом осуществляется дистанционно.</p>
<p><strong>Балтийский научно-инженерный конкурс</strong> - соревнование для школьников, которое проводится в Санкт-Петербурге. Мероприятие имеет 10 секций, одна из которых &mdash; робототехника. На конкурс могут быть представлены проекты, содержащие элемент научного открытия, законченные технические устройства.</p>
<p></p>
<p>Для того, чтобы успешно участвовать в робототехнических соревнованиях нужно:</p>
<ul>
<li>Подготовить оборудование (Робота) для участия в выбранном виде соревнования;</li>
<li>Понимать, как работает Робот и уметь его чинить, при необходимости;</li>
<li>Обеспечить резервное питание (дополнительные аккумуляторы /батарейки);</li>
<li>Подготовить дополнительные (сменные) датчики;</li>
<li>Уметь писать код для робота и уметь его отлаживать;</li>
<li>Подготовить дополнительно ремкомплект для Робота.</li>
</ul>
<p></p>
<p>Порядок подготовки модели робота, примеры соревновательных заданий, которые необходимо отрабатывать в процессе подготовки:</p>
<p></p>
<p>Этапы подготовки модели робота к соревнованиям:</p>
<p></p>
<ol>
<li>Изучить регламент (требования к конструкции и программе)</li>
<li>Составить план подготовки (обозначить крайний срок)</li>
<li>Подобрать идеи решения (свой/чужой опыт)</li>
<li>Проанализировать идеи решения заданий (плюсы и минусы)</li>
<li>Сделать эскиз, прогноз (схема реализации)</li>
<li>Сделать конструкцию (согласно заданию, регламенту):</li>
</ol>
<p>- составить прогноз движения, действия робота;</p>
<p>- составить программу (согласно регламенту);</p>
<p>- протестировать и выявить слабые места;</p>
<p>- исправить программу при необходимости, и отладить алгоритм программы.</p>
<p></p>
<p>Основные направления соревнований:</p>
<ol>
<li>Следование по траектории с различными усложнениями (инверсия, изгибы, разрывы).</li>

<li>Ориентирование на траектории (перекрестки, тупики).</li>
<li>Преодоление препятствий (горки, кочки, барьеры, объезд банки).</li>
<li>Транспортировка предметов (перетаскивание, подъем).</li>
<li>Сортировка предметов.</li>
</ol>
<p>&nbsp;</p>
<p>На завершающем этапе:</p>
<p>- выполнение дополнительных заданий;</p>
<p>- определение границ возможностей робота (например, максимальная высота подъема и т.д.)</p>
<p>&nbsp;</p>
<p>Упражнения на уровне программирования:</p>
<p>На подготовительном этапе:</p>
<p>- определить разницу между двумя программами.</p>
<p>На этапе тестирования программы:</p>
<p>- определить, что поменялось в поведении робота.</p>
<p>- найти ошибку в программе;</p>
<p>&nbsp;</p>
<p>На завершающем этапе:</p>
<p>- выполнение дополнительных заданий;</p>
<p>- определение границ возможностей, &laquo;гибкости&raquo; программы (например, максимальное количество программ работы с кубиками и т.д.).</p>
<p>&nbsp;</p>
<p>Для усложнения уровня подготовки следует:</p>
<p>1) использовать имеющиеся элементы поля:</p>
<p>- дополнительные шарики/карточки;</p>
<p>- дополнительные цилиндры;</p>
<p>- дополнительные кубики/столбики.</p>
<p>2) использовать другие элементы, например, добавление дополнительных перемещаемых элементов (шарики, цилиндры, кубики и т.п.).</p>
                    </AccordionItem>
                </Accordion>
            </div>
        )
    }
}

export default BaseTheory