import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P8Disputes extends Component {
    render() {
        return (
            <AccordionItem title={<li>Разрешение споров</li>}>
                <div>
                    <ol>
                        <li>До обращения в суд с иском по спорам, возникающим из отношений между Сторонами,
                            обязательным является предъявление претензии (письменного предложения о добровольном
                            урегулировании спора).</li>

                        <li>Получатель претензии в течение 10 календарных дней со дня получения претензии,
                            письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</li>

                        <li>При не достижении соглашения, спор будет передан на рассмотрение в Арбитражный
                            суд Санкт-Петербурга и Ленинградской области в соответствии с действующим законодательством
                            Российской Федерации.</li>

                        <li>К настоящей Политике конфиденциальности и отношениям между Пользователем и ООО «Проф-ИТ»
                            применяется действующее законодательство Российской Федерации.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P8Disputes;