import React, {Component} from 'react';

class Annotation extends Component {
    render() {
        let { data } = this.props;

        return (
            <div className={"annotation"}>
                <div className={"block"}>
                    <h3>Цель</h3>
                    <ul>
                        { data.goal.map( (item, i) => <li key={i}>{ item }</li> ) }
                    </ul>
                </div>

                <div className={"block"}>
                    <h3>Задача</h3>
                    <p>{ data.task }</p>
                </div>

                <div className={"block"}>
                    <h3>Используемые функции</h3>
                    <ul>
                        { data.functions.map( (item, i) => <li key={i}>{ item }</li> ) }
                    </ul>
                </div>

                <div className={"block"}>
                    <h3>Приобретаемые навыки</h3>
                    <ul>
                        { data.skills.map( (item, i) => <li key={i}>{ item }</li> ) }
                    </ul>
                </div>
            </div>
        )
    }
}

export default Annotation