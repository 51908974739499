import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class LineSensor extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль датчика линии</h4>

                <p>
                    Модуль датчика линии представляет собой печатную плату с установленным
                    оптическим датчиком предназначенным для обнаружения препятствий в виде линий
                    (белых или чёрных) на основании определения отражающей способности поверхности.
                    Внешний вид модуля датчика линии приведен на рисунке 2.8. Датчик является аналоговым,
                    выходной сигнал с сенсора прямо пропорционален количеству отраженного от поверхности
                    света.
                </p>

                <p>
                    Области применения модуля датчика линии:<br/>
                    - определение факта наличия предмета известного цвета рядом с модулем;<br/>
                    - дифференциация предметов с разными по цвету поверхностями;<br/>
                    - в Конструкторе используется для перемещения робота по линии.
                </p>

                <p>
                    В модуле датчика линии установлена оптопара TCRT5000, излучающая свет в инфракрасном
                    спектре и принимающая отражённый сигнал. В зависимости от типа и цвета поверхности
                    меняется интенсивность отраженного сигнала. По этой интенсивности можно судить об
                    оттенках цвета поверхности.
                </p>

                <Image src={"/Constructor/line-sensor.png"} name={"Внешний вид модуля датчика линии"} number={"2.8"}/>

                <p>
                    Основные технические характеристики модуля датчика линии приведены в таблице 2.7.
                </p>

                <Table name={"Основные технические характеристики модуля датчика линии"}
                       number={"2.7"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Минимальное расстояние до поверхности", "2,5 мм"],
                           ["Встроенный фильтр солнечного света", "имеется"],
                           ["Максимальный прямой ток светодиода", "60 мА"],
                           ["Падение напряжения на светодиоде при токе 60 мА", "от 1,25 до 1.5 В"],
                           ["Номинальное значение длины волны излучения светодиода", "950 нм"],
                           ["Максимальное напряжение коллектор-эмиттер", "70 В"],
                           ["Максимальное напряжение эмиттер-коллектор", "5 В"],
                           ["Максимальный ток коллектора", "100 мА"],
                           ["Температура эксплуатации", "от -25 до +100°C"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Максимальное расстояние до поверхности", "12 мм"],
                           ["Номинальное значение напряжения электропитания", "5 В"],
                           ["Среднее значение тока потребления", "1 мА"],
                       ]}/>
            </div>
        )
    }
}

export default LineSensor