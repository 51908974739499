import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P4Obligations extends Component {
    render() {
        return (
            <AccordionItem title={<li>Права и обязанности сторон</li>}>
                <div>
                    <ol>
                        <li>Оператор Сайта вправе:
                            <ol>
                                <li>Устанавливать любые ограничения в использовании Сайта, изменять правила пользования Сайтом,
                                    а также изменять содержание Сайта.</li>

                                <li>Ограничить доступ к Сайту или к частям сайта в случае нарушения Пользователем условий
                                    настоящего Пользовательского соглашения.</li>

                                <li>По своему собственному усмотрению приостанавливать, ограничивать или прекращать доступ
                                    Пользователя ко всем или к любому из разделов или сервисов Сайта.</li>

                                <li>В любое время изменять настоящее Пользовательское соглашение в одностороннем порядке,
                                    опубликовав на Сайте новую редакцию Пользовательского соглашения, без получения согласия
                                    Пользователя.</li>
                            </ol>
                        </li>
                        <li>Пользователь вправе:
                            <ol>
                                <li>Получить доступ к использованию Сайта в ознакомительных и подобных целях, а также к
                                    онлайн сервисам, представленным на Сайте.</li>

                                <li>Задавать любые вопросы, относящиеся к услугам, представленным на Сайте и иной информации,
                                    размещенной на Сайте, используя раздел Сайта «Контакты».</li>

                                <li>Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не
                                    запрещенных законодательством Российской Федерации.</li>
                            </ol>
                        </li>
                        <li>Пользователь Сайта обязуется:
                            <ol>
                                <li>Соблюдать имущественные и неимущественные права Оператора Сайта, авторов и иных
                                    правообладателей при использовании Сайта.</li>

                                <li>Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную
                                    работу Сайта.</li>

                                <li>Не распространять с использованием Сайта любую конфиденциальную и охраняемую
                                    законодательством Российской Федерации информацию о Операторе Сайта, физических
                                    или юридических лицах.</li>

                                <li>Избегать любых действий, в результате которых может быть нарушена конфиденциальность
                                    информации, охраняемой законодательством Российской Федерации.</li>

                                <li>
                                    Не использовать Сайт для распространения информации рекламного характера.
                                    Несанкционированно собирать и хранить персональные данные других лиц.
                                </li>

                                <li>Не размещать ссылки на ресурсы сети Интернет, содержание которых противоречит
                                    действующему законодательству Российской Федерации;</li>

                                <li>Не использовать сервисы Сайта с целью:
                                    <ol>
                                        <li>Загрузки контента, который является незаконным, нарушает любые права третьих
                                            лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по
                                            расовому, национальному, половому, религиозному, социальному признакам; содержит
                                            недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций,
                                            органов власти; побуждения к совершению противоправных действий, а также содействия
                                            лицам, действия которых направлены на нарушение ограничений и запретов, действующих
                                            на территории Российской Федерации.</li>

                                        <li>Нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой
                                            форме.</li>

                                        <li>Нарушения авторских прав.</li>

                                        <li>Представления себя за другого человека или представителя организации и (или)
                                            сообщества без достаточных на то прав, в том числе за Администрацию
                                            Сайта.</li>

                                        <li>Введения в заблуждение относительно свойств и характеристик какой-либо
                                            услуги, размещенной на Сайте.</li>

                                        <li>Некорректного сравнения услуги, а также формирования негативного отношения к
                                            лицам, пользующимся/не пользующимися определенными услугами, или осуждения
                                            таких лиц.</li>
                                    </ol>
                                </li>

                                <li>При цитировании материалов Сайта, включая охраняемые авторские произведения,
                                    указывать ссылку на Сайт.</li>
                            </ol>
                        </li>
                        <li>Пользователю запрещается:
                            <ol>
                                <li>Нарушать надлежащее функционирование Сайта.</li>

                                <li>Любым способом обходить навигационную структуру Сайта для получения или попытки
                                    получения любой информации, документов или материалов любыми средствами, которые
                                    специально не представлены сервисами Сайта.</li>

                                <li>Несанкционированный доступ к функциям Сайта, любым другим системам или сетям,
                                    относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте.</li>

                                <li>Нарушать систему безопасности или аутентификации на Сайте.</li>

                                <li>Отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе
                                    Сайта.</li>

                                <li>Использовать Сайт в любых целях, запрещенных законодательством Российской Федерации,
                                    а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей
                                    права Оператора Сайта или других лиц.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P4Obligations;