import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P4Goals extends Component {
    render() {
        return (
            <AccordionItem title={<li>Цели сбора персональной информации пользователя</li>}>
                <div>
                    ООО «Проф-ИТ» может использовать персональные данные Пользователя Сайта в целях:

                    <ol>
                        <li>Идентификации Пользователя, для оформления заказа и (или) заключения договора на оказание
                            услуг дистанционным способом или их оплаты. Подтверждения достоверности и полноты персональных
                            данных, предоставленных Пользователем.</li>

                        <li>Предоставления Пользователю доступа к персонализированной информации.</li>

                        <li>Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
                            касающихся использования Сайта, оказания услуг, оплаты, обработки запросов и заявок от
                            Пользователя.</li>

                        <li>Создания учетной записи для онлайн-заказа услуг или их оплаты, если Пользователь дал согласие
                            на создание учетной записи путем прохождения процедуры регистрации на Сайте;</li>

                        <li>Уведомления Пользователя Сайта о состоянии онлайн-заказа услуги или их оплаты.</li>

                        <li>Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания
                            (возврата) платежа;</li>

                        <li>Предоставления Пользователю клиентской и технической поддержки при возникновении проблем,
                            связанных с использованием Сайта.</li>

                        <li>Предоставления Пользователю, с его согласия, обновлений ассортимента услуг, специальных
                            предложений, информации о ценах, новостной рассылки и иных сведений от имени ООО «Проф-ИТ»
                            или от имени партнеров ООО «Проф-ИТ».</li>

                        <li>Осуществления рекламной деятельности.</li>

                        <li>Предоставления доступа Пользователю на сайты или сервисы партнеров ООО «Проф-ИТ» с целью
                            получения продуктов, обновлений и услуг.</li>

                        <li>Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
                            мошенничества.</li>

                        <li>Улучшения качества работы Сайта, удобства его использования, разработки новых проектов;</li>

                        <li>Проведения статистических и иных исследований на основе обезличенных данных;</li>

                        <li>Выполнение требований законодательства по определению порядка обработки и защиты персональных
                            данных граждан, являющихся посетителем или зарегистрированным пользователем Сайта.</li>

                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P4Goals;