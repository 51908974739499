import React, {Component} from 'react';
import Table from "../common/Table";

class PowerSupply extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Блок питания</h4>

                <p>
                    Блок питания предназначен для зарядки аккумуляторов, которые размещены в
                    аккумуляторном блоке. Блок питания подключается к соединителю на задней
                    панели робота.
                </p>

                <p>
                    Основные технические характеристики блока питания приведены в таблице 2.23.
                </p>

                <Table name={"Основные технические характеристики блока питания"}
                       number={"2.23"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Параметры входной цепи", "напряжение от 100 до 240 В переменного тока, частота от 50 до 60 Гц"],
                           ["Параметры выходной цепи", "номинальное напряжение 12 В, номинальный ток 1 A"],
                           ["Ток разряда максимальный", "13 А"],
                           ["Выходной соединитель", "5,5 х 2,1 мм"],
                           ["Защита", "OVP (защита от перенапряжения);\n" +
                           "OCP (защита от повышенного токопотребления);\n" +
                           "SCP (защита от короткого замыкания)\n"],
                           ["Вес, не более", "110 г"],
                           ["Габариты", "75 х 65 мм"],
                           ["Эффективность", "от 80 до 85 %"],
                           ["Тип выхода", "постоянный ток"],
                           ["Подключение", "штепсельное соединение"],
                           ["Рабочая температура", "от -25 до +60℃"],
                           ["Температура хранения", "от -45 до +80℃"],
                       ]}/>
            </div>
        )
    }
}

export default PowerSupply