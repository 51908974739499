import React, {Component} from 'react';
import {Link} from "react-router-dom";

class Categories extends Component {
    render() {
        let { categories, selected } = this.props;

        return (
            <div className={"categories"}>
                {
                    categories.map(category =>
                        <Link to={`/products/${category.id}`} key={category.id}>
                            <button title={category.name}
                                    className={"btn " +
                                    (+selected === category.id
                                        ? "btn-light" : "btn-solid")}>
                                { category.name }
                            </button>
                        </Link>
                    )
                }
                <Link to={`/products`}>
                    <button title={"Все"}
                            className={"btn " +
                            (!selected ? "btn-light" : "btn-solid")}>
                        Все
                    </button>
                </Link>
            </div>
        )
    }
}

export default Categories