import React, { Component } from 'react';
import {getCategoriesList} from "../../../../assets/axios/categories";

class Navbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px' },
            categoryId: ''
        }

        this.openNav = this.openNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    componentWillMount() {
        getCategoriesList(response => {
            this.setState({
                categoryId: response.data[0].id
            })
        }, () => {});

        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px' } })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px' } })
        }
    }

    openNav() {
        this.setState({ navClose: { right: '0px' } })
    }
    closeNav() {
        this.setState({ navClose: { right: '-410px' } })
    }

    render() {
        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav">
                        <div className="toggle-nav" onClick={this.openNav}>
                            <i className="fa fa-bars sidebar-bar"/>
                        </div>
                        <ul className="nav-menu" style={this.state.navClose}>
                            <li className="back-btn" onClick={this.closeNav}>
                                <div className="mobile-back text-right">
                                    <span>Назад</span>
                                    <i className="fa fa-angle-right pl-2" aria-hidden="true"/>
                                </div>
                            </li>

                            <li>
                                <a href={`/products/${this.state.categoryId}`}
                                   className="nav-link">Продукты</a>
                            </li>

                            <li>
                                <a href="/education" className="nav-link">Обучение</a>
                            </li>

                            <li>
                                <a href="/software" className="nav-link">Загрузка ПО</a>
                            </li>

                            <li>
                                <a href="/contacts" className="nav-link">Контакты</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navbar;