import React from 'react';
import {Link} from 'react-router-dom'

function Logo(props) {

    return <Link to={`/`} >
                <img src={`/assets/images/common/logo/${props.logo}`}
                     alt="OMEGABOT"
                     className={`img-fluid ${props.className || ''}`} />
            </Link>;
}

export default Logo;