import React, {Component} from 'react';

class Features extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Отличительные особенности предлагаемого решения</h4>
                <p>
                    Примененные в Конструкторе решения обладают следующими отличительными особенностями:<br/>
                    - эргономичность;<br/>
                    - надежность;<br/>
                    - универсальность;<br/>
                    - современность;<br/>
                    - безопасность.
                </p>
                <p>
                    <strong>Эргономичность</strong><br/>
                    Сменные модули Конструктора фиксируются на платформе с помощью магнитных креплений.
                    При этом, магниты крепления не являются токоведущими контактами, что исключает потерю
                    работоспособности вследствие окисления поверхностей магнитных креплений.
                </p>
                <p>
                    <strong>Надежность</strong><br/>
                    Основу колесной платформы составляет металлический каркас, который обеспечивает общую
                    жесткость конструкции и надежность крепления мотор-колес.
                </p>
                <p>
                    <strong>Универсальность</strong><br/>
                    Использование в основе программируемого контроллера архитектуры Arduino, открытых
                    универсальных интерфейсов и стандартных соединителей, подключаемых модулей, обеспечивает
                    совместимость Конструктора с другими внешними модулями сторонних производителей, что
                    существенно увеличивает вариативность функционала Конструктора. Для конструктивного
                    многообразия предусмотрена возможность совместного использования Конструктора с деталями
                    конструктора "LEGO Техник".
                </p>
                <p>
                    <strong>Современность</strong><br/>
                    Контроллер Конструктора обеспечивает возможность непосредственного сопряжения
                    (прямого соединения) с одноплатным микрокомпьютером для выполнения задач в области
                    искусственного интеллекта (машинное зрение, нейросетевые технологии и пр.).
                </p>
                <p>
                    <strong>Безопасность</strong><br/>
                    Колесная платформа, составляющая конструктивную основу Конструктора, выполнена из
                    ABS-пластика, что обеспечивает более высокую прочность и повышенную безопасность при
                    проведении занятий с детьми. Металлические элементы каркаса полностью скрыты конструкцией
                    платформы.
                </p>
                <p>
                    Программирование Конструктора производится:<br/>
                    - в визуальной среде блочного типа, рассчитанной на обучаемых, начинающих постигать
                    азы составления алгоритмов и составлять простейшие программы работы робота из готовых
                    блоков <strong>OmegabotIDE;</strong><br/>
                    - на языке программирования <strong>С++</strong>, предоставляющем доступ к огромной базе библиотек и
                    позволяющем реализовывать собственные алгоритмы управления роботом любой сложности;<br/>
                    - на языке программирования <strong>Python</strong>.
                </p>
            </div>
        )
    }
}

export default Features