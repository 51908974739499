import React, {Component} from 'react';
import {SERVER_HOST} from "../../../../../../../constants/axiosURLs";
import {SYMBOL} from "../../../../../../../constants";

class List extends Component {
    render() {
        let {items} = this.props;

        return (
            items.map((item) =>
                <div className="row product-order-detail" key={item.id}>
                    <div className="col-sm-3 col-4">
                        <img src={SERVER_HOST + (item.product.pictures[0].url || '/assets/images/common/image-stub.jpg')}
                             alt="" className="img-fluid"/>
                    </div>
                    <div className="col-sm-3 col-4 order_detail">
                        <div>
                            <h4>Продукт</h4>
                            <h5>{item.product.name}</h5>
                        </div>
                    </div>
                    <div className="col-sm-3 col-4 order_detail">
                        <div>
                            <h4>Кол-во</h4>
                            <h5>{item.count}</h5>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 order_detail price">
                        <div>
                            <h4>Цена (шт.)</h4>
                            <h5>{item.product.price} {SYMBOL}</h5>
                        </div>
                    </div>
                </div>
            )
        )
    }
}

export default List