import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P6Obligations extends Component {
    render() {
        return (
            <AccordionItem title={<li>Обязательства сторон</li>}>
                <div>
                    <ol>
                        <li>Пользователь обязан:
                            <ol>
                                <li>Предоставить достоверные персональные данные, необходимые для пользования Сайтом.</li>

                                <li>Обновлять, дополнять предоставленную информацию о персональных данных в случае
                                    изменения данной информации.</li>
                            </ol>
                        </li>
                        <li>Оператор обязан:
                            <ol>
                                <li>Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей
                                    Политики конфиденциальности.</li>

                                <li>Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного
                                    письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование,
                                    либо разглашение иными возможными способами переданных персональных данных Пользователя, за
                                    исключением п. 5.2. и п. 5.3. настоящей Политики Конфиденциальности.</li>

                                <li>Принимать меры предосторожности для защиты конфиденциальности персональных данных
                                    Пользователя согласно порядку, обычно используемого для защиты такого рода информации
                                    в существующем деловом обороте.</li>

                                <li>Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю,
                                    с момента обращения или запроса Пользователя или его законного представителя либо
                                    уполномоченного органа по защите прав субъектов персональных данных на период проверки,
                                    в случае выявления недостоверных персональных данных или неправомерных действий.</li>
                            </ol>
                        </li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P6Obligations;