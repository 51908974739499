import React, { Component } from 'react';
import AdvantageBlock from "./AdvantageBlock";

class Advantages extends Component {
    render (){
        return (
            <section className="section-b-space blog-bg">
                <h2 className={"text-center header"}>Преимущества</h2>
                <div className="advantages container">
                    <div className="row light-list">
                        <AdvantageBlock name={"Совместимость с Lego"}
                                        image={"lego-compatibility.png"} />

                        <AdvantageBlock name={"Участие в соревнованиях"}
                                        image={"participation-in-competitions.png"} />

                        <AdvantageBlock name={"Возможности техзрения"}
                                        image={"vision-possibilities.png"} />

                        <AdvantageBlock name={"Низкий порог вхождения"}
                                        image={"low-entry-threshold.png"} />
                    </div>
                </div>
            </section>
        )
    }
}

export default Advantages;