import React, {Component} from "react";
import {Accordion, AccordionItem} from 'react-light-accordion';
import PageContent from "../../common/Page/PageContent";

class Robot extends Component {
    render() {
        return (
            <PageContent>
                <div className={"container-fluid accordion-container"}>
                    <Accordion atomic={true}>

                        <AccordionItem title="Как правильно подключить робота к компьютеру?">
                            <div>
                                Подключите плату на роботе к компьютеру с помощью кабеля для
                                программирования, выключив при этом питание. Подключайте провода
                                и модули к Омегаботу только тогда, когда он выключен и не подключен
                                к компьютеру.
                            </div>
                        </AccordionItem>

                        <AccordionItem title="Как правильно прошить плату?">
                            <div>
                                Отсоедините плату от робота и подключите ее к компьютеру. Загрузите
                                скетч в плату, а затем отключите ее от компьютера и снова подсоедините
                                к роботу. Подключите к Омегаботу необходимые для работы программы модули,
                                включите его, и он начнет выполнять действия. Не обязательно отсоединять
                                плату от робота во время ее прошивки, однако в таком случае следите
                                за тем, чтобы робот был выключен во время загрузки скетча.
                            </div>
                        </AccordionItem>

                        <AccordionItem title="Куда подсоединять провода, выходящие из робота?">
                            <div>
                                От робота выходят провода двух видов. К двойным шлейфам с более крупными
                                прозрачными разъемами следует подключать моторы. Для правильной работы
                                шлейфы должны быть подсоединены к разъемам зеркально (левый провод к правому
                                разъему на плате, правый - к левому). Тройные шлейфы (они есть только в расширенном
                                наборе) предназначены для подключения энкодеров к разъемам D2 и D3 на плате.
                            </div>
                        </AccordionItem>

                        <AccordionItem title="Как определить уровень заряда в роботе?">
                            <div>
                                По мере того, как робот разряжается, его колеса начинают крутиться
                                медленнее. В среднем робот может работать в режиме непрерывного движения более
                                двух часов.
                            </div>
                        </AccordionItem>

                        <AccordionItem title="Что делать, если робот неожиданно выключился во время выполнения программы?">
                            <div>
                                Если робот имел достаточный уровень заряда, то неожиданное выключение может
                                произойти вследствие чрезмерной нагрузки (например, если робот на полной
                                скорости резко меняет направление движения), так как происходит короткое замыкание.
                                В таком случае необходимо выключить робота на 5 секунд, а затем снова включить,
                                после чего он возобновит свою работу.
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
            </PageContent>
        );
    }
}

export default Robot;