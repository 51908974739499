import React, {Component} from 'react';

class PageTitles extends Component {
    render() {
        return (
            <section>
                <div className={"row page-titles titles"}>
                    {
                        this.props.titles.map(title =>
                            <div key={title.id}
                                 className={`col-lg-4 
                                 ${title.id ? `order-lg-${title.id}` : ''} 
                                 col-sm-6 
                                 ${title.order ? `order-sm-${title.order}` : ''} 
                                 block
                                 ${title.name && title.body ? 'scroll-block': ''}
                                 ${title.className || ''}`}
                                 data-link={`${this.props.linkAnchor ? (`#${this.props.linkAnchor}${title.id}`) : ''}`}>

                                {
                                    title.name
                                        ? <>
                                            <div className={"numeration"}>
                                                <div className={"number"}>0{title.id}</div>
                                                <div className={"decoration"}> </div>
                                            </div>

                                            <div>
                                                <div className={"name"}>{title.name}</div>
                                            </div>
                                        </>
                                        : null
                                }
                            </div>
                        )
                    }
                </div>
            </section>
        )
    }
}

export default PageTitles