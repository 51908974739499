import React, {Component} from "react";
import Helmet from "react-helmet/es/Helmet";
import PageTitles from "../../common/Page/PageTitles";
import {header} from "../../../constants/Pages/FooterPages/privacyPolicy";
import Text from "./Text";

class PrivacyPolicy extends Component {
    render() {
        return (
            <>
                <Helmet><title>Политика конфиденциальности</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className={"container privacy-policy section-b-space"}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <h2 className={"text-center header"}>Политика конфиденциальности</h2>
                                <PageTitles titles={header}/>
                                <Text/>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default PrivacyPolicy;