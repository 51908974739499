import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P7Violation extends Component {
    render() {
        return (
            <AccordionItem title={<li>Нарушение условий пользовательского соглашения</li>}>
                <div>

                    <ol>
                        <li>Оператор Сайта вправе раскрыть любую собранную о Пользователе Сайта информацию, если
                            раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного
                            использования Сайта либо для установления (идентификации) Пользователя, который может
                            нарушать или вмешиваться в права Оператора Сайта или в права других Пользователей
                            Сайта.</li>

                        <li>Оператор Сайта имеет право раскрыть любую информацию о Пользователе, которую посчитает
                            необходимой для выполнения положений действующего законодательства или судебных решений,
                            обеспечения выполнения условий настоящего Соглашения, защиты прав Пользователей.</li>

                        <li>Оператор сайта имеет право раскрыть информацию о Пользователе, если действующее
                            законодательство Российской Федерации требует или разрешает такое раскрытие.</li>

                        <li>Оператор Сайта вправе без предварительного уведомления Пользователя прекратить доступ к
                            Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах
                            условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине
                            технической неполадки.</li>

                        <li>Оператор Сайта не несет ответственности перед Пользователем или третьими лицами за
                            прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего
                            Соглашения или иного документа, содержащего условия пользования Сайтом.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P7Violation;