import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class BaseWithDistanceSensor extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Расширенная основа с датчиками расстояния</h4>

                <p>
                    Расширенная основа представляет собой деталь из АВС пластика с установленными
                    датчиками расстояния. Внешний вид расширенной основы с датчиками расстояния
                    приведен на рисунке 2.7. Некоторые из отверстий имеют встроенные магниты для
                    фиксации модулей. С передней и боковых сторон основы расположены датчики расстояния.
                </p>

                <p>
                    Датчики расстояния предназначены для определения расстояния до объектов и состоят
                    из комбинации устройств: датчика положения, инфракрасного светодиода и схемы
                    обработки сигнала.
                </p>

                <p>
                    Уровень напряжения на выходе датчиков прямо пропорционален расстоянию до объекта.
                    Датчики расстояния возможно использовать в качестве датчиков приближения.
                </p>

                <Image src={"/Constructor/base-with-distance-sensor.png"} name={"Внешний вид расширенной основы с датчиками расстояния"} number={"2.7"}/>

                <p>
                    Основные технические характеристики датчика расстояния приведены в таблице 2.6.
                </p>

                <Table name={"Основные технические характеристики датчика расстояния"}
                       number={"2.6"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Диапазон измерений расстояния", "от 10 до 80 см"],
                           ["Выход", "аналогового типа"],
                           ["Номинальное значение тока потребления", "30 мА"],
                           ["Вес, не более", "1,17 г"],
                           ["Размер", "29,5 х 13 х 13,5 мм"],
                           ["Напряжение электропитания", "от 4,5 до 5,5 В"],
                       ]}/>
            </div>
        )
    }
}

export default BaseWithDistanceSensor