import React, {Component} from "react";

class SoftwareBlock extends Component {
    render() {
        return (
            <section className={"software section-b-space"}>
                <div className={"container"}>
                    <div className={"description col-lg-5"}>
                        <h3><strong>Визуальная среда программирования</strong></h3>
                        <p>
                            Управляйте поведением робота, составляя программу из
                            простых блоков <strong>без погружения в код</strong>
                        </p>
                        <a href={"/software#software-downloads"} className={"scroll"}>
                            <button type="button"
                                className="btn btn-solid">скачать</button>
                        </a>
                    </div>
                    <div className={"mockup col-lg-5"}>
                        <img alt={"OmegaBot IDE"}
                             className={"img-fluid"}
                             src={`/assets/images/common/soft-mockup.png`}/>
                    </div>
                </div>
            </section>
        );
    }
}

export default SoftwareBlock;