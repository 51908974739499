import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P8Disputes extends Component {
    render() {
        return (
            <AccordionItem title={<li>Разрешение споров</li>}>
                <div>

                    <ol>
                        <li>В случае возникновения любых разногласий или споров между Сторонами Настоящего
                            Соглашения обязательным условием до обращения в суд является предъявление другой
                            Стороне письменной претензии (письменного предложения о добровольном урегулировании
                            спора).</li>

                        <li>Получатель претензии в течение 10 календарных дней со дня ее получения, письменно
                            уведомляет Сторону предоставившую претензию о результатах рассмотрения претензии.</li>

                        <li>При невозможности разрешить спор в добровольном порядке любая из Сторон вправе
                            обратиться в суд за защитой своих прав, которые предоставлены им действующим
                            законодательством Российской Федерации.</li>

                        <li>При нарушении условий данного пункта любой иск или основания для иска погашаются исковой
                            давностью.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P8Disputes;