import React, {Component} from 'react';
import {details} from "../../../constants/Pages/FooterPages/support";
import {getCategoriesList} from "../../../assets/axios/categories";
import PageContent from "../../common/Page/PageContent";

class Details extends Component {
    state = {
        categoryId: ''
    }

    componentDidMount() {
        getCategoriesList(response => {
            this.setState({
                categoryId: response.data[0].id
            })
        }, () => {});
    }

    render() {
        return (
            <PageContent blocks={details(this.state.categoryId)} />
        )
    }
}

export default Details