import React from 'react';
import {Link} from 'react-router-dom'
import {SERVER_HOST} from "../../../../../constants/axiosURLs";
import {SYMBOL} from "../../../../../constants";
import {connect} from "react-redux";
import {removeFromCart} from "../../../../../actions";

const Item = ({item, removeFromCart}) => (
    <li>
        <div className="media">
            <Link to={`/product/${item.id}`}>
                <img alt="" className="mr-3"
                     src={SERVER_HOST + (item.pictures.length ? item.pictures[0].url : '/assets/images/common/image-stub.jpg')} />
            </Link>
            <div className="media-body">
                <Link to={`/product/${item.id}`}>
                    <h4>{ item.name }</h4>
                </Link>
                <h4>
                    <span>{item.qty} x {item.price - (item.price * item.discount / 100)} {SYMBOL}</span>
                </h4>
            </div>
        </div>

        <div className="close-circle">
            <span onClick={() => removeFromCart(item)}>
                <i className="fa fa-times" aria-hidden="true"/>
            </span>
        </div>
    </li>
)

export default connect(() => ({}), {removeFromCart}) (Item);
