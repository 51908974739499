import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P2GeneralProvisions extends Component {
    render() {
        return (
            <AccordionItem title={<li>Общие положения</li>}>
                <div>

                    <ol>
                        <li>Настоящее Пользовательское соглашение в сети интернет по
                            адресу <a href={"/"}>http://omegabot.ru</a> и является внутренним документом
                            Оператора Сайта.</li>

                        <li>Сайт является собственностью Общества с Ограниченной Ответственностью «Проф-ИТ».</li>

                        <li>Настоящее Соглашение регулирует отношения между Оператором Сайта и Пользователем Сайта.
                            Оператор Сайта предоставляет Пользователю право использовать Сайт и сервисы Сайта с
                            помощью персональных компьютеров, ноутбуков и мобильных и иных устройств.</li>

                        <li>Оператор Сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты
                            настоящего Соглашения без уведомления Пользователя.</li>

                        <li>Продолжение использования Сайта Пользователем означает принятие условий Соглашения и изменений
                            к нему, внесенных в настоящее Соглашение.</li>

                        <li>Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие
                            изменений в нем.</li>

                        <li>Соглашение вступает в силу с момента заключения с Оператором Сайта настоящего Пользовательского
                            соглашения путем акцепта  оферты. Пользователь считается принявшим условия Пользовательского
                            соглашения в полном объеме, без каких-либо оговорок и исключений.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P2GeneralProvisions;