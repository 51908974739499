import React, { Component } from 'react';
import TopBar from "./TopBar";
import Menu from "./Menu";

class Header extends Component {
    componentDidMount() {
        setTimeout(function() {
            document.querySelector(".loader-wrapper").style.display = "none";
        }, 1000);
    }

    render() {
        return (
            <div className={this.props.className || ""}>
                    <TopBar/>
                    <Menu className={this.props.className}/>
            </div>
        )
    }
}

export default Header