import React, {Component} from 'react';

class Windows extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Инструкция по установке ПО OmegaBot IDE в ОС Windows</h4>

                <p>
                    Установка и запуск ПО OmegaBot IDE в ОС Windows производится в следующей
                    последовательности:
                </p>

                <p>
                    <strong>Шаг 1</strong><br/>
                    Зайти на сайт <a href={"/"}>https://omegabot.ru</a>.
                </p>

                <p>
                    <strong>Шаг 2</strong><br/>
                    Переход на страницу загрузки ПО возможен следующими альтернативными способами:<br/>
                    - нажать ссылку <strong>ЗАГРУЗКА ПО</strong> в шапке сайта;<br/>
                    - пролистать вниз открытую страницу до раздела <strong>Визуальная среда программирования</strong> и
                    нажать кнопку <strong>СКАЧАТЬ</strong>;<br/>
                    - перейти на страницу загрузки по адресу <a href={"/software"}>https://omegabot.ru/software</a>.
                </p>

                <p>
                    <strong>Шаг 3</strong><br/>
                    Загрузка ПО производится следующими альтернативными способами:<br/>
                    - нажать кнопку <strong>СКАЧАТЬ</strong> в начале страницы;<br/>
                    - пролистать вниз открытую страницу и нажать кнопку <strong>СКАЧАТЬ</strong> под
                    надписью <strong>для Windows</strong>.
                </p>

                <p>
                    <strong>Шаг 4</strong><br/>
                    Открыть скачанный архив и распаковать файлы в любую удобную директорию.
                </p>

                <p>
                    <strong>Шаг 5</strong><br/>
                    Запустить установщик ПО (файл <strong>OmegaBot_IDE-setup.exe</strong>).
                </p>

                <p>
                    <strong>Шаг 6</strong><br/>
                    Выбрать язык установки и нажать кнопку <strong>ОК</strong>.
                </p>

                <p>
                    <strong>Шаг 7</strong><br/>
                    Если на компьютере не было установлено ПО <strong>Arduino IDE</strong>, то будет предложено его
                    установить, для этого необходимо нажать кнопку <strong>Да</strong>, далее производится установка
                    ПО <strong>Arduino IDE</strong> (в архиве с установщиком также находится папка с установщиком
                    ПО <strong>Arduino IDE</strong>).<br/>
                    При нажатии кнопки <strong>Нет</strong> можно установить ПО <strong>Arduino IDE</strong> самостоятельно
                    или повторно воспользоваться установщиком ПО <strong>OmegaBot IDE</strong>.
                </p>

                <p>
                    <strong>Шаг 8</strong><br/>
                    После установки ПО <strong>Arduino IDE</strong> будет продолжена установка
                    ПО <strong>OmegaBot IDE</strong>.
                </p>

                <p>
                    <strong>Шаг 9</strong><br/>
                    В окне выбора компонентов установки ПО <strong>OmegaBot IDE</strong> необходимо
                    выбрать один из следующих способов установки:<br/>
                    - выбрать вариант <strong>Полная установка</strong> и нажать кнопку <strong>Далее</strong>;
                    - или выбрать все пункты и нажать кнопку <strong>Далее</strong>.
                </p>

                <p>
                    <strong>Шаг 10</strong><br/>
                    Нажать кнопку <strong>Установить</strong> и дождаться окончания установки ПО, после чего нажать
                    кнопку <strong>Завершить</strong>.
                </p>

                <p>
                    <strong>Шаг 11</strong><br/>
                    Запустите на рабочем столе (или из меню <strong>Пуск</strong>)
                    ПО <strong>Arduino IDE</strong>.
                </p>

                <p>
                    <strong>Шаг 12</strong><br/>
                    Для перехода в среду <strong>OmegaBot IDE</strong> открыть в
                    ПО <strong>Arduino IDE</strong> вкладку <strong>Инструменты – OmegaBot_IDE</strong>.
                </p>

                <p>
                    <strong>Шаг 13</strong><br/>
                    В поле авторизации ввести ключ продукта (серийный номер) и нажать кнопку <strong>ОК</strong>.
                </p>

                <p>
                    ПО OmegaBot_IDE готово к работе.
                </p>
            </div>
        )
    }
}

export default Windows