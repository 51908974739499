import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P5Usage extends Component {
    render() {
        return (
            <AccordionItem title={<li>Использование сайта</li>}>
                <div>
                    <ol>
                        <li>Сайт и информация с Сайта не может быть скопирована, опубликована, воспроизведена,
                            передана или распространена любым способом, полностью или частично, без предварительного
                            письменного согласия Оператора Сайта.</li>

                        <li>Сайт защищен авторским правом, а также другими правами, связанными с интеллектуальной
                            собственностью, и законодательством о недобросовестной конкуренции.</li>

                        <li>Онлайн-заказ на Сайте не требует регистрации на Сайте.</li>

                        <li>Пользователь несет персональную ответственность за сохранение конфиденциальности
                            информации, а также за всю без исключения деятельность, которая ведётся от имени
                            Пользователя.</li>

                        <li>Настоящее Соглашение распространяет свое действия на все дополнительные положения и
                            условия по оказанию услуг, предоставляемых на Сайте.</li>

                        <li>Информация, размещаемая на Сайте, не должна истолковываться как изменение настоящего
                            Соглашения.</li>

                        <li>Администрация сайта имеет право в любое время без уведомления Пользователя вносить
                            изменения в перечень услуг, предлагаемых на Сайте, и в цены, применимые к оказываемым
                            услугам.</li>

                        <li>Документ «Политика конфиденциальности обработки персональных данных ООО «Проф-ИТ» регулирует
                            в соответствующей части и распространяет свое действие на использование Пользователем
                            Сайта.</li>

                        <li>Администрация Сайта не несет ответственности за посещение и использование им внешних
                            ресурсов, ссылки на которые могут содержаться на Сайте.</li>

                        <li>Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств
                            перед Пользователем в связи с любыми возможными или возникшими потерями или убытками,
                            связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой
                            регистрации, товарами или услугами, доступными на или полученными через внешние сайты или
                            ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на
                            Сайте информацию или ссылки на внешние ресурсы.</li>

                        <li>Пользователь согласен с тем, что Администрация Сайта не несет какой-либо ответственности и
                            не имеет каких-либо обязательств в связи с рекламой, которая может быть размещена на
                            Сайте.</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P5Usage;