import React, {Component} from 'react';
import {SYMBOL} from "../../../../../../../constants";

class Delivery extends Component {
    render() {
        let { order } = this.props;

        return (
            <div className="row product-order-detail">
                <div className="col-sm-3 col-4"/>
                <div className="col-sm-3 col-4 order_detail">
                    <div>
                        <h4>Продукт</h4>
                        <h5>Доставка</h5>
                    </div>
                </div>
                <div className="col-sm-3 col-4 order_detail">
                    <div>
                        <h4>Кол-во</h4>
                        <h5> </h5>
                    </div>
                </div>
                <div className="col-sm-3 col-3 order_detail price">
                    <div>
                        <h4>Цена (шт.)</h4>
                        <h5>{order.delivery ? order.delivery.cost : 0} {SYMBOL}</h5>
                    </div>
                </div>
            </div>
        )
    }
}

export default Delivery