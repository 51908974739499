import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class TouchSensor extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль датчика касания</h4>

                <p>
                    Модуль датчика касания представляет собой печатную плату с установленным
                    чувствительным элементом – концевым выключателем. Внешний вид модуля датчика
                    касания приведен на рисунке 2.9.
                </p>

                <p>
                    Области применения модуля датчика касания:<br/>
                    - обнаружение препятствия во время движения;<br/>
                    - срабатывание при физическом контакте.
                </p>

                <p>
                    При физическом контакте модуля датчика касания с препятствием во время движения
                    происходит замыкание контактов концевого выключателя, в результате чего вырабатывается
                    соответствующий сигнал.
                </p>

                <Image src={"/Constructor/touch-sensor.png"} name={"Внешний вид датчика касания"} number={"2.9"}/>

                <p>
                    Основные технические характеристики модуля датчика касания приведены в таблице 2.8.
                </p>

                <Table name={"Основные технические характеристики модуля датчика касания"}
                       number={"2.8"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Тип контактора", "Прямой рычаг"],
                           ["Номинальный ток", "100 мА"],
                           ["Тип контактов", "SPDT"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Тип концевого выключателя", "Муфта с лапкой"],
                           ["Номинальное напряжение электропитания", "5 В"],
                           ["Среднее значение тока потребления", "5 мА"],
                       ]}/>
            </div>
        )
    }
}

export default TouchSensor