import React, {Component} from 'react';

class CustomerDetails extends Component {
    render() {
        let {order} = this.props;
        const invId = order.id;
        const name = order.user ? order.user.name : ''
        const email = order.user ? order.user.email : '';

        return (
            <section className="section-b-space light-layout">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="success-text">
                                <i className="fa fa-check-circle" aria-hidden="true"/>
                                <h2>спасибо</h2>
                                <p>Заказ был успешно оплачен</p>
                                <p>№ заказа: {invId}</p>
                                <p>ФИО: {name}</p>
                                <p>email: {email}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CustomerDetails