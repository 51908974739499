import React, {Component} from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import SyntaxHighlighter from "react-syntax-highlighter";
import {a11yLight} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class Code extends Component {
    render() {
        let { data } = this.props;
        return (
            <div className={"code"}>
                <Tabs className="tab-content tab-product nav-material">
                    <TabList className="nav nav-tabs nav-material">
                        <Tab className="nav-item">
                            <span className={"nav-link"}>Блоки</span>
                            <div className="material-border"/>
                        </Tab>
                        <Tab className="nav-item">
                            <span className={"nav-link"}>Код</span>
                            <div className="material-border"/>
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <div className={"content-container"}>
                            <img alt={""} src={`/assets/images/MenuPages/Education/exercises${data.blocks}`} className={"blocks"}/>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={"content-container"}>
                            <SyntaxHighlighter language="c" style={a11yLight}>
                                { data.text }
                            </SyntaxHighlighter>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        )
    }
}

export default Code