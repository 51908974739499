import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {SERVER_HOST} from "../../../../../../constants/axiosURLs";

class ProductImage extends Component {
    render() {
        let { item } = this.props;

        return (
            <td>
                <Link to={`/product/${item.id}`}>
                    <img src={SERVER_HOST + (item.pictures[0].url || '/assets/images/common/image-stub.jpg')}
                         alt="" />
                </Link>
            </td>
        )
    }
}

export default ProductImage