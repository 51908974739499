import React, {Component} from 'react';
import {SYMBOL} from "../../../../constants";
import {connect} from "react-redux";


class ShortDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            quantity: 1,
        }
    }

    onOpenModal = () => this.setState({open: true});
    onCloseModal = () => this.setState({open: false});

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState(state => ({
                quantity: state.quantity - 1,
            }));
        }
    }

    plusQty = () => {
        let cartProduct = this.props.productFromCart;
        let cartQty = cartProduct ? cartProduct.qty : 0;

        if (this.props.item.stock - cartQty > this.state.quantity) {
            this.setState(state => ({quantity: state.quantity + 1}))
        }
    }

    changeQty = (item, value) => {
        value = +value;
        value = value === 0
            ? 1
            : (value && value > 0
                ? value
                : this.state.quantity);

        let cartProduct = this.props.productFromCart;
        let cartQty = cartProduct ? cartProduct.qty : 0;

        if (value > item.stock - cartQty) {
            this.setState({quantity: item.stock - cartQty || 1});
        } else {
            this.setState({quantity: parseInt(value)});
        }
    }

    render() {
        const {item, addToCartClicked} = this.props;
        let cartQty = this.props.productFromCart ? this.props.productFromCart.qty : 0;

        return (
            <div className="col-md-6 product-right">
                <h2>{item.name}</h2>

                <h3>
                    {item.discount
                        ? <div>
                            {Math.round(item.price * (100 - item.discount) / 100)} {SYMBOL} <del>{item.price} {SYMBOL}</del>
                        </div>
                        : <div>{item.price} {SYMBOL}</div>}
                </h3>

                <div className="product-description border-product">
                    <span className={"instock-cls " + (item.stock - cartQty === 0 ? "warning" : "")}>
                        {item.stock - cartQty === 0 ? "Нет в наличии" : `В наличии ${item.stock - cartQty} шт.`}
                    </span>

                    <h6 className="product-title">Количество</h6>
                    <div className="qty-box">
                        <div className="input-group">
                            <span className="input-group-prepend">
                                <button type="button"
                                        className="btn quantity-left-minus"
                                        onClick={this.minusQty}
                                        data-type="minus" data-field="">
                                 <i className="fa fa-angle-left"/>
                                </button>
                            </span>
                            <input type="text" name="quantity" value={this.state.quantity}
                                   onChange={e => this.changeQty(item, e.target.value)}
                                   className="form-control input-number"/>
                            <span className="input-group-prepend">
                                <button type="button"
                                        className="btn quantity-right-plus"
                                        onClick={this.plusQty}
                                        data-type="plus" data-field="">
                                    <i className="fa fa-angle-right"/>
                                </button>
                           </span>
                        </div>
                    </div>
                </div>

                <div className="product-buttons">
                    <button className="btn btn-solid" onClick={() => {
                        if (this.state.quantity + cartQty <= this.props.item.stock) {
                            addToCartClicked(item, this.state.quantity);
                        }
                    }}>в корзину</button>
                </div>

                <div className="border-product">
                    <h6 className="product-title">Описание</h6>
                    <p>{item.shortDetails}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let productIndex = state.cartList.cart.findIndex(product => product.id === ownProps.item.id);
    let productFromCart = ~productIndex ? state.cartList.cart[productIndex] : undefined;
    return { productFromCart };
}

export default connect(mapStateToProps) (ShortDetails);