import React, {Component} from 'react';
import {SYMBOL} from "../../../../constants";
import {connect} from "react-redux";
import {getCartTotal} from "../../../../assets/js/functions";

class Total extends Component {
    render() {
        let { total } = this.props;

        return (
            <table className="table cart-table table-responsive-md">
                <tfoot>
                <tr>
                    <td>итого :</td>
                    <td><h2>{total} {SYMBOL}</h2></td>
                </tr>
                </tfoot>
            </table>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        total: getCartTotal(state.cartList.cart),
    })
}

export default connect(mapStateToProps) (Total)