import React, {Component} from 'react';
import {Accordion, AccordionItem} from "react-light-accordion";

class AdvancedTheory extends Component {
    render() {
        return (
            <div className="accordion-container">
                <Accordion atomic={true}>
                    <AccordionItem title="Аппаратная часть системы управления роботом – центральные устройства и периферийные устройства">

                    </AccordionItem>
                    <AccordionItem title="Программная часть системы управления роботом – системное ПО, прикладное ПО, системы программирования">

                    </AccordionItem>
                    <AccordionItem title="(Знакомство с языком С++, альтернативные среды программирования)">

                    </AccordionItem>
                    <AccordionItem title="Виды приводов – электрический, гидравлический, пневматический">

                    </AccordionItem>
                    <AccordionItem title="Рабочие органы робота – схват и его модификации">

                    </AccordionItem>
                    <AccordionItem title="Параметрические датчики (холла и др.)">

                    </AccordionItem>
                    <AccordionItem title="Генераторные датчики (температуры и др.)">

                    </AccordionItem>
                </Accordion>
            </div>
        )
    }
}

export default AdvancedTheory