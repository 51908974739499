import React, {Component} from 'react';
import {createOrder} from "../../../../../assets/axios/order";
import {getOrderData, getRobokassaHref} from "../../../../../assets/js/functions";
import {getCartTotal} from "../../../../../assets/js/functions";
import {connect} from "react-redux";
import Modal from "react-responsive-modal";

class PaymentButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
            modalContent: ''
        };
    }

    onOpenModal = (content) => {
        this.setState({ modalOpen: true, modalContent: content });
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false, modalContent: '' });
    };

    render() {
        const {cartItems, total, values, validator} = this.props;

        return (
            <>
                <div className="payment-box">
                    {(total !== 0) ?
                        <div className="text-right">
                            <button type="button"
                                    className="btn-solid btn payment-button"
                                    onClick={() => {
                                        if (validator.allValid()) {
                                            let fullCost = (total + +values.delivery_cost);
                                            let orderData = Object.assign({},
                                                values,
                                                { fullCost, cartItems });

                                            createOrder(getOrderData(orderData),
                                                response => {
                                                    orderData.orderId = response.data.id;
                                                    orderData.sign = response.data.sign;
                                                    window.location.href = getRobokassaHref(orderData);
                                                },
                                                () => this.onOpenModal("Не удалось создать заказ"))
                                        } else {
                                            window.scrollTo(0,0);
                                            validator.showMessages();
                                        }
                                    }}>
                                оплатить
                            </button>
                        </div>
                        : null}
                </div>

                <Modal open={this.state.modalOpen} onClose={this.onCloseModal} center>
                    <div className={"modal-warning"}>
                        {this.state.modalContent}
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    total: getCartTotal(state.cartList.cart)
});

export default connect(mapStateToProps) (PaymentButton)