import React, {Component} from 'react';
import Header from "./common/Header";
import Footer from "./common/Footer";

class App extends Component {
    render() {
        return (
            <div className={"page-container"}>
                <Header className={"white-menu"}/>
                { this.props.children }
                <Footer/>
            </div>
        );
    }
}

export default App;