import React, {Component} from 'react';
import SimpleReactValidator from "simple-react-validator";
import PersonalData from "./PersonalData";
import DeliveryData from "./DeliveryData";
import OrderBox from "./OrderBox";

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            full_name: '',
            email: '',
            phone: '',
            comment: '',
            delivery_type: '',
            delivery_city: '',
            delivery_address: '',
            delivery_cost: 0,
            delivery_time: 0,
        };

        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            messages: {
                full_name: "ФИО обязательно",
                email: "Email некорректен",
                phone: "Телефон некорректен",
                delivery_city: "Город обязателен",
                delivery_address: "Адрес обязателен",
                default: "Заполните поле",
            }
        });

        this.changeSDEKInfo = this.changeSDEKInfo.bind(this);
        this.setStateFromInput = this.setStateFromInput.bind(this);
        this.refreshInfo = this.refreshInfo.bind(this);
        window.sdekCallback = this.changeSDEKInfo;
        window.cartItems = this.props.cartItems;
        window.sdekRefresh = this.refreshInfo;
    }

    changeSDEKInfo(wat) {
        this.setState({
            delivery_type: wat.id,
            delivery_cost: wat.price,
            delivery_address: wat.PVZ ? wat.PVZ.Address : '',
            delivery_city: wat.cityName,
            delivery_time: wat.term
        });
    }

    refreshInfo(wat) {
        if(wat.cityName !== this.state.delivery_city) {
            this.setState({
                delivery_type: '',
                delivery_cost: 0,
                delivery_address: '',
                delivery_city: '',
                delivery_time: 0
            })
        }
    }

    setStateFromInput = (event) => {
        let obj = {};

        if (event.target.name === 'delivery_type') {
            if (event.target.value === 'office') {
                obj.delivery_city = 'Санкт-Перербург';
                obj.delivery_address = 'Синопская наб., 52а, БЦ "Башкировъ"';
                obj.delivery_cost = 0;
                obj.delivery_time = 0;
                obj.delivery_type = event.target.value;
            } else {
                obj.delivery_city = this.cdekDeliveryInfo.city;
                obj.delivery_address = this.cdekDeliveryInfo.address;
                obj.delivery_cost = this.cdekDeliveryInfo.cost;
                obj.delivery_time = this.cdekDeliveryInfo.time;
                obj.delivery_type = this.cdekDeliveryInfo.type;
            }
        } else {
            obj[event.target.name] = event.target.value;
        }

        if (event.target.name === 'delivery_type' && event.target.value === 'office') {
            this.setState((state) => {
                this.cdekDeliveryInfo = {
                    type: state.delivery_type,
                    city: state.delivery_city,
                    address: state.delivery_address,
                    cost: state.delivery_cost,
                    time: state.delivery_time
                }

                return obj;
            })
        } else {
            this.setState(obj)
        }
    };

    render() {
        return (
            <form onSubmit={e => e.preventDefault()}>
                <div className="checkout">
                    <div className="checkout-title"><h3>Платежные данные</h3></div>

                    <PersonalData onChange={this.setStateFromInput}
                                  values={this.state}
                                  validator={this.validator}/>

                    <DeliveryData onChange={this.setStateFromInput}
                                  values={this.state}
                                  validator={this.validator}/>

                    <OrderBox values={this.state}
                              validator={this.validator}/>
                </div>
            </form>
        )
    }
}

export default Form;