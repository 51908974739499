import React, {Component} from 'react';
import {conditions, linkAnchor} from "../../../constants/Pages/FooterPages/guarantee";
import PageTitles from "../../common/Page/PageTitles";
import PageContent from "../../common/Page/PageContent";

class Conditions extends Component {
    render() {
        return (
            <>
                <PageTitles titles={conditions}
                            linkAnchor={linkAnchor}/>
                <PageContent blocks={conditions}
                             linkAnchor={linkAnchor}/>
            </>
        )
    }
}

export default Conditions