import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class DistanceSensorWithServo extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модули УЗ дальномера и сервопривода</h4>

                <p>
                    Модули УЗ дальномера и сервопривода представляют собой ультразвуковой дальномер и сервомотор,
                    вмонтированные в пластиковые корпуса.<br/>
                    Внешний вид модуля УЗ дальномера приведен на рисунке 2.17.<br/>
                    Внешний вид модуля сервопривода приведен на рисунке 2.18.
                </p>

                <p>
                    Модуль УЗ дальномера, используя принцип эхолокации, посылает сигнал и принимает его
                    отражение от объекта. Время между отправкой и получением сигнала используется для
                    определения расстояния. В состав ультразвукового дальномера входят дальномер HC-SR04 и
                    вычислительный модуль.
                </p>

                <p>
                    Принцип работы дальномера HC-SR04 заключается в излучении ультразвукового сигнала и приеме
                    его отражения от объекта наблюдения. При этом запуск излучения осуществляется каким-либо
                    внешним устройством управления подачей импульса на вывод, а при приеме отраженного сигнала
                    дальномер HC-SR04 формирует импульс на выводе. Таким образом может быть измерено время между
                    началом излучения и приемом отраженного сигнала. То есть то время, за которое ультразвук
                    преодолел путь, равный двум расстояниям до объекта наблюдения. Результатом умножения половины
                    полученного значения времени на скорость звука является расстояние до объекта наблюдения.
                </p>

                <p>
                    Все перечисленные функции и вычисления автоматически выполняет вычислительный модуль дальномера,
                    построенный на основе восьмиразрядного микроконтроллера. При обращении по интерфейсу I2C он
                    выдает расстояние до объекта наблюдения в сантиметрах в диапазоне от 2 до 254 см.
                </p>

                <p>
                    Сервомотор, вмонтированный в пластиковый корпус, позволяет поворачивать дальномер в право
                    и в лево на необходимый угол для того, чтобы направить его на нужный объект не используя поворот
                    колёсной платформы.
                </p>

                <p>
                    Модули УЗ дальномера и сервопривода подключаются к двум разным соединителям на плате расширения.
                    Провода, идущие от модуля УЗ дальномера, подключаются в специальный соединитель интерфейса I2C
                    платы расширения. Провода, идущие от сервомотора, подключаются в любой цифровой или аналоговый
                    порт на плате расширения.
                </p>

                <Image src={"/Constructor/distance-sensor.png"} name={"Внешний вид модуля УЗ дальномера"} number={"2.17"}/>
                <Image src={"/Constructor/servo.png"} name={"Внешний вид модуля сервопривода"} number={"2.18"}/>

                <p>
                    Основные технические характеристики модуля УЗ дальномера приведены в таблице 2.16.
                </p>

                <Table name={"Основные технические характеристики модуля УЗ дальномера"}
                       number={"2.16"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["УЗ-датчик", "HC-SR04"],
                           ["Встроенный I2C-конвертер", "имеется"],
                           ["Номинальное напряжение электропитания", "5 В"],
                           ["Потребление в режиме ожидания, не более", "2 мА"],
                           ["Потребление при работе, не более", "15 мА"],
                           ["Диапазон расстояний", "от 2 до 400 см"],
                           ["Эффективный угол наблюдения", "15°"],
                           ["Рабочий угол наблюдения", "30°"],
                       ]}/>

               <p>
                   Основные технические характеристики модуля сервопривода приведены в таблице 2.17.
               </p>

                <Table name={"Основные технические характеристики модуля сервопривода"}
                       number={"2.17"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Напряжение электропитания", "от 4,8 до 6,0 В"],
                           ["Скорость без нагрузки при напряжении 4,8 В", "до 0,11 с/60°"],
                           ["Скорость без нагрузки при напряжении 6 В", "до 0,1 с/60°"],
                           ["Момент при напряжении 4,8 В", "до 1,8 кг/см"],
                           ["Момент при напряжении 6 В", "до 2,2 кг/см"],
                           ["Рабочая температура", "от 0 до +55°С"],
                       ]}/>
            </div>
        )
    }
}

export default DistanceSensorWithServo