export const minProm = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" width="204px" height="35px" viewBox="0 0 204 35" version="1.1" style="&#10;    &#10;">\n' +
    '    <!-- Generator: Sketch 3.2.2 (9983) - http://www.bohemiancoding.com/sketch -->\n' +
    '    <title>Imported Layers</title>\n' +
    '    <desc>Created with Sketch.</desc>\n' +
    '    <defs/>\n' +
    '    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">\n' +
    '        <g id="Imported-Layers" sketch:type="MSLayerGroup" fill="#938D8C">\n' +
    '            <path d="M35.107,26.699 L17.795,26.699 C17.316,26.771 16.842,26.929 16.398,27.188 C15.895,27.478 15.483,27.862 15.159,28.304 L11.658,34.379 L39.541,34.379 L35.107,26.699" id="Fill-1" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M25.394,24.279 C25.917,24.576 26.474,24.743 27.039,24.793 L34,24.793 L20.035,0.609 L15.581,8.32 L24.075,23.04 C24.409,23.536 24.846,23.956 25.394,24.279" id="Fill-2" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M18.349,17.927 C18.349,17.321 18.213,16.748 17.97,16.235 L14.5,10.223 L0.548,34.379 L9.465,34.379 L17.891,19.776 C18.182,19.224 18.349,18.594 18.349,17.927" id="Fill-3" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M59.533,16.462 C59.533,16.113 59.799,4.655 59.799,4.655 L56.685,16.462 L54.264,16.462 L51.149,4.655 C51.149,4.655 51.411,15.992 51.411,16.462 L48.792,16.462 L48.792,2.092 L52.699,2.092 L55.503,12.755 L58.272,2.092 L62.156,2.092 L62.156,16.462 L59.533,16.462" id="Fill-4" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M77.192,16.462 L74.503,16.462 L74.503,7.059 L68.074,14.748 L68.074,16.462 L65.39,16.462 L65.39,2.092 L68.074,2.092 L68.074,11.416 L74.503,3.722 L74.503,2.092 L77.192,2.092 L77.192,16.462" id="Fill-5" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M89.313,16.462 L89.313,9.926 L83.152,9.926 L83.152,16.462 L80.443,16.462 L80.443,2.092 L83.152,2.092 L83.152,7.503 L89.313,7.503 L89.313,2.092 L92.021,2.092 L92.021,16.462 L89.313,16.462" id="Fill-6" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M106.67,2.093 L106.67,16.463 L103.98,16.463 L103.98,4.534 L97.959,4.534 L97.959,16.463 L95.275,16.463 L95.275,2.093 L106.67,2.093" id="Fill-7" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M115.539,2.093 C118.508,2.093 120.643,3.926 120.643,6.876 C120.643,9.48 119.134,11.573 115.392,11.573 L112.647,11.573 L112.647,16.462 L109.96,16.462 L109.96,2.093 L115.539,2.093 L115.539,2.093 Z M112.647,9.381 L115.006,9.381 C116.92,9.381 117.875,8.48 117.875,6.792 C117.875,4.475 116.003,4.392 114.702,4.392 L112.647,4.392 L112.647,9.381 L112.647,9.381 Z" id="Fill-8" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M128.882,1.729 C133.25,1.729 135.757,5.307 135.757,9.195 C135.757,12.455 133.989,16.825 128.817,16.825 C124.587,16.825 121.981,13.328 121.981,9.296 C121.981,5.082 124.73,1.729 128.882,1.729 M128.9,14.32 C131.035,14.32 133.049,12.676 133.049,9.416 C133.049,6.855 131.71,4.186 128.858,4.186 C126.501,4.186 124.75,6.043 124.75,9.195 C124.75,11.432 125.825,14.32 128.9,14.32" id="Fill-9" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M148.978,16.462 C148.978,16.113 149.243,4.655 149.243,4.655 L146.131,16.462 L143.707,16.462 L140.596,4.655 C140.596,4.655 140.859,15.992 140.859,16.462 L138.238,16.462 L138.238,2.092 L142.141,2.092 L144.952,12.755 L147.716,2.092 L151.603,2.092 L151.603,16.462 L148.978,16.462" id="Fill-10" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M160.246,4.513 L160.246,16.462 L157.562,16.462 L157.562,4.513 L153.654,4.513 L153.654,2.092 L164.214,2.092 L164.214,4.513 L160.246,4.513" id="Fill-11" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M172.512,1.729 C176.89,1.729 179.389,5.307 179.389,9.195 C179.389,12.455 177.62,16.825 172.452,16.825 C168.22,16.825 165.617,13.328 165.617,9.296 C165.617,5.082 168.364,1.729 172.512,1.729 M172.534,14.32 C174.669,14.32 176.684,12.676 176.684,9.416 C176.684,6.855 175.342,4.186 172.492,4.186 C170.133,4.186 168.384,6.043 168.384,9.195 C168.384,11.432 169.459,14.32 172.534,14.32" id="Fill-12" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M187.567,2.093 C190.538,2.093 192.675,3.926 192.675,6.876 C192.675,9.48 191.169,11.573 187.425,11.573 L184.678,11.573 L184.678,16.462 L181.994,16.462 L181.994,2.093 L187.567,2.093 L187.567,2.093 Z M184.678,9.381 L187.037,9.381 C188.952,9.381 189.906,8.48 189.906,6.792 C189.906,4.475 188.037,4.392 186.733,4.392 L184.678,4.392 L184.678,9.381 L184.678,9.381 Z" id="Fill-13" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M203.252,2.093 L203.252,4.534 L197.35,4.534 L197.35,16.463 L194.665,16.463 L194.665,2.093 L203.252,2.093" id="Fill-14" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M54.368,20.015 C57.336,20.015 59.473,21.845 59.473,24.796 C59.473,27.398 57.97,29.494 54.226,29.494 L51.481,29.494 L51.481,34.379 L48.792,34.379 L48.792,20.015 L54.368,20.015 L54.368,20.015 Z M51.481,27.297 L53.84,27.297 C55.754,27.297 56.709,26.406 56.709,24.714 C56.709,22.394 54.836,22.315 53.534,22.315 L51.481,22.315 L51.481,27.297 L51.481,27.297 Z" id="Fill-15" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M67.717,19.646 C72.086,19.646 74.59,23.233 74.59,27.113 C74.59,30.37 72.824,34.746 67.651,34.746 C63.421,34.746 60.816,31.246 60.816,27.215 C60.816,23.005 63.565,19.646 67.717,19.646 M67.735,32.243 C69.871,32.243 71.883,30.596 71.883,27.337 C71.883,24.778 70.541,22.111 67.693,22.111 C65.334,22.111 63.583,23.962 63.583,27.113 C63.583,29.358 64.664,32.243 67.735,32.243" id="Fill-16" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M89.195,30.371 C88.73,31.595 87.124,34.746 82.994,34.746 C79.594,34.746 76.279,32.428 76.279,27.054 C76.279,23.859 78.087,19.647 83.092,19.647 C86.798,19.647 88.322,22.026 88.934,24.083 L86.265,24.777 C86.102,23.209 84.781,22.112 83.194,22.112 C80.855,22.112 79.189,23.902 79.189,27.097 C79.189,29.047 79.98,32.206 83.176,32.206 C85.027,32.206 86.229,30.778 86.656,29.312 L89.195,30.371" id="Fill-17" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M103.619,30.371 C103.151,31.595 101.548,34.746 97.412,34.746 C94.017,34.746 90.698,32.428 90.698,27.054 C90.698,23.859 92.511,19.647 97.518,19.647 C101.22,19.647 102.743,22.026 103.355,24.083 L100.69,24.777 C100.526,23.209 99.206,22.112 97.617,22.112 C95.278,22.112 93.61,23.902 93.61,27.097 C93.61,29.047 94.401,32.206 97.598,32.206 C99.449,32.206 100.65,30.778 101.076,29.312 L103.619,30.371" id="Fill-18" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M117.369,34.379 L114.685,34.379 L114.685,24.977 L108.26,32.668 L108.26,34.379 L105.567,34.379 L105.567,20.014 L108.26,20.014 L108.26,29.333 L114.685,21.645 L114.685,20.014 L117.369,20.014 L117.369,34.379" id="Fill-19" sketch:type="MSShapeGroup"/>\n' +
    '            <path d="M132.426,34.379 L129.739,34.379 L129.739,24.977 L123.311,32.668 L123.311,34.379 L120.624,34.379 L120.624,20.014 L123.311,20.014 L123.311,29.333 L129.739,21.645 L129.739,20.014 L132.426,20.014 L132.426,34.379" id="Fill-20" sketch:type="MSShapeGroup"/>\n' +
    '        </g>\n' +
    '    </g>\n' +
    '</svg>';