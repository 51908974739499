import axios from "axios";
import {ORDER_DETAIL, ORDERS} from "../../constants/axiosURLs";

export function createOrder(data, thenFunc, catchFunc) {
    axios.post(ORDERS, data, {withCredentials: true})
        .then(thenFunc)
        .catch(catchFunc);
}

export function getOrderDetail(orderId, thenFunc, catchFunc) {
    axios.get(ORDER_DETAIL(orderId))
        .then(thenFunc)
        .catch(catchFunc);
}