import React, {Component} from "react";
import {AccordionItem} from 'react-light-accordion';

class P9DataActions extends Component {
    render() {
        return (
            <AccordionItem
                title={<li>Изменение, блокирование, уничтожение персональных данных</li>}>
                <div>
                    <ol>
                        <li>Оператор обязуется внести по требованию Пользователя необходимые изменения, блокировать его
                            персональные данные или уничтожить в случае, если персональные данные являются неполными,
                            устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной
                            настоящей Политикой конфиденциальности  цели. О внесенных изменениях и предпринятых мерах
                            Оператор уведомляет Пользователя в течение тридцати календарных дней с момента получения
                            требования Пользователя о необходимости изменить, блокировать или уничтожить его персональные
                            данные.</li>

                        <li>В случае выявления недостоверных персональных данных Пользователя или неправомерных действий
                            с ними Оператор при обращении или по запросу   Пользователя осуществляет блокирование персональных
                            данных, относящихся к данному Пользователю, с момента такого обращения на период проверки.</li>

                        <li>В случае подтверждения факта недостоверности персональных данных Пользователя, Оператор
                            уточняет персональные данные у Пользователя и снимает их блокирование.</li>

                        <li>В случае выявления неправомерных действий с персональными данными Пользователя Оператор в
                            срок, не превышающий трех рабочих дней с даты выявления, обязан устранить допущенные нарушения.
                            В случае невозможности устранения допущенных нарушений Оператор в срок, не превышающий трех
                            рабочих дней с даты выявления неправомерности действий с персональными данными Пользователя,
                            обязан уничтожить персональные данные. Об устранении допущенных нарушений или об уничтожении
                            персональных данных Оператор уведомляет Пользователя.</li>

                        <li>По истечении трех лет с момента последней активности Пользователя на сайте Оператор прекращает
                            обработку (хранение) его персональных данных, если иной срок хранения персональных данных не
                            установлен федеральным законом или Договором (стороной которого, выгодоприобретателем или
                            поручителем по которому является Пользователь).</li>
                    </ol>
                </div>
            </AccordionItem>
        );
    }
}

export default P9DataActions;