import React, {Component} from 'react';
import Description from "./Description";
import Items from "./Items";

class Menu extends Component {
    render() {
        return (
            <section className="menu section-b-space darken-layout">
                <div className="container">
                    <div className="row footer-theme partition-f">
                        <Description/>
                        <Items/>
                    </div>
                </div>
            </section>
        )
    }
}

export default Menu