import React, {Component} from 'react';

class Purpose extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Назначение</h4>
                <p>
                    Основу Конструктора составляют универсальный программируемый контроллер и
                    колесная платформа со встроенным аккумуляторным блоком, имеющая места для
                    крепления навесных модулей. Программируемый контроллер архитектуры Arduino
                    обеспечивает возможность использования огромной открытой базы библиотек
                    (готовых программных модулей) для решения самых разнообразных учебных и
                    технических задач. Конструктор механически совместим с физическими модулями
                    (сенсорами, исполнительными устройствами) других производителей.
                </p>
                <p>
                    Конструктор предназначен для использования в образовательном процессе молодежи
                    при изучении инженерно-технических дисциплин. Конструктор обеспечивает:<br/>
                    - интерактивное и глубокое практическое изучение робототехники, программирования,
                    методов разработки систем компьютерного зрения;<br/>
                    - стимулирование совместной работы группы и индивидуальных обучающихся;<br/>
                    - мотивацию к процессу познания за счет эффектов вовлечения и глубокого погружения.

                </p>
                <p>
                    Конструктор позволяет легко и быстро интегрировать его в основные общеобразовательные
                    программы по предметам "Технология", "Физика" и "Информатика", а также в программы
                    дополнительного образования.
                </p>
            </div>
        )
    }
}

export default Purpose