import React, {Component} from "react";

class Parallax extends Component {
    render() {
        return (
            <section className="parallax">
                <img alt={""}
                     src={`/assets/images/MainPage/parallax/mob.jpg`} />
            </section>
        );
    }
}

export default Parallax;