import React, {Component} from 'react';
import {Accordion} from "react-light-accordion";
import P1Definitions from "./Accordion/P1Definitions";
import P2GeneralProvisions from "./Accordion/P2GeneralProvisions";
import P3Subject from "./Accordion/P3Subject";
import P4Goals from "./Accordion/P4Goals";
import P5DataProcessing from "./Accordion/P5DataProcessing";
import P6Obligations from "./Accordion/P6Obligations";
import P7Responsibility from "./Accordion/P7Responsibility";
import P8Disputes from "./Accordion/P8Disputes";
import P9DataActions from "./Accordion/P9DataActions";
import P10Advanced from "./Accordion/P10Advanced";
import PageContent from "../../../common/Page/PageContent";

class Text extends Component {
    render() {
        return (
            <PageContent>
                <div className={"document-container container-fluid accordion-container"}>
                    <ol>
                        <Accordion atomic={true}>
                            <P1Definitions/>
                            <P2GeneralProvisions/>
                            <P3Subject/>
                            <P4Goals/>
                            <P5DataProcessing/>
                            <P6Obligations/>
                            <P7Responsibility/>
                            <P8Disputes/>
                            <P9DataActions/>
                            <P10Advanced/>
                        </Accordion>
                    </ol>
                </div>
            </PageContent>
        )
    }
}

export default Text