import React, {Component} from 'react';
import Content from "./Content";

class Menu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: this.props.className ? `dark.png` : `light.png`
        }
    }

    componentWillMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if (number >= 300) {
            document.getElementById("sticky").classList.add('fixed');
            this.setState({
                logo: `dark.png`
            });
        } else {
            document.getElementById("sticky").classList.remove('fixed');
            this.setState({
                logo: this.props.className ? `dark.png` : `light.png`
            });
        }
    }

    render() {
        return (
            <header id="sticky" className="sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <Content logo={this.state.logo}/>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Menu