import React, {Component} from 'react';

class ContactsFooter extends Component {
    render() {
        return (
            <div className={"contacts"}>
                <div className={"content"}>
                    <div className={"numeration"}>
                        <div className="decoration"/>
                    </div>
                    <div>
                        <div className={"name"}>Контакты отдела<br/>технической поддержки</div>
                        <div className={"name not-uppercase"}>E-mail: <a href={"mailto:support@omegabot.ru"}>support@omegabot.ru</a></div>
                        <div className={"name not-uppercase"}>Телефон: <a href={"tel:+78124263110"}>+7 (812) 426 31 10</a></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactsFooter