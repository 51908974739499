import React, {Component} from 'react';
import ProductImage from "./ProductImage";
import {Link} from "react-router-dom";
import MobileQuantity from "./MobileQuantity";
import {SYMBOL} from "../../../../../../constants";
import Quantity from "./Quantity";
import RemoveButton from "./RemoveButton";
import {connect} from "react-redux";
import {changeQty} from "../../../../../../actions";

class Item extends Component {
    constructor(props) {
        super(props);
        this.onQuantityChange = this.onQuantityChange.bind(this);
    }

    onQuantityChange(item, value) {
        let { cart } = this.props;
        value = +value;
        value = value === 0 ? 1 : (value && value > 0 ? value : item.qty);
        let cartProductIndex = cart.findIndex(product => product.id === item.id);

        if(~cartProductIndex) {
            value = value <= item.stock ? value : item.stock;
            this.props.changeQty(item.id, parseInt(value));
        }
    }

    render() {
        let { item } = this.props;

        return (
            <tbody>
            <tr>
                <ProductImage item={item}/>

                <td>
                    <Link to={`/product/${item.id}`}>{item.name}</Link>
                    <MobileQuantity item={item}
                                    onChange={event => this.onQuantityChange(item, event.target.value)}/>
                </td>

                <td><h2>{item.price - (item.price * item.discount / 100)} {SYMBOL}</h2></td>

                <Quantity item={item}
                          onChange={event => this.onQuantityChange(item, event.target.value)}/>

                <RemoveButton item={item}/>

                <td><h2 className="td-color">{item.sum} {SYMBOL}</h2></td>
            </tr>
            </tbody>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        cart: state.cartList.cart
    })
}

export default connect(mapStateToProps, {changeQty}) (Item)