import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Encoder extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль энкодера</h4>

                <p>
                    Модуль энкодера представляет собой печатную плату с установленным чувствительным
                    элементом – фототранзистором. Чувствительный элемент модуля энкодера представляет
                    собой фототранзисторный выход, с противоположным излучателем и детектором,
                    обеспечивающим бесконтактное зондирование – "фотодетектирование".
                </p>

                <p>
                    Области применения модуля энкодера:<br/>
                    - регистрация прерывания потока излучения;<br/>
                    - определение наличия предмета между "стенками" энкодера.
                </p>

                <p>
                    Чувствительный элемент модуля энкодера состоит из двух частей светодиода
                    и фототранзистора, светодиод постоянно излучает и фототранзистор при наличии
                    излучения открыт. При наличии препятствия между светодиодом и фототранзистором
                    световой поток не доходит до фототранзистора, фототранзистор закрывается, микросхема
                    сигнализирует о наличии в ней препятствия.
                </p>

                <Image src={"/Constructor/encoder.png"} name={"Внешний вид модуля энкодера"} number={"2.6"}/>

                <p>
                    Основные технические характеристики энкодера приведены в таблице 2.5.
                </p>

                <Table name={"Основные технические характеристики энкодера"}
                       number={"2.5"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Максимальное напряжение коллектор-эмиттер (VCEO)", "35 В"],
                           ["Максимальный коллекторный ток", "20 мА"],
                           ["If - прямой ток", "50 мА"],
                           ["Минимальная рабочая температура", "- 25̊ C"],
                           ["Максимальная рабочая температура", "+ 85̊ C"],
                           ["Тип выхода", "фототранзистор"],
                           ["Метод измерения", "Transmissive, Slotted "],
                           ["Количество каналов", "1"],
                           ["Время спада", "150 мкс"],
                           ["Рассеивание мощности", "100 мВт"],
                           ["Габариты, Д х Ш", "20,5 х 11 мм"],
                           ["Управляющее напряжение", "1,2 В"],
                           ["Время отклика", "50 мкс"],
                           ["Номинальное значение напряжения электропитания", "5 В"],
                           ["Номинальное значение тока потребления", "50 мА"],
                       ]}/>
            </div>
        )
    }
}

export default Encoder