import React, {Component} from "react";
import { Accordion, AccordionItem } from 'react-light-accordion';
import {Link} from "react-router-dom";
import PageContent from "../../common/Page/PageContent";

class Programming extends Component {
    render() {
        return (
            <PageContent>
                <div className={"container-fluid accordion-container"}>
                    <Accordion atomic={true}>
                        <AccordionItem title="Как установить программное обеспечение для работы с роботом?">
                            <div>
                                Подробную инструкцию, как устанавливать OmegaBot IDE вы найдете на
                                странице <Link to={"/software"}>Загрузка ПО</Link>.
                            </div>
                        </AccordionItem>
                        <AccordionItem title="Как начать работу в OmegaВot IDE?">
                            <div>
                                <ul className={"list"}>
                                    <li>
                                        Запустите программу Arduino IDE и выберете пункт "Инструменты"
                                        в панели меню, в выпадающем списке выберите OmegaBot_IDE.
                                    </li>
                                    <li>
                                        В том же выпадающем списке "Инструменты" нажмите "Порт" и выберите
                                        нужный COM-порт.
                                    </li>
                                    <li>
                                        Подключите плату Омегабота к компьютеру через кабель для программирования
                                        и загрузите тестовый пример программы. Затем отключите плату от компьютера,
                                        подсоедините ее к роботу и включите его.
                                    </li>
                                </ul>
                            </div>
                        </AccordionItem>
                        <AccordionItem title="Можно ли открыть OmegaBot IDE без запуска Arduino IDE?">
                            <div>
                                После установки Arduino IDE и OmegaBot IDE есть два варианта запуска нашей среды программирования:<br/>
                                Вариант 1. Через панель меню Arduino IDE ("Инструменты" → OmegaBot_IDE).
                                Позже вы можете настроить автозапуск OmegaBot IDE, нажав "Файл" → "Настройки" → "Запуск
                                вместе с Arduino IDE" в панели меню визуальной среды.<br/>
                                Вариант 2. Через иконку OmegaBot IDE, запустив ее. В таком случае сразу откроется
                                визуальная среда, а окно с Arduino IDE будет скрыто. Управлять видимостью окна Ардуино
                                можно с помощью иконки в OmegaBot IDE.

                            </div>
                        </AccordionItem>
                        <AccordionItem title="Как создавать свои программы в OmegaBot IDE?">
                            <div>
                                В визуальной среде программирования есть базовое встроенное обучение. Вы можете
                                пройти его, выбрав "Инструменты" → "Пройти обучение" в панели меню OmegaBot IDE.

                            </div>
                        </AccordionItem>
                        <AccordionItem title="Нужно ли закрывать программу в визуальной среде после того, как я загрузил ее в плату?">
                            <div>
                                Не обязательно закрывать программу после ее загрузки в плату. Достаточно отсоединить
                                плату от USB-кабеля, подсоединить ее к выключенному роботу и включить его, чтобы
                                протестировать программу. Затем плату можно снова вытащить из робота, подключить к
                                USB-кабелю и загрузить в нее другую программу.
                            </div>
                        </AccordionItem>
                        <AccordionItem title="Что делать, если при загрузке программы в плату в окне ошибок Arduino IDE появилась ошибка?">
                            <div>
                                В окне ошибок Arduino IDE могут возникать следующие ошибки:
                                <ul className={"list"}>
                                    <li>
                                        <strong>"Проблема загрузки в плату" или "Ошибка открытия последовательного порта"</strong><br/>
                                        Во-первых, проверьте что плата подключена к компьютеру через USB-кабель
                                        для программирования. Во-вторых, проверьте, что у вас выбран нужный порт,
                                        нажав "Инструменты" → "Порт" в панели меню Arduino IDE.
                                    </li>
                                    <li>
                                        <strong>"Ошибка при загрузке скетча"</strong><br/>
                                        Попробуйте снова загрузить программу в плату. Если возникла эта же ошибка,
                                        то проверьте, что у вас актуальная версия программы Arduino IDE.
                                    </li>
                                </ul>
                                <br/>
                                Если ваша проблема не решилась, напишите нам на
                                почту <a href={"mailto:info@omegabot.ru"}>info@omegabot.ru</a> или через форму на
                                главной странице
                                сайта, и мы разберем ваш вопрос индивидуально.
                            </div>
                        </AccordionItem>
                        <AccordionItem title="Существуют ли готовые примеры программ для OmegaBot IDE?">
                            <div>
                                На странице <Link to={"/software"}>Обучение</Link> вы сможете найти множество
                                примеров готовых программ. Работа с визуальной средой программирования детально
                                описана в методическом пособии.
                            </div>
                        </AccordionItem>
                        <AccordionItem title="Существует ли мобильное приложение для программирования робота?">
                            <div>
                                На данный момент существует только версия OmegaBot IDE для персонального компьютера,
                                но сейчас мы занимаемся разработкой мобильного приложения для Android и iOS.
                            </div>
                        </AccordionItem>
                    </Accordion>
                </div>
            </PageContent>
        );
    }
}

export default Programming;