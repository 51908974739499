import React, {Component} from 'react';
import Table from "../common/Table";
import Image from "../common/Image";

class Shield extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Плата расширения</h4>

                <p>
                    Плата расширения Контроллера содержит в себе следующие устройства:<br/>
                    - два H-образных моста с драйверами для управления коллекторными моторами постоянного тока;<br/>
                    - соединитель для подключения Bluetooth модуля HC-05/06;<br/>
                    - цифровой процессор движения DMPTM на базе модуля ICM-20948;<br/>
                    - импульсный стабилизатор входного напряжения электропитания четырех модулей, подключаемых
                    на соединители "MODULES";<br/>
                    - тактовая кнопка;<br/>
                    - светодиод;<br/>
                    - светодиод индикации подачи электропитания на модуль.
                </p>

                <p>
                    Порты и интерфейсы платы расширения, на которые подключаются описанные выше устройства,
                    приведены в таблице 2.3.
                </p>

                <Table name={"Порты и интерфейсы платы расширения"}
                       className={"half-td"}
                       number={"2.3"}
                       header={["Подключаемое устройство", "Порт платы расширения"]}
                       rows={[
                           ["Драйвер мотора, L", "ШИМ - D6, Направление вращения - D7"],
                           ["Драйвер мотора, R", "ШИМ - D5, Направление вращения - D4"],
                           ["ICM-20948", "I2C, адрес b1101000"],
                           ["Bluetooth модуль HC-05/06", "UART"],
                           ["Тактовая кнопка", "D12"],
                           ["Светодиод", "D13"],
                       ]}/>

               <p>
                   Основные технические характеристики платы расширения приведены в таблице 2.4.
               </p>

                <Table name={"Основные технические характеристики платы расширения"}
                       number={"2.4"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Тактовая кнопка", "на D12"],
                           ["Светодиод", "на D13"],
                           ["I2C интерфейс", "имеется"],
                           ["Соединитель для подключения bluetooth-модуля HC-05/06", "имеется"],
                           ["Драйвера двигателей постоянного тока со встроенной защитой", "имеются"],
                           ["Соединители для подключения двух двигателей", "имеются"],
                           ["Электропитание от аккумуляторного блока", "имеется"],
                           ["Напряжение электропитания", "от 5,5 до 12 В"],
                           ["Количество соединителей для цифровых модулей", "11"],
                           ["Количество аналоговых соединителей", "4"],
                           ["Количество ШИМ соединителей", "3"],
                           ["Количество подключаемых сервомоторов", "4"],
                           ["Максимально допустимый ток на сервоприводе", "0,8 А"],
                           ["Номинальное напряжение на соединителях подключаемых модулей", "5 В"],
                           ["Количество соединителей для подключения моторов", "2"],
                           ["Максимально допустимый ток на мотор-колёсах", "7 А"],
                           ["Количество соединителей для подключения энкодеров", "2"],
                           ["Количество соединителей типа I2C", "1"],
                           ["Габариты, Д х Ш", "68,6 х 53,3 мм"],
                           ["Номинальное напряжение электропитания модулей, подключаемых к соединителям \"MODULES\", \"SENSORS\", \"I2C\"", "5 В"],
                           ["Максимальный ток нагрузки каждого H-моста", "5 А"],
                           ["Максимальный суммарный ток нагрузки модулей, подключаемых к соединителям \"MODULES\", при наличии внешнего электропитания", "3,5 А"],
                           ["Максимальный суммарный ток нагрузки модулей, подключаемых к соединителям \"SENSORS\", \"I2C\"", "0,5 А"],
                       ]}/>

                <Image src={"/Constructor/shield.png"} name={"Внешний вид платы расширения"} number={"2.3"}/>

                <p>
                    Внешний вид платы расширения приведен на рисунке 2.3.
                </p>
            </div>
        )
    }
}

export default Shield