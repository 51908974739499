import React, {Component} from 'react';

class Terms extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Перечень принятых сокращений и терминов</h4>

                <p>
                    АЦП – аналого-цифровой преобразователь.<br/>
                    Конструктор – модульный робототехнический конструктор "ОмегаБот".<br/>
                    ОС – операционная система.<br/>
                    ПО – программное обеспечение.<br/>
                    УЗ – ультразвуковой.<br/>
                    ШИМ – широтно-импульсная модуляция.
                </p>
            </div>
        )
    }
}

export default Terms