import code1 from "./code/1.json";
import code2 from "./code/2.json";
import code3 from "./code/3.json";
import code4 from "./code/4.json";
import code5 from "./code/5.json";

export const baseExercises = [
    {
        id: 1,
        name: 'Движение вперед, поворот, движение назад',
        annotation: {
            goal: [
                'Изучить принцип работы модуля кнопки',
                'Изучить работу моторов',
                'Необходимо задать роботу траекторию движения'
            ],
            task: 'Омегабот должен при нажатии на кнопку (на модуле кнопки) 2 секунды проехать прямо, затем 2 секунды поворачивать по часовой стрелке, затем 2 секунды ехать назад. Между действиями перерыв полсекунды. После чего робот должен остановиться.',
            functions: [
                'Чтение с кнопки',
                'Инициализация моторов',
                'Управление моторами'
            ],
            skills: [
                'Базовые навыки работы с роботом и средой программирования',
                'Составление простейших алгоритмов',
                'Работа с модулем кнопки',
                'Управление моторами робота'
            ]
        },
        complectation: {
            image: "/base/complectation/1.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль Кнопка',
                'Соединительный провод 3 pin 160 мм',
                'Кабель для программирования контроллера',
                'Аккумуляторы'
            ]
        },
        code: {
            blocks: "/base/blocks/1.png",
            text: code1
        }
    },
    {
        id: 2,
        name: 'Движение до препятствия и назад',
        annotation: {
            goal: [
                'Изучить принцип работы модуля датчика касания',
                'Закрепить знания по управлению моторами'
            ],
            task: 'Омегабот должен при нажатии на кнопку на плате расширения проехать прямо до момента срабатывания датчика касания (до препятствия), затем 1 секунду ехать назад. После чего робот должен остановиться.',
            functions: [
                'Чтение с кнопки платы расширения',
                'Инициализация моторов',
                'Управление моторами',
                'Чтение с датчика касания'
            ],
            skills: [
                'Базовые навыки работы с роботом и средой программирования',
                'Составление простейших алгоритмов',
                'Работа с модулем датчика касания',
                'Управление моторами робота'
            ]
        },
        complectation: {
            image: "/base/complectation/2.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль датчика касания',
                'Соединительный провод 3 pin 160 мм',
                'Кабель для программирования контроллера',
                'Аккумуляторы'
            ]
        },
        code: {
            blocks: "/base/blocks/2.png",
            text: code2
        }
    },
    {
        id: 3,
        name: 'Сигнал SOS светом и звуком',
        annotation: {
            goal: [
                'Изучить принцип работы модуля светодиода',
                'Изучить принцип работы модуля пьезодинамика'
            ],
            task: 'Омегабот должен при нажатии на кнопку на плате расширения помигать 3 раза светодиодом (каждый сигнал светодиода длится 1 секунду), затем 3 раза издать звук модулем пьезодинамика (каждый звук пьезодинамика длится 3 секунды), затем опять подать 3 сигнала светодиодом. Между действиями перерыв полсекунды. Цикл не повторять.',
            functions: [
                'Чтение с кнопки платы расширения',
                'Инициализация модуля светодиода',
                'Инициализация модуля пьезодинамика'
            ],
            skills: [
                'Базовые навыки работы с роботом и средой программирования',
                'Составление простейших алгоритмов',
                'Работа с модулем светодиода',
                'Работа с модулем пьезодинамика'
            ]
        },
        complectation: {
            image: "/base/complectation/3.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль пьезодинамика',
                'Модуль светодиода',
                'Соединительный провод 3 pin 160 мм – 2 шт.',
                'Кабель для программирования контроллера',
                'Аккумуляторы'
            ]
        },
        code: {
            blocks: "/base/blocks/3.png",
            text: code3
        }
    },
    {
        id: 4,
        name: 'Движение по черной линии до поворота',
        annotation: {
            goal: [
                'Изучить принцип работы модуля датчика линии',
                'Закрепить знания по работе моторов'
            ],
            task: 'Омегабот должен при нажатии на кнопку на плате расширения начать движение по черной линии. Робот должен двигаться по линии до момента, пока не потеряет линию (до момента поворота линии). После чего робот должен остановиться.',
            functions: [
                'Чтение с кнопки платы расширения',
                'Инициализация работы моторов',
                'Чтение с модуля датчика линии'
            ],
            skills: [
                'Базовые навыки работы с роботом и средой программирования',
                'Составление простейших алгоритмов',
                'Работа с модулем датчика линии'
            ]
        },
        complectation: {
            image: "/base/complectation/4.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль датчика линии – 2 шт.',
                'Соединительный провод 3 pin 160 мм',
                'Кабель для программирования контроллера',
                'Аккумуляторы'
            ]
        },
        code: {
            blocks: "/base/blocks/4.png",
            text: code4
        }
    },
    {
        id: 5,
        name: 'Движение до обнаружения препятствия',
        annotation: {
            goal: [
                'Изучить принцип работы модуля УЗ датчика',
                'Закрепить знания по работе моторов'
            ],
            task: 'Робот должен при нажатии на кнопку платы расширения начать движение вперед до обнаружения модулем УЗ датчика препятствия на расстоянии 15 сантиметров от робота. После обнаружения препятствия робот движется назад 1 секунду. После чего робот должен остановиться.',
            functions: [
                'Чтение с кнопки платы расширения',
                'Инициализация работы моторов',
                'Чтение с модуля УЗ датчика',
                'Управление моторами'
            ],
            skills: [
                'Базовые навыки работы с роботом и средой программирования',
                'Работа с УЗК дальномером',
                'Составление простейших алгоритмов',
                'Управление моторами робота'
            ]
        },
        complectation: {
            image: "/base/complectation/5.jpg",
            items: [
                'Четырехколесная платформа с базовой основой для установки модулей',
                'Контроллер',
                'Плата расширения контроллера',
                'Модуль УЗ датчика с модулем сервопривода',
                'Кабель для программирования контроллера',
                'Соединительный провод 4 pin 140 мм',
                'Аккумуляторы'
            ]
        },
        code: {
            blocks: "/base/blocks/5.png",
            text: code5
        }
    }
]