import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Microcomputer extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Микрокомпьютер</h4>

                <p>
                    Микрокомпьютер – это одноплатный компьютер с четырехъядерным 64-битным процессором
                    с частотой 1,4 ГГц, двухполосной беспроводной LAN 2,4 ГГц и 5,0 ГГц, Bluetooth 4.2/BLE,
                    Ethernet и возможностью питания PoE через отдельную плату PoE HAT.
                </p>

                <p>
                    Внешний вид микрокомпьютера приведен на рисунке 2.21.
                </p>

                <Image src={"/Constructor/microcomputer.png"} name={"Внешний вид микрокомпьютера"} number={"2.21"}/>

                <p>
                    Основные технические характеристики микрокомпьютера приведены в таблице 2.19.
                </p>

                <Table name={"Основные технические характеристики микрокомпьютера"}
                       number={"2.19"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Модель", "Raspberry Pi3 Model B+"],
                           ["Тип процессора", "bcm2387 четырехъядерный cortex-a53"],
                           ["Частота процессора", "1,4 ГГц"],
                           ["Оперативная память", "1 Гб"],
                           ["Графический процессор", "двухъядерный videocore iv"],
                           ["Поддерживаемые операционные системы", "linux, windows 10 iot core и др."],
                           ["Интерфейсы связи", "usb, hdmi, ethernet, micro-sd, audio, dsi, csi, i/o"],
                           ["Порты", "4 порта USB 2.0;\n" +
                           "полноразмерный HDMI;\n" +
                           "порт дисплея MIPI DSI;\n" +
                           "порт камеры MIPI CSI;\n" +
                           "выходной 4-полюсный порт стерео звука/композитного видеосигнала;\n" +
                           "соединитель MicroSD карты\n"],
                           ["GPIO", "40 pin"],
                       ]}/>
            </div>
        )
    }
}

export default Microcomputer