import React, {Component} from 'react';

class Requisites extends Component {
    render() {
        return (
            <div className={"requisites"}>
                <div className={"company"}>
                    <img src={"/assets/images/MenuPages/Contacts/omegaLogo.svg"} alt={"ООО Проф-Ит"}/>
                    Общество с ограниченной ответственностью «Проф-Ит»:<br/>
                    ИНН 7801553786 КПП 781001001<br/>
                    Адрес: 196105, Санкт-Петербург г, Решетникова ул, дом № 9, литера А, квартира 70<br/>
                    Почтовый адрес: 191124, Санкт-Петербург, Синопская наб, д. 52 А
                </div>

                <div className={"details"}>
                    <div className={"header"}>
                        <i className="fa fa-university"/> Банковские реквизиты:
                    </div>
                    <div className={"content"}>
                        Петербургский Филиал ПАО "БАНК УРАЛСИБ"<br/>
                        р/с 40702810722550400264<br/>
                        кор/счет 30101810800000000706<br/>
                        БИК 044030706
                    </div>
                </div>

                <div className={"details"}>
                    <div className={"header"}>
                        <i className="fa fa-cog"/> Производство:
                    </div>
                    <div className={"content"}>
                        СПб, Южное шоссе, дом 37/2
                    </div>
                </div>
            </div>
        )
    }
}

export default Requisites