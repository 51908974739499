import React, {Component} from 'react';
import SolutionBlock from "./SolutionBlock";

class Solutions extends Component {
    render() {
        return (
            <section className="banner-6 solutions p-t-0">
                <div className="row partition3">
                    <SolutionBlock name={<>комплекты<br/>конструкторов</>}
                                   image={"box.jpg"}/>

                    <SolutionBlock name={<>учебные<br/>программы</>}
                                   image={"book.jpg"}/>
                </div>
            </section>
        )
    }
}

export default Solutions