import React, {Component} from 'react';
import Helmet from "react-helmet/es/Helmet";
import {details} from "../../constants/Pages/FooterPages/mission";
import PageContent from "../common/Page/PageContent";

class Mission extends Component {
    render() {
        return (
            <>
                <Helmet><title>Наша миссия</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className="section-b-space container mission">
                        <div className="row">
                            <div className="col">
                                <h2 className={"text-center header"}>Наша миссия</h2>
                                <PageContent blocks={details}/>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

export default Mission