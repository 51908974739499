import React, {Component} from 'react';

class PageNotFound extends Component {
    render (){
        return (
            <div className={"menu-padding-section"}>
                <section className="p-0 container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="error-section">
                                <h1>404</h1>
                                <h2>
                                    страница не найдена
                                </h2>
                                <a href="/" className="btn btn-solid">на главную</a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default PageNotFound