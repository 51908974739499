import React, {Component} from 'react';

class PersonalData extends Component {
    render() {
        let { values, validator } = this.props;

        return (
            <div className="row personal-data">
                <div className="form-group col-sm-6 col-xs-12">
                    <div className="field-label">ФИО *</div>
                    <input type="text" name="full_name" value={values.full_name}
                           onChange={this.props.onChange}/>
                    {validator.message('full_name', values.full_name, 'required')}
                </div>

                <div className="form-group col-sm-6 col-xs-12">
                    <div className="field-label">Email *</div>
                    <input type="text" name="email" value={values.email}
                           onChange={this.props.onChange}/>
                    {validator.message('email', values.email, 'required|email')}
                </div>

                <div className="form-group col-sm-6 col-xs-12">
                    <div className="field-label">Телефон *</div>
                    <input type="text" name="phone" value={values.phone}
                           onChange={this.props.onChange}/>
                    {validator.message('phone', values.phone, 'required|phone')}
                </div>

                <div className="form-group col-sm-6 col-xs-12">
                    <div className="field-label">Комментарий</div>
                    <input type="text" name="comment" value={values.comment}
                           onChange={this.props.onChange}/>
                    {validator.message('comment', values.comment, '')}
                </div>
            </div>
        )
    }
}

export default PersonalData