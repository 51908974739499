import React, {Component} from 'react';

class AdvantageBlock extends Component {
    render() {
        return (
            <div className={"col-lg-3 col-sm-6 col-6"}>
                <div className="image logo-block">
                    <img src={`/assets/images/MainPage/advantages/${this.props.image}`}
                         alt={this.props.name} />
                    <div>{ this.props.name }</div>
                </div>
            </div>
        )
    }
}

export default AdvantageBlock