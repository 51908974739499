import React, {Component} from 'react';
import Logo from "../../Logo";

class Description extends Component {
    render() {
        const imagePath = `${process.env.PUBLIC_URL}/assets/images/common/footer/`;

        return (
            <div className="col-lg-4 col-md-6">
                <div className="logo">
                    <Logo logo={"light.png"}/>
                </div>
                <p>
                    Создай и запрограммируй своего робота. Участвуй во всех видах соревнований
                    благодаря компактности и новым возможностям конструктора Омегабот.
                </p>
                <div className={"coming-soon"}>
                    <img src={`${imagePath}coming-soon-apple.png`}
                         alt="Coming soon in Apple Store"/>
                    <img
                        src={`${imagePath}coming-soon-google.png`}
                        alt="Coming soon in Play Market"/>
                </div>
            </div>
        )
    }
}

export default Description