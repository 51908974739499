import React, {Component,Fragment} from 'react'
import {findFileIcon, getSize, shortenString} from "../../../../assets/js/functions";

class FilesList extends Component {
    constructor() {
        super();
        this.state = {
            screenSize: window.innerWidth
        }
    }

    screenSize() {
        window.onresize = () => {
            this.setState({screenSize: window.innerWidth})
        }
    }

    render() {
        const files = this.props.files
        this.screenSize()
        const lettersCount = this.state.screenSize > 1200
            ? 6
            :  this.state.screenSize < 991 && this.state.screenSize > 470 ? 6 : 4
        return(
            <div className={'files-list col-8 col-lg-8 col-xl-9 col-sm-8'}>
                {files.map((file,i) => {
                    return(
                        <Fragment key={file.id}>
                            <div className={'files-wrapper'}>
                                <div className={'files'}>
                                    <span className={'files-name'}>{i + 1}.</span>
                                    <img src={findFileIcon(file.f.name)} alt="file icon" className={'files-image'}/>
                                    <span className={'files-name'}>{shortenString(file.f.name, lettersCount)}</span>
                                </div>
                                <div className={'files'}>
                                    <span className={'files-name files-size'}>{getSize(file.f.size)}</span>
                                    <div className={'close-btn'} onClick={() => this.props.removeFile(file)}/>
                                </div>
                            </div>
                        </Fragment>
                    )
                })}
            </div>
        )
    }
}

export default FilesList