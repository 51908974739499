import React, {Component} from 'react';
import '../../assets/index.scss';

import Header from "../common/Header";
import Screen from "./blocks/Screen";
import Footer from "../common/Footer";
import Helmet from "react-helmet/es/Helmet";
import Offer from "./blocks/Offer";
import Advantages from "./blocks/Advantages";
import Parallax from "./blocks/Parallax";
import QuickStart from "./blocks/QuickStart";
import SoftwareBlock from "../common/SoftwareBlock";
import Reviews from "./blocks/Reviews";
import Competitions from "./blocks/Competitions";

class MainPage extends Component {

    render() {
        return (
            <>
                <Helmet><title>Главная страница</title></Helmet>

                <div className={"main-page"}>
                    <Header/>
                    <Screen/>
                    <Offer/>
                    <Advantages/>
                    <Parallax/>
                    <Competitions/>
                    <QuickStart/>
                    <SoftwareBlock/>
                    <Reviews/>
                    <Footer/>
                </div>
            </>
        )
    }
}

export default MainPage;