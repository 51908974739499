import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Platform extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4 className={'title'}>Колесная платформа</h4>

                <p className={'about'}>
                    Внешний вид колёсной платформы приведен на рисунке 2.1.

                </p>

                <Image src={'/Constructor/platform.png'} name={"Внешний вид колёсной платформы"} number={"2.1"}/>

                <p>
                    Колесная платформа является несущей конструктивной частью Конструктора и
                    обеспечивает механическую устойчивость создаваемых робототехнических устройств
                    при эксплуатации. Колесная платформа состоит из жесткой металлической рамы и
                    съёмной основы. Также на раме крепится блок аккумуляторов и мотор-колёса. Внешними
                    габаритами колесной платформы являются края пластиковой пластины, достаточно гибкой,
                    чтобы демпфировать удары при падении конструкции с высоты учебного стола. Также
                    демпфирующее свойство пластины, в сочетании с применением магнитных креплений внешних
                    модулей, приводит к свободному отсоединению последних и снижает риск их повреждения.
                </p>

                <p>
                    На колесной платформе с базовой основой предусмотрены четыре гнезда для магнитного
                    крепления внешних модулей, что позволяет устанавливать на платформу до четырех датчиков
                    или исполнительных устройств дополнительно к интегрированным на плату контроллера.
                </p>

                <p>
                    Основные технические характеристики колесной платформы с базовой основой приведены
                    в таблице 2.1.
                </p>

                <Table name={"Основные технические характеристики колесной платформы с базовой основой"}
                       number={"2.1"}>
                    <Table name={"Общие характеристики"}
                           header={["Наименование", "Значение"]}
                           rows={[
                               ["Тип основы", "колесная"],
                               ["Количество мотор-колёс", "4"],
                               ["Ток потребления мотора, не более", "4 А"],
                               ["Номинальное напряжение, электропитания мотора", "7,4 В"],
                               ["Количество универсальных креплений для модулей", "5"]
                           ]}/>

                    <Table name={"Механические характеристики"}
                           header={["Наименование", "Значение"]}
                           rows={[
                               ["Способ крепления модулей", "магнитный"],
                               ["Способ крепления контроллера", "магнитный"],
                               ["Материал каркаса", "нержавеющая сталь"],
                               ["Материал корпуса", "ABS-пластик"],
                           ]}/>
                </Table>
            </div>
        )
    }
}

export default Platform