import React, {Component} from 'react';
import SocialMediaItem from "./SocialMediaItem";

class SubFooter extends Component {
    render() {
        return (
            <div className="sub-footer dark-subfooter">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-md-6 col-sm-12">
                            <p className={"copyright"}>
                                <i className="fa fa-copyright" aria-hidden="true"></i> 2022
                                Лаборатория ОмегаЛаб. Вся информация представленная на сайте не
                                является публичной офертой.
                            </p>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-12 footer-social social-media">
                            <ul>
                                <SocialMediaItem link={"https://vk.com/omega.robot"} icon={"vk"} />
								<SocialMediaItem link={"https://t.me/omega_robotics"} icon={"telegram"} />
                                <SocialMediaItem link={"https://www.youtube.com/channel/UCOaEsDX0tHKLnMF_SaSGr5A"} icon={"youtube"} />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SubFooter