import React, {Component} from 'react';
import {SYMBOL} from "../../../../../constants";
import {getCartTotal} from "../../../../../assets/js/functions";
import {connect} from "react-redux";

class ItemsList extends Component {
    render() {
        const {cartItems, total, values} = this.props;

        return (
            <div className="order-box items-list">
                <div className="title-box">
                    <div className={"item"}>
                        <div className={"name"}>
                            Продукт
                        </div>
                        <span> Цена</span>
                    </div>
                </div>

                <ul className="qty">
                    {cartItems.map(item =>
                        <li key={item.id}>
                            <div className={"item"}>
                                <div
                                    className={"name"}>{item.name} × {item.qty} </div>
                                <span>{item.sum} {SYMBOL}</span>
                            </div>
                        </li>
                    )}

                    <li>
                        <div className={"item"}>
                            <div className={"name"}>Доставка:
                            </div>
                            <span>{values.delivery_cost} {SYMBOL}</span>
                        </div>
                    </li>
                </ul>

                <ul className="total">
                    <li>
                        <div className={"item"}>
                            <div className={"name"}>Итого</div>
                            <span className="count">{total + +values.delivery_cost} {SYMBOL}</span>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    total: getCartTotal(state.cartList.cart)
});

export default connect(mapStateToProps) (ItemsList)