import React, {Component} from 'react';
import Image from "../common/Image";

class BaseWithEncoders extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Расширенная основа с энкодерами</h4>
                <p>
                    Расширенная основа с энкодерами представляет собой деталь из АВС пластика
                    с увеличенным количеством отверстий для крепления модулей и установленными
                    модулями энкодеров (см. 2.6). Внешний вид расширенной основы с энкодерами
                    приведен на рисунке 2.5. Некоторые из отверстий имеют латунные резьбовые втулки
                    (3х4 мм) для фиксации деталей Lego, а некоторые - встроенные магниты для фиксации
                    модулей. На основе закреплены 2 модуля энкодера.
                </p>

                <Image src={"/Constructor/base-with-encoders.png"} name={"Внешний вид расширенной основы с энкодерами"} number={"2.5"}/>
            </div>
        )
    }
}

export default BaseWithEncoders