import React from 'react';

export const ComplectationTable = ({ complectationItems }) => {
    return (
        <div className="tab-pane fade mt-4 show active react-tabs__tab-panel--selected"
             role="tabpanel"
             id="react-tabs-1" aria-labelledby="react-tabs-0">
            <table className="table table-striped mb-0">
                <tbody>
                    {complectationItems.map((item, i) =>
                            <tr key={i}>
                                <th>{ item.name }</th>
                                <td>{ item.value }</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}

export const ProductVideo = ({ link }) => {
    return (
        <div className="mt-4 text-center">
            <div className="embed-responsive embed-responsive-16by9">
                <iframe
                    src={link}
                    title={"Omegabot"}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope;"
                    allowFullScreen/>
            </div>
        </div>
    )
}

export function makeProductTabs(detail) {
    let tabs =  [];

    if (detail.fullDetails && detail.fullDetails !== '<p><br></p>') {
        tabs[tabs.length] = {
            name: "Описание",
            content: <div className={"characteristics"}
                          dangerouslySetInnerHTML={{ __html: detail.fullDetails }}/>
        }
    }

    if (detail.characteristicItems && detail.characteristicItems.length) {
        tabs[tabs.length] = {
            name: "Характеристики",
            content: <ComplectationTable complectationItems={detail.characteristicItems || []}/>
        };
    }

    if (detail.complectationItems && detail.complectationItems.length) {
        tabs[tabs.length] = {
            name: "Комплектация",
            content: <ComplectationTable complectationItems={detail.complectationItems || []}/>
        };
    }

    if (detail.videoLink) {
        tabs[tabs.length] = {
            name: "Видео",
            content: <ProductVideo link={detail.videoLink.replace("watch?v=", "embed/")} />
        };
    }

    return tabs;
}