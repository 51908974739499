import React, {Component} from "react";
import SoftwareBlock from "../../common/SoftwareBlock";

class Description extends Component {
    render() {
        return (
            <>
                <SoftwareBlock/>
                <p className={"description"}>
                    В основу визуальной среды разработки положена всемирно
                    известная и популярная среда Scratch, которая обеспечивает
                    плавный переход из визуальной программы на язык программирования
                    С++ и Python. При этом практически полное соответствие структуры
                    системы блоков текстовому коду облегчает освоение программы.
                    Omegabot IDE имеет интуитивно понятный интерфейс и подходит
                    для детей младшего школьного возраста.
                </p>
            </>
        );
    }
}

export default Description;