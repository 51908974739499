import React, {Component} from 'react';
import {connect} from 'react-redux'
import {addToCart} from "../../../../actions";
import Item from './Item';
import {ToastContainer} from "react-toastify";
import {getProductsList} from "../../../../assets/axios/products";
import Modal from "react-responsive-modal";
import {getCategoriesList} from "../../../../assets/axios/categories";
import Helmet from "react-helmet/es/Helmet";
import {findObject} from "../../../../assets/js/functions";
import Categories from "./Categories";

class ProductsItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            productsList: [],
            categoriesList: [],
            modalOpen: false,
            modalContent: ''
        }
    }

    componentDidMount() {
        getCategoriesList(
            response => this.changeCategoriesList(response.data),
            () => this.onOpenModal("Не удалось загрузить список категорий")
        );
        this.updateProductList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.category !== prevProps.category) {
            this.updateProductList();
        }
    }

    updateProductList() {
        getProductsList(
            this.props.category === undefined ? {} : { category: this.props.category },
            response => this.changeProductsList(response.data),
            () => this.onOpenModal("Не удалось загрузить список товаров")
        );
    }

    changeProductsList(items) {
        this.setState( {productsList: items} );
    }

    changeCategoriesList(items) {
        this.setState( {categoriesList: items} );
    }

    onOpenModal = (content) => {
        this.setState({ modalOpen: true, modalContent: content });
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false, modalContent: '' });
    };

    render() {
        const {addToCart} = this.props;
        const { productsList, categoriesList } = this.state;
        let titleCategory = findObject(categoriesList, "id", +this.props.category).name;
        return (
            <>
                <Helmet><title>Продукты { titleCategory ? `| ${titleCategory}`: "" }</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className={"section-b-space container products j-box pets-box ratio_square"}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <Categories categories={categoriesList} selected={this.props.category}/>

                                <div className={"items"}>
                                    {productsList.map((product) =>
                                        <Item product={product}
                                                     onAddToCartClicked={() => addToCart(product, 1)}
                                                     key={product.id}/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Modal open={this.state.modalOpen} onClose={this.onCloseModal} center>
                    <div className={"modal-warning"}>{this.state.modalContent}</div>
                </Modal>
                <ToastContainer/>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    category: ownProps.match.params.category
});

export default connect(mapStateToProps, { addToCart }) (ProductsItems);