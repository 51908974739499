import React, {Component} from 'react';
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import {MAIL, ATTACHMENT} from "../../../../constants/axiosURLs";
import Modal from "react-responsive-modal";
import Drop from "./Drop";

class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            phone: '',
            email: '',
            problem: '',
            confirm: '',
            modalContent: '',
            modalOpen: false,
            filesDrop: [],
            total: 0,
            files: [],
        };
        this.validator = new SimpleReactValidator({
            messages: {
                name: "Имя обязательно",
                email: "Email некорректен",
                phone: "Телефон некорректен",
                confirm: "Подтвердите согласие на обработку персональных данных",
                default: "Заполните поле"
            }
        });
        this.getFiles = this.getFiles.bind(this);
    }

    setStateFromInput = (event) => {
        let obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    };

    setStateFromCheckbox = (event) => {
        let obj = {};
        obj[event.target.name] = event.target.checked ? true : '';
        this.setState(obj);
    };

    getFiles = (files, total) => {
        this.setState({filesDrop: files, total})
    }

    processProductPictures(pictures) {
        let formData = new FormData();

        pictures.forEach((item) => {
            formData.append("files[]", item.f);
        })

        return formData;
    }

    idsFromData(data) {
        let ids = []
        data.data.forEach(item => {
            ids = [...ids, item.id]
        })
        return ids
    }

    onSubmit = () => {
        if (this.validator.allValid()) {
            if (this.state.total < 5) {
                axios.post(ATTACHMENT, this.processProductPictures(this.state.filesDrop), {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((data) => {
                        this.setState({files: this.idsFromData(data)})
                        axios.post(MAIL, this.state).then(() => {
                            this.setState({
                                name: '',
                                phone: '',
                                email: '',
                                problem: '',
                                confirm: '',
                                modalContent: '',
                                modalOpen: false,
                                filesDrop: null,
                                total: 0,
                                files: []
                            })
                            this.onOpenModal(
                                <>
                                    Ваша заявка успешно отправлена!<br/>
                                    Наш менеджер свяжется с Вами в ближайшее время.
                                </>)
                        }).catch(() => this.onOpenModal("Не удалось отправить заявку"));
                    })
                    .catch(() => {
                    })
                this.validator.hideMessages()
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    onOpenModal = (content) => {
        this.setState({modalOpen: true, modalContent: content});
    };

    onCloseModal = () => {
        this.setState({modalOpen: false, modalContent: ''});
    };

    render() {
        return (
            <section className={"container checkout-page"}>
                <div className="row">
                    <div className={"col"}>
                        <h2 className={"text-center header"}>Форма связи<br/>с технической поддержкой</h2>

                        <section className={"checkout-form"}>
                            <form className={"form"}>
                                <div className="row">
                                    <div className="form-group col-lg-6 col-xs-12">
                                        <div className="form-group col-12">
                                            <div className="field-label">Имя *</div>
                                            <input className={"colorful"}
                                                   style={{background: "#78cdd6"}}
                                                   type="text"
                                                   name="name"
                                                   value={this.state.name}
                                                   onChange={this.setStateFromInput}/>
                                            {this.validator.message('name', this.state.name, 'required')}
                                        </div>

                                        <div className="form-group col-12">
                                            <div className="field-label">E-mail *</div>
                                            <input className={"colorful"}
                                                   style={{background: "#44b7c4"}}
                                                   type="text"
                                                   name="email"
                                                   value={this.state.email}
                                                   onChange={this.setStateFromInput}/>
                                            {this.validator.message('email', this.state.email, 'required|email')}
                                        </div>

                                        <div className="form-group col-12">
                                            <div className="field-label">Телефон *</div>
                                            <input className={"colorful"}
                                                   style={{background: "#3998a3"}}
                                                   type="text"
                                                   name="phone"
                                                   value={this.state.phone}
                                                   onChange={this.setStateFromInput}/>
                                            {this.validator.message('phone', this.state.phone, 'required|phone')}
                                        </div>
                                        <div className="form-group col-12">
                                            <div className={"according"}>
                                                <input type="checkbox" name="confirm"
                                                       id="account-option"
                                                       checked={this.state.confirm}
                                                       onChange={this.setStateFromCheckbox}/>
                                                <label htmlFor="account-option">
                                                    Согласен с условиями <a href={"/privacy-policy"} target={"_blank"}>
                                                    Политики конфиденциальности</a>
                                                </label>
                                            </div>
                                            {this.validator.message('confirm', this.state.confirm, 'required')}
                                        </div>
                                    </div>

                                    <div className="form-group col-lg-6 col-xs-12">
                                        <div className="form-group col-12">
                                            <div className="field-label">Что случилось? *</div>
                                            <textarea name="problem" className={"contact-form-textarea"}
                                                      value={this.state.problem} onChange={this.setStateFromInput}
                                                      placeholder={"Расскажите нам подробно, что произошло"}/>
                                            {this.validator.message('problem', this.state.problem, 'required')}
                                        </div>

                                        <div className={'form-group col-12'}>
                                            <Drop getFiles={this.getFiles}
                                                  filesDrop={this.state.filesDrop}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <button type="button"
                                        onClick={this.onSubmit}
                                        className="btn btn-solid">отправить
                                </button>
                            </form>

                        </section>
                    </div>
                </div>
                <Modal open={this.state.modalOpen} onClose={this.onCloseModal} center>
                    <div className={"modal-warning"}>
                        {this.state.modalContent}
                    </div>
                </Modal>
            </section>
        )
    }
}

export default ContactForm