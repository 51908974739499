import React, {Component} from 'react';
import Mark from "./Mark";
import {reviewMarks} from "../../../../constants/Pages/MainPage/reviews";

class Reviews extends Component {
    render() {
        return (
            <section className={"section-b-space reviews"}>
                <h2 className={"header text-center"}>Отзывы о платформе Омегабот</h2>
                <div className={"container"}>
                    <section className={"col"}>
                        <div className={"map"}>
                            <img alt={"Отзывы"}
                                 src={`/assets/images/MainPage/reviews/map.png`}
                                 className={"img-fluid"}/>

                            {
                                reviewMarks.map(mark =>
                                    <Mark className={mark.id}
                                          key={mark.id}
                                          name={mark.name}
                                          reviews={mark.reviews}/>
                                )
                            }
                        </div>
                    </section>
                </div>
            </section>
        )
    }
}

export default Reviews