import React, {Component} from 'react';
import Cover from "./Cover";
import Details from "./Details";

class Item extends Component {
    render() {
        const {product} = this.props;
        return (
            <div className={"item col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12"}>
                <div className="product-box">
                    <Cover product={product}/>
                    <Details product={product}
                             addToCart={() => this.props.onAddToCartClicked()}/>
                </div>
            </div>
        )
    }
}

export default Item;