import React, {Component} from 'react';

class Complectation extends Component {
    render() {
        let { data } = this.props;
        return (
            <div className={"complectation"}>
                <img alt={""} src={`/assets/images/MenuPages/Education/exercises${data.image}`}/>
                <div className={"list"}>
                    <h3>Вам понадобится</h3>
                    <ul>
                        { data.items.map( (item, i) => <li key={i}>{ item }</li> ) }
                    </ul>
                </div>
            </div>
        )
    }
}

export default Complectation