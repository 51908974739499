import React, {Component} from 'react';

class Linux extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Инструкция по установке ПО OmegaBot IDE в ОС Linux</h4>

                <p>
                    Установка и запуск ПО OmegaBot IDE в ОС Linux производится в следующей последовательности:
                </p>

                <p>
                    <strong>Шаг 1</strong><br/>
                    Зайти на сайт <a href={"/"}>https://omegabot.ru</a>.
                </p>

                <p>
                    <strong>Шаг 2</strong><br/>
                    Переход на страницу загрузки ПО возможен следующими альтернативными способами:
                    - нажать ссылку <strong>ЗАГРУЗКА</strong> ПО в шапке сайта;
                    - пролистать вниз открытую страницу до раздела <strong>Визуальная среда программирования</strong> и
                    нажать кнопку <strong>СКАЧАТЬ</strong>;
                    - перейти на страницу загрузки по адресу <a href={"/software"}>https://omegabot.ru/software</a>.
                </p>

                <p>
                    <strong>Шаг 3</strong><br/>
                    Загрузка ПО производится следующими альтернативными способами:<br/>
                    - нажать кнопку <strong>СКАЧАТЬ</strong> в начале страницы;<br/>
                    - пролистать вниз открытую страницу и нажать кнопку <strong>СКАЧАТЬ</strong> под
                    надписью <strong>для Linux</strong>.
                </p>

                <p>
                    <strong>Шаг 4</strong><br/>
                    Открыть скачанный архив и распаковать файлы в любую удобную директорию.
                </p>

                <p>
                    <strong>Шаг 5</strong><br/>
                    Перейдите в распакованную папку. Открыть терминал из данной директории и для
                    установки прописать <strong>sudo ./omegaBot.sh</strong>.<br/>
                    Далее необходимо уточнить наличие установленной
                    среды <strong>Arduino</strong> (прописать <strong>Y/N</strong>; <strong>Y</strong> – да,
                    есть; <strong>N</strong> – нет, отсутствует).<br/>
                    Установщик автоматически определит расположение папки <strong>Arduino</strong> для установки.<br/>
                    Однако, если он его не сможет найти папку <strong>Arduino</strong>, необходимо будет вручную прописать
                    директорию <strong>Arduino IDE</strong>. В случае её отсутствия она установится автоматически.
                </p>

                <p>
                    <strong>Шаг 6</strong><br/>
                    После окончания установки в терминале выводится сообщение <strong>Установка завершена</strong>.
                </p>

                <p>
                    <strong>Шаг 7</strong><br/>
                    На рабочем столе открыть приложение <strong>Arduino IDE</strong>.
                </p>

                <p>
                    <strong>Шаг 8</strong><br/>
                    Для перехода в среду <strong>OmegaBot IDE</strong> открыть в
                    ПО <strong>Arduino IDE</strong> вкладку <strong>Tools – OmegaBot_IDE</strong>.
                </p>

                <p>
                    <strong>Шаг 9</strong><br/>
                    В поле авторизации ввести ключ продукта (серийный номер) и нажать кнопку <strong>ОК</strong>.
                </p>

                <p>
                    ПО OmegaBot_IDE готово к работе.
                </p>
            </div>
        )
    }
}

export default Linux