import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class LightSensor extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль датчика света</h4>

                <p>
                    Модуль датчика света представляет собой печатную плату с установленным
                    чувствительным элементом – фоторезистором. Внешний вид модуля датчика света
                    приведен на рисунке 2.11. Модуль датчика света является аналоговым, выходной
                    сигнал с фоторезистора прямо пропорционален интенсивности света, падающего на
                    чувствительную поверхность датчика.
                </p>

                <p>
                    Области применения модуля датчика света:<br/>
                    - регистрация слабых потоков света;<br/>
                    - определение интенсивности падающего света;<br/>
                    - в Конструкторе используется совместно со светодиодом.
                </p>

                <p>
                    Фоторезистор модуля датчика света изменяет сопротивление в зависимости от силы
                    падающего на него света. Имеет максимальное сопротивление в темноте, сопротивление
                    снижается по мере увеличения интенсивности освещения.
                </p>

                <Image src={"/Constructor/light-sensor.png"} name={"Внешний вид модуля датчика света"} number={"2.11"}/>

                <p>
                    Основные технические характеристики модуля датчика света приведены в таблице 2.10.
                </p>

                <Table name={"Основные технические характеристики модуля датчика света"}
                       number={"2.10"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Напряжение", "до 150 В"],
                           ["Мощность", "до 90 мВт"],
                           ["Рабочий диапазон температур", "от -30 до +70°C"],
                           ["Спектральный пик", "540 нм"],
                           ["Сопротивление при 10 Люк", "от 5 до 10 кОм"],
                           ["Сопротивление в темноте", "0,5 МОм"],
                           ["Задержка", "от 20 до 30 мс"],
                           ["Resistance illumination (коэффициент преобразования)", "2"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Принцип действия", "Фоторезистивный"],
                           ["Номинальное напряжение электропитания", "5 В"],
                           ["Средний ток потребления", "5 мА"],
                       ]}/>
            </div>
        )
    }
}

export default LightSensor