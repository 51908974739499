import React, {Component} from 'react';
import {Accordion, AccordionItem} from "react-light-accordion";

class VisionTheory extends Component {
    render() {
        return (
            <div className="accordion-container">
                <Accordion atomic={true}>
                    <AccordionItem title="Центральные и периферийные устройства робота – микропроцессор, память, контроллер, микрокомпьютер, управляемые устройства">

                    </AccordionItem>
                    <AccordionItem title="Сетевое взаимодействие роботов">

                    </AccordionItem>
                    <AccordionItem title="Основы технического зрения">

                    </AccordionItem>
                    <AccordionItem title="Пассивное управление роботом / независимое (программное управление) движением робота">

                    </AccordionItem>
                    <AccordionItem title="Искусственный интеллект и планирование задач в робототехнике">

                    </AccordionItem>
                    <AccordionItem title="Знакомство с языком Python">

                    </AccordionItem>
                    <AccordionItem title="Состав и компоненты датчиков робота (терморезистор, резистор, пирометр и др.)">

                    </AccordionItem>
                </Accordion>
            </div>
        )
    }
}

export default VisionTheory