import React, {Component} from 'react';
import {Map, Placemark, YMaps} from "react-yandex-maps";

class AddressMap extends Component {
    render() {
        return (
            <div className={"address-map"}>
                <script src={"https://unpkg.com/react-yandex-maps/dist/production/react-yandex-maps.umd.js"}/>
                <YMaps>
                    <Map defaultState={{ center: [59.93458346090535,30.392199970569603], zoom: 13 }}
                         width={"100%"} height={"100%"}>
                        <Placemark geometry={[59.93458346090535,30.392199970569603]}
                                   options={{ iconColor: "#393230" }} />
                    </Map>
                </YMaps>
            </div>
        )
    }
}

export default AddressMap