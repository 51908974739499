import React, {Component} from 'react';

class Pedestal extends Component {
    render() {
        return (
            <div className={'pedestal'}>
                <div className={'left'}>
                    <h4>Классические<br/>соревнования</h4>
                    Кегельринг<br/>
                    Лабиринт
                </div>
                <div className={'center'}>
                    <h4>Робофинист</h4>
                    Следование по узкой линии<br/>
                    Большое путешествие<br/>
                    RoboCup Junior<br/>
                    Линия профи<br/>
                    Эстафета<br/>
                    Арканоид<br/>
                    Кубок РТК<br/>
                    Свободная творческая категория
                </div>
                <div className={'right'}>
                    <h4>Робофест</h4>
                    Робокурьер<br/>
                    Робоперевозчик<br/>
                    Робостроитель<br/>
                    AutoNet
                </div>
            </div>
        )
    }
}

export default Pedestal