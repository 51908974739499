import React, {Component} from 'react';

class Column extends Component {
    render() {
        return (
            <div className={`col sub-title ${this.props.className || ''}`}>
                <h4 className="footer-title">
                    { this.props.name }
                    <span className="according-menu"></span>
                </h4>
                <div>
                    <ul className={this.props.listClassName || ''}>
                        {this.props.items.map((item,i) =>
                            <li key={i}>{ item }</li>
                        )}
                    </ul>

                    { this.props.children }
                </div>
            </div>
        )
    }
}

export default Column