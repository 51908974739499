import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {SERVER_HOST} from "../../../../../constants/axiosURLs";

class Cover extends Component {
    render() {
        const {product} = this.props;

        return (
            <div className="img-wrapper">
                <div className="lable-block">
                    {product.new ? <span className="lable3">new</span> : ''}
                    {product.discount ? <span className="lable4">on sale</span> : ''}

                </div>
                <div className="front">
                    <Link to={`/product/${product.id}`} >
                        <img src={SERVER_HOST +
                                (product.pictures.length ? product.pictures[0].url : '/assets/images/common/image-stub.jpg')}
                             className="img-fluid bg-img"
                             alt={product.name} />
                    </Link>
                </div>
            </div>
        )
    }
}

export default Cover;