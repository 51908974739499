import React, {Component} from "react";

class QuickStart extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.setSettings = this.setSettings.bind(this);
        window.onresize = this.setSettings;
    }

    componentDidMount() { this.setSettings(); }

    render() {


        return (
            <section className={"section-b-space blog-bg quick-start"}>
                <h2 className={"text-center header"}>Как начать работу с ОмегаБотом</h2>
                <section className={"container"}>
                    <div className={"col"}>
                        <img src={`/assets/images/MainPage/quickStart/${this.state.stepsLink}`}
                             alt={"Быстрый старт"}
                             className={`steps`}/>
                    </div>
                </section>
            </section>
        );
    }

    setSettings() {
        let isBigScreen = document.documentElement.offsetWidth > 630;
        this.setState(isBigScreen
            ? {stepsLink: 'pc.png'}
            : {stepsLink: 'mob.png'});
    }
}

export default QuickStart;