import React, {Component} from 'react';
import Image from "../common/Image";
import Table from "../common/Table";

class Potentiometer extends Component {
    render() {
        return (
            <div className={"section"}>
                <h4>Модуль потенциометра</h4>

                <p>
                    Модуль потенциометра представляет собой печатную плату с установленным
                    переменным резистором. Внешний вид модуля потенциометра приведен на рисунке
                    2.12. При изменении сопротивления переменного резистора изменяется уровень
                    выходного напряжения.
                </p>

                <p>
                    Области применения модуля потенциометра – регулирование чего-либо (мощности,
                    скорости, громкости).
                </p>

                <Image src={"/Constructor/potentiometer.png"} name={"Внешний вид модуля потенциометра"} number={"2.12"}/>

                <p>
                    Основные технические характеристики модуля потенциометра приведены в таблице 2.11.
                </p>

                <Table name={"Основные технические характеристики модуля потенциометра"}
                       number={"2.11"}
                       header={["Наименование", "Значение"]}
                       rows={[
                           ["Износоустойчивость", "10 000 циклов"],
                           ["Резистивный элемент", "углерод"],
                           ["Выключатель", "отсутствует"],
                           ["Число секций", "1"],
                           ["Фиксация", "есть"],
                           ["Рабочая температура", "от -10 до +50°С"],
                           ["Номинальная мощность", "0,05 Вт"],
                           ["Габариты, Д х Ш", "24 х 24 мм"],
                           ["Диапазон изменения сопротивления", "от 1 до 10 кОм"],
                           ["Номинальное значение напряжения электропитания", "5 В"],
                           ["Ток потребления", "от 0,5 до 5 мА"],
                       ]}/>
            </div>
        )
    }
}

export default Potentiometer