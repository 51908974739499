import React, {Component} from "react";
import {connect} from "react-redux";
import Theory from "./theory";
import Examples from "./Examples";
import Literature from "./Literature";
import Helmet from "react-helmet/es/Helmet";

class Education extends Component {
    render() {
        return (
            <>
                <Helmet><title>Обучение</title></Helmet>

                <div className={"menu-padding-section"}>
                    <section className="section-b-space container education">
                        <div className="row">
                            <div className="col">
                                <Theory/>
                                <Examples/>
                                <Literature/>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default connect(() => ({}), {})(Education);