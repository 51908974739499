import React, {Component} from 'react';
import {connect} from "react-redux";
import {removeFromCart} from "../../../../../../actions";

class RemoveButton extends Component {
    render() {
        let { item } = this.props;

        return (
            <td>
                <span className="icon delete-item"
                      onClick={() => this.props.removeFromCart(item)}>
                    <i className="fa fa-times"/>
                </span>
            </td>
        )
    }
}

export default connect(() => ({}), {removeFromCart}) (RemoveButton)