import React, { Component } from "react";

class TechVision extends Component {
  render() {
    return (
      <>
        <h2 className={"text-center header"}>Техническое зрение</h2>
        <section>
          <div className={"row downloads light-list"}>
            <div className={"col-lg-4 col-md-4 item"}>
              <div className="logo-block">
                <img
                  src={`/assets/images/MenuPages/Software/tech-vision.png`}
                  alt={""}
                />
                <div>Дистрибутив</div>
              </div>
              <a
                href={`https://yadi.sk/d/a1svi6lCw0jQRw`}
                target={"_blank"}
                className={"download-button"}
              >
                <button type="button" className="btn btn-solid">
                  скачать
                </button>
              </a>
            </div>
          </div>
          <p className={"text-center header description"}>
            Стоимость Omegabot IDE входит в стоимость робота Omegabot.
          </p>
        </section>
      </>
    );
  }
}

export default TechVision;
